import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
// import RemoveIcon from "@mui/icons-material/Remove";
import Delete from "@mui/icons-material/Delete";
import Textarea from "../Input/Textarea";
import SaveIcon from "@mui/icons-material/Save";
import Component from "../GoogleMap/index"; // Asegúrate de ajustar la ruta de importación
import "./index.css";

const Direction = (props) => {
  const [asistentes, setAsistentes] = useState(null); // Cambiado a null para manejar sin selección inicial
  const [btnShow, setBtnShow] = useState(false);
  const [inputs, setInputs] = useState({});
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const handleAsistentesChange = (event, newAsistentes) => {
    setAsistentes(newAsistentes);
    const _inputs = { ...inputs, asistentes: newAsistentes };
    setInputs(_inputs);
  };

  const onChange = (e) => {
    const _name = e.target.name;
    const _inputs = { ...inputs, [_name]: e.target.value };
    setInputs(_inputs);
    if (
      _inputs.address_components &&
      _inputs.lat &&
      _inputs.lng &&
      _inputs.direccion &&
      _inputs.direccion !== ""
    ) {
      setBtnShow(true);
    } else {
      setBtnShow(false);
    }
  };

  const onChangeMap2 = (place, name) => {
    if (place.geometry && place.geometry.location) {
      const geometry = place.geometry.location;
      const _inputs = {
        ...inputs,
        direccion: place.formatted_address,
        lat: geometry.lat(),
        lng: geometry.lng(),
      };
      setInputs((prev) => ({
        ...prev,
        direccion: place.formatted_address,
      }));
    }
  };

  const onChangeMap = (place, name) => {
    if (place.geometry && place.geometry.location) {
      const geometry = place.geometry.location;
      const _inputs = {
        ...inputs,
        all: place,
        address_components: place.formatted_address,
        lat: geometry.lat(),
        lng: geometry.lng(),
      };
      setInputs(_inputs);
    }
  };

  const handleSave = () => {
    if (
      inputs.descripcion &&
      inputs.descripcion.trim() !== "" &&
      asistentes !== null
    ) {
      setReset(true);
      setTimeout(() => {
        setReset(false);
      }, 1000);
      if (props.setOpen) {
        props.setOpen(false);
      }

      if (props.setLocations) {
        let _locations = [...props.locations];
        const _inputs = { ...inputs, asistentes, active: true };

        if (props.defaultValue && props.defaultValue.lat) {
          const result = _locations.findIndex(
            (search) => search.lat === props.defaultValue.lat
          );
          _locations[result] = _inputs;
        } else {
          if (_locations && _locations[props.contador]) {
            _locations[props.contador] = _inputs;
          } else {
            _locations.push(_inputs);
          }
        }
        props.setLocations(_locations);
        props.handleEvent(_locations);

        // Después de manejar el evento, restablece los campos a un estado inicial vacío
        setInputs({});
        setAsistentes(null); // Restablecer asistentes
        setShowAlert(false);
      }
    } else {
      // Muestra la alerta si la descripción está vacía o no se ha seleccionado asistentes
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (props.defaultValue === true) {
      setReset(true);
      setTimeout(() => {
        setReset(false);
      }, 1000);
    }
    if (props.defaultValue) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [props.defaultValue]);

  return (
    <Paper
      sx={{ p: 2, mb: 2 }}
      className={props.active ? "direction-active" : "direction-desactive"}
    >
      {loading && <Grid>Cargando...</Grid>}
      {!reset && (
        <Component
          reset={reset}
          setReset={setReset}
          onChange={onChangeMap}
          size="small"
          label={props.contador === 1 ? "Ciudad origen" : "Ciudad destino"}
          icon={false}
        />
      )}
      {!loading && !reset && (
        <>
          <Grid>
            {!reset && (
              <Component
                type="address"
                reset={reset}
                setReset={setReset}
                onChange={onChangeMap2}
                size="small"
                label={
                  props.contador === 1
                    ? "Dirección origen"
                    : "Dirección destino"
                }
                icon={true}
              />
            )}
          </Grid>
          <Grid>
            <Textarea
              defaultValue={
                props && props.defaultValue && props.defaultValue.descripcion
                  ? props.defaultValue.descripcion
                  : ""
              }
              size="small"
              sx={{ mb: 1, width: "100%" }}
              required
              onChange={onChange}
              fullWidth
              name="descripcion"
              label="Descripción"
            />
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs="auto" sx={{ mt: 1 }}>
              ¿Necesitas asistentes?
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                value={asistentes}
                exclusive
                onChange={handleAsistentesChange}
                aria-label="asistentes"
                sx={{ width: "100%" }} // Ajustar el ancho del ToggleButtonGroup
              >
                <ToggleButton
                  value={1}
                  aria-label="sí"
                  sx={{ width: "50%" }} // Ajustar el ancho del botón "Sí" al 50%
                >
                  Sí
                </ToggleButton>
                <ToggleButton
                  value={0}
                  aria-label="no"
                  sx={{ width: "50%" }} // Ajustar el ancho del botón "No" al 50%
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} align="right" sx={{ mt: 2 }}>
              {btnShow && (
                <Button onClick={handleSave} variant="contained">
                  <SaveIcon /> Guardar
                </Button>
              )}
              <IconButton
                onClick={() => {
                  props.setOpen(false);
                  setLoading(true);
                }}
              >
                {/* <RemoveIcon /> */}
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
      {showAlert && (
        <Alert severity="error">
          La descripción y la selección de asistentes son obligatorias para
          guardar.
        </Alert>
      )}
    </Paper>
  );
};

export default Direction;
