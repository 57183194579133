import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";

const TermsDriver = () => {

  useEffect(()=> {
    window.scrollTo(0, 0);
  }, [])

	return (
      <Grid 
        zIndex={20} 
        sx={{ 
          mx: { xs: '1rem', sm: '50px', md: '100px', xl: '300px' } 
        }}
      >
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography 
            component="h1"  
            sx={{ 
              fontSize: { xs: 25, sm: 30, md: 30, xl: 30 }, 
              mt: { xs: '10vh', sm: '12vh', md: '12vh', xl: '12vh' },
              mb: { xs: '24vh', sm: '18vh', md: '18vh', xl: '18vh' },
              width: '400px',
              fontWeight: 'bold', 
              textAlign: 'center',
              color: '#ED5C01', 
            }}>
            Terminos y Condiciones Transportador
          </Typography>
        </Grid>
        <div>
          <p style={{ fontWeight: 'bold' }}>TÉRMINOS Y CONDICIONES DE LAS APLICACIONES ELECTRÓNICAS Y/O PLATAFORMA EN LÍNEA DE GLOBAL TRUCKER SAS PROPIETARIO, POSEEDOR, TENEDOR O CONDUCTOR DE VEHÍCULO (S)</p>
          <div style={{ textAlign: "justify" }}>
            <p>GLOBAL TRUCKER SAS (en adelante GLOBAL TRUCKER SAS o el Agente) es una sociedad por acciones simplificada constituida legalmente bajo las leyes de la República de Colombia y domiciliada en la ciudad de Bogotá D.C. (Colombia), tal y como consta en el respectivo certificado de existencia y representación legal expedido por la Cámara de Comercio de Bogotá D.C., con Número de Identificación Tributaria (NIT) 901.688.594-4, ubicada en la dirección física que a continuación se detalla Diagonal 79 B  #63-13 con correo electrónico  federico.beltran@outlook.com  y cuyo objeto social principal es entre otros la creación de soluciones tecnológicas para transporte, comercio electrónico, manejo y explotación comercial de bases de datos e información.</p>
            <p>El acceso, uso o registro en la Plataforma dispuesta por el Agente implicará la aceptación de los términos cuando así se manifieste expresamente, y en particular de las condiciones que a continuación se detallan:</p>
          </div>
          <ol style={{ listStyleType: "upper-roman"}}>
            <li>
              <span>DEFINICIONES</span>
              <ul>
                <li>Propietario, Poseedor, Tenedor de Vehículo(s): Persona natural o jurídica que detenta la capacidad jurídica para comprometer a un vehículo o varios en una movilización o envio.</li>
                <li>Conductor de Vehículo(s): Persona natural que con el consentimiento del Propietario, Poseedor, Tenedor de Vehículo(s) opera el equipo comprometido a una movilización, transporte o envio.</li>
                <li>Usuario: Cualquier persona natural o jurídica que acceda, use o se registre en la Plataforma, el cual podrá ser Cliente de movilización, transporte o envio, el Usuario podrá ser Propietario, Poseedor, Tenedor o Conductor de Vehículo(s).</li>
              </ul>
            </li>
            <li>
              <p>ÁMBITO DE APLICACIÓN</p>
              <p>Los presentes términos y condiciones se aplicarán a todo Usuario al acceder y/o registrarse en la Plataforma. Para ello el Usuario deberá cumplir con el lleno de los requisitos mínimos exigidos y dar su aceptación expresa a los presentes términos y condiciones la que le será requerida por la Plataforma.</p>
              <p>El Usuario manifiesta que tiene al menos 18 años de edad. Los menores solo pueden usar los servicios dispuestos en la Plataforma junto con sus padres o tutores.</p>
            </li>
            <li>
              <p>PROPIEDAD INTELECTUAL</p>
              <p>La propiedad de todos los contenidos y elementos susceptibles de protección a través del régimen de derechos de autor o propiedad industrial que se encuentren en la Plataforma de GLOBAL TRUCKER SAS, es enteramente de la sociedad en cita. Por lo tanto cualquier acto de cualquier Usuario que resulte o pueda resultar violatorio a derechos y/o propiedad de los contenidos aquí dispuestos implicará para el usuario las sanciones previstas en la ley.</p>
            </li>
            <li>
              <p>LICENCIA PARA USO DE LA PLATAFORMA</p>
              <p>GLOBAL TRUCKER SAS, concede al Usuario una vez este se haya registrado en debida forma en la Plataforma una licencia de acceso limitada a la vigencia de su registro para su uso personal (no transferible). La solicitud de servicios por los mismos se entenderán realizados por el titular de la cuenta y contraseña asignada por la Plataforma.</p>
            </li>
            <li>
              <p>ACCESO Y FUNCIONAMIENTO DE LA PLATAFORMA</p>
              <p>Al registrarse el Usuario en la Plataforma dispuesta por GLOBAL TRUCKER SAS deberá diligenciar y/o completar determinada información tanto del propietario, poseedor, tenedor o conductor del vehículo (s) como de las características técnicas y de operatividad del mismo. A cada Usuario le será asignado un correo de ingreso y una contraseña para el acceso, uso e identificación del mismo en el ámbito de la Plataforma teniendo en cuenta para el efecto la información suministrada por el Usuario.</p>
              <p>El Usuario se obliga a suministrar información correcta y verdadera, a su vez mantener la misma en su cuenta de forma exacta, completa y actualizada, en su defecto GLOBAL TRUCKER SAS resolverá unilateralmente estas condiciones.</p>
              <p>GLOBAL TRUCKER SAS no asumirá responsabilidad alguna por situaciones generadas por desconexiones temporales o permanentes que sufran Usuarios de la Plataforma que obedezcan o puedan obedecer a labores de soporte, mantenimiento o actos de terceros o que no le sean imputables o que puedan constituir fuerza mayor o caso fortuito.</p>
            </li>
            <li>
              <p>PRODUCTOS TERCEROS EN LA PLATAFORMA</p>
              <p>Cuando se sugiera algún producto y/o servicio dentro de la Plataforma, es de exclusiva autonomía del Usuario decidir su utilización, y por lo tanto la Plataforma no asume ninguna responsabilidad por los perjuicios directos o indirectos, ciertos o eventuales, pasados, presentes o futuros, que se llegaren a derivar de dicha utilización.</p>
            </li>
            <li>
              <p>IDENTIFICACIÓN DEL USUARIO, REGISTRO Y TRATAMIENTO DE DATOS PERSONALES</p>
              <p>La información incluida en el registro está protegida por la Ley 1266 de 2008, la Ley 1581 de 2012 y Decreto 1377 de 2013 de Habeas Data, por la cual la Plataforma se compromete a usar la información dada únicamente para el fin establecido en los presentes términos y condiciones, en la política de datos personales y política o aviso de privacidad, entre los límites establecidos por la ley de la República de Colombia.</p>
              <p>Si hubiere lugar a una venta, cesión, fusión, escisión, cambio en el control societario, transferencia de activos o transferencia global de activos, reorganización o liquidación de GLOBAL TRUCKER SAS, entonces, la Plataforma podrá, discrecionalmente y bajo cualquier título, transferir, transmitir, vender o asignar los datos recibidos, a cualquier tercero vinculado con alguna de las operaciones descritas o a una o más partes relevantes.</p>
              <p>Al aceptar los presentes términos y condiciones, el usuario también acepta el contenido de la política de datos personales, procedimientos, política o aviso de privacidad adoptados por la Plataforma.</p>
            </li>
            <li>
              <p>DERECHOS DEL USUARIO</p>
              <span>El Usuario tiene derecho a:</span>
              <ol>
                <li>Registrarse como tal dentro de la Plataforma, para lo cual serán validadas por parte del GLOBAL TRUCKER SAS un nombre de usuario, que será el correo electrónico y una contraseña que le permitirán identificarse dentro de la Plataforma en todas y cada una de las operaciones respectivas.</li>
                <li>Conservar, sin perjuicio de lo señalado en estos términos y condiciones, el nombre de usuario y contraseña que le han sido validadas para el acceso y uso de la Plataforma por parte del Agente.</li>
                <li>Requerir si es del caso y obtener información sobre los mecanismos de protección y seguridad de sus datos personales.</li>
                <li>Interponer peticiones, quejas o reclamos relacionados con el uso de la Plataforma.</li>
                <li>Usar la plataforma de conformidad con la ley, las sanas costumbres comerciales y lo previsto en los presentes términos y condiciones.</li>
                <li>Recibir el Manifiesto de carga y/o guía y demás documentación pertinentes, de acuerdo a la normatividad vigente en la materia para llevar a cabo la operación. </li>
                <li>Conocer las características, precio y demás condiciones del servicio a contratar.</li>
                <li>Contratar, usando la Plataforma, todo sujeto a los requerimientos del caso y a la oferta de servicios disponible.</li>
                <li>Recibir el pago por concepto de la operación.</li>
                <li>Efectuar modificaciones o cancelaciones de los servicios, previamente aceptados; lo anterior con las consecuencias determinadas por GLOBAL TRUCKER SAS</li>
              </ol>
            </li>
            <li>
              <p>DEBERES DEL USUARIO</p>
              <span>El Usuario se obliga a:</span>
              <ol>
                <li>Suministrar en forma veraz y completa toda información que sea relevante o necesaria para la verificación de la operación propuesta a través de la Plataforma. En particular lo relacionado con los datos personales de ingreso y actualización del Propietario, Poseedor, Tenedor o Conductor del Vehículo (quien sea titular de la información) así como las dimensiones del vehículo, las características técnicas del mismo y la información solicitada por la Plataforma de acuerdo a lo exigido por los estándares dispuestos por el Plan Estratégico de Seguridad Vial.</li>
                <li>
                  <span>Abstenerse de usar la Plataforma en los siguientes eventos :</span>
                  <ol style={{ listStyleType: "lower-latin" }}>
                    <li>Llevar a cabo transporte o envio de armas, drogas, contrabando y en general cualquier forma que desconozca la ley, las sanas costumbres comerciales o los presentes términos y condiciones.</li>
                    <li>Prestar el servicio si se encuentra reportado o bien el propietario, poseedor tenedor o conductor del equipo en listas restrictivas, en particular pero no exclusivamente en la Lista Clinton y Naciones Unidas, o centrales de riesgo del riesgo del Transporte.</li>
                  </ol>
                </li>
                <li>Toda otra obligación que le corresponda en virtud de la ley o instrumento internacional aplicable a la operación respectiva.</li>
                <li>Leer en su totalidad los presentes términos y condiciones.</li>
                <li>Conservar en debida custodia el usuario y contraseña que ha sido validada por GLOBAL TRUCKER SAS, toda vez que como se ha establecido a lo largo de los términos y condiciones es personal e intransferible.</li>
              </ol>
            </li>
            <li>
              <p>SANCIONES POR USO INCORRECTO DE LA PLATAFORMA</p>
              <span>El Agente se reserva el derecho de suspender el acceso a cualquier Usuario a la Plataforma en cualquier caso en que considere que se ha hecho un uso ilegítimo, contrario a las sanas costumbres comerciales o que desconozca los presentes términos y condiciones. En particular el Agente podrá ejercer tal facultad en los siguientes casos:</span>
              <ol>
                <li>Si se utilizara la Plataforma como mecanismo para realizar conductas prohibidas, para evadir la ley o en fraude a la ley.</li>
                <li>En caso de facilitar, permitir o coadyuvar el uso de la Plataforma por terceros a los cuales no les fue asignado el correo de ingreso y la contraseña intransferible.</li>
                <li>Si se detecta cualquier clase de conducta que pueda afectar la libre competencia.</li>
                <li>Si se detecta que se ha presentado un evento que cause o pueda potencialmente causar perjuicios de terceros.</li>
                <li>Cuando no se paguen completamente las sumas a que haya lugar, en relación con los servicios contratados a través de la Plataforma.</li>
              </ol>
            </li>
            <p>Todo lo anterior se entiende sin perjuicio de la posibilidad de adoptar las acciones civiles o penales que la ley prevea, según el caso.</p>
            <li>
              <p>INDEMNIDAD</p>
              <p>Los Usuarios se obligan a mantener indemne GLOBAL TRUCKER SAS, sus empleados, dependientes, subcontratistas, subagentes o proveedores respecto de cualquier clase de acción legal o pretensión indemnizatoria de cualquier naturaleza que tenga o pueda tener como fuente el incumplimiento o cumplimiento defectuoso de cualquier contrato celebrado a través de la Plataforma.</p>
            </li>
            <li>
              <p>POLITICAS DE PRIVACIDAD</p>
              <p>GLOBAL TRUCKER SAS cuenta con una política de protección de los datos personales suministrados por los Usuarios para la realización de operaciones en la Plataforma. Dicha política, junto con las garantías de cumplimiento de la ley de habeas data contenidas en el anexo.</p>
            </li>
            <li>
              <p>MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES</p>
              <p>El Agente podrá actualizar, modificar y/o unificar unilateralmente el contenido de los presentes términos y condiciones en cualquier tiempo y modo. Sin embargo el Agente pondrá en conocimiento de los Usuarios que se hayan registrado en la Plataforma la versión actualizada de dichos términos y condiciones por una vía idónea.</p>
            </li>
            <li>
              <p>LEY APLICABLE</p>
              <p>Los presentes términos y condiciones se regirán en su integridad por las leyes de la Republica de Colombia.</p>
            </li>
            <li>
              <p>RESOLUCIÓN DE CONTROVERSIAS</p>
              <p>Los Usuarios desde ya aceptan que toda controversia, diferencia o reclamación derivada de los presentes términos y condiciones, en particular las relativas a la celebración, ejecución, interpretación, terminación o liquidación del mismo, y que no pueda ser arreglada por la vía del arreglo directo en una etapa que no superará los treinta (30) días calendario, será sometidas a un Tribunal de Arbitramento, que fallará en Derecho y que se sujetará a las siguientes reglas:</p>
              <ol style={{ listStyleType: 'upper-latin' }}>
                <li>El Tribunal tendrá su sede en el Centro de Arbitraje y Conciliación de la Cámara de Comercio Bogotá y estará integrado por un (1) árbitro que será elegido por las partes de común y acuerdo; de no poderse llevar a cabo esa designación de tal forma el Centro de Arbitraje de la Cámara de Comercio de Bogotá será el encargado de escogerlo de la lista oficial que para tal efecto lleva el Centro, a solicitud de cualquiera de las partes.</li>
                <li>La organización interna y el funcionamiento del tribunal se sujetará a los reglamentos previstos para el efecto por el Tribunal de Arbitraje de la Cámara de Comercio de Bogotá.</li>
              </ol>
            </li>
          </ol>
          <h3>ACUERDO DE SEGURIDAD ASOCIADO DE NEGOCIO GLOBAL TRUCKER SAS</h3>
          <ol>
            <li>
              <p>POLÍTICA DE SEGURIDAD Y CONTROL DE GLOBAL TRUCKER SAS</p>
              <p>Está comprometida en crear un entorno operacional confiable que permita llevar a cabo sus operaciones en un marco de seguridad, asegurando razonablemente la protección de su infraestructura y la de sus asociados de negocios, fundamentándose en los valores corporativos, en el respeto y promoción de los derechos humanos y en el cumplimiento estricto de la normatividad legal colombiana.</p>
            </li>
            <li>
              <p>ESTÁNDARES DE SEGURIDAD FÍSICA DE GLOBAL TRUCKER SAS</p>
              <p>Para GLOBAL TRUCKER SAS, es fundamental que todos sus asociados de negocios cumplan con los estándares de seguridad física exigidos por la compañía; en búsqueda de ese objetivo, todos los empleados directos y contratistas de los clientes, socios y proveedores, deberán acogerse de manera integral y voluntaria a su política de seguridad y control y los procedimientos que la desarrollan.</p>
              <span>Declaró y expresó de manera espontánea, libre y voluntaria que:</span>
              <ol>
                <li>
                  <p>Conozco, entiendo y en consecuencia aplicaré de manera integral la política de seguridad y control de GLOBAL TRUCKER SAS</p>
                </li>
                <li>
                  <p>Conozco, entiendo y en consecuencia aplicaré de manera integral, el sistema integral para la prevención y control del lavado de activos, la financiación del terrorismo y el financiamiento de la proliferación de armas de destrucción masiva (SIPLAFT) cuyo contenido aparece descrito en la resolución 074854 del 21 de diciembre de 2016 emanada de la superintendencia de puertos y transportes.</p>
                </li>
                <span>Conozco y acepto que GLOBAL TRUCKER SAS:</span>
                <li>
                  <p>Enfatiza que bajo ninguna circunstancia permitirá ser utilizada como instrumento para el ocultamiento, manejo o aprovechamiento de bienes o recursos provenientes de actividades ilícitas.</p>
                </li>
                <li>
                  <p>Implementaré en cada una de las actuaciones contractuales comportamientos alineados con la cultura de legalidad, estableciendo como mandatorio que solo haré y/o permitiré actos plenamente ajustados a la normatividad legal colombiana.</p>
                </li>
                <li>
                  <p>Garantizaré mi participación y/o de mis empleados y/o contratistas en las capacitaciones y controles referidos a temas de seguridad física establecidos y programados por GLOBAL TRUCKER SAS</p>
                </li>
                <li>
                  <p>Me acojo de manera mandataria, a través del presente instrumento, al precepto según el cual frente a necesidades de seguridad pública y/o privada solo acudiré a las autoridades legítimamente constituidas y/o a las compañías de seguridad privada debidamente autorizadas y controladas por la superintendencia y vigilancia y seguridad privada.</p>
                </li>
                <li>
                  <p>(Solo para representantes legales de personas jurídicas) designaré a un funcionario para que atienda de manera expedita y oportuna los procesos de seguridad física en las operaciones que adelanten para GLOBAL TRUCKER SAS; esta persona deberá conocer, implementar y asegurar el cumplimiento en las operaciones de mi compañía, de la estrategia de seguridad de GLOBAL TRUCKER SAS y los correspondientes protocolos de actuación en eventos de seguridad física.</p>
                </li>
                <li>
                  <p>Acataré, difundiré y aseguraré el cumplimiento de la prohibición del uso de armas de fuego, aun con salvoconducto, y/o el porte y/o consumo de sustancias sicoactivas o sus precursores y/o porte y/o consumo de bebidas alcohólicas dentro de las instalaciones y/o áreas de trabajo de GLOBAL TRUCKER SAS, o en el cumplimiento de actividades relacionadas con la operación de GLOBAL TRUCKER SAS</p>
                </li>
                <li>
                  <p>Aseguraré la debida diligencia en aquellos casos que llegare a tener conocimiento acerca de infracciones al ordenamiento jurídico colombiano, adicionalmente, en los casos que dicha infracción constituya una violación a un derecho fundamental consagrado en la constitución política de Colombia, dispondré de todas las medidas pertinentes que aseguren el impulso procesal hasta que se produzca una decisión de fondo por parte de la autoridad judicial, administrativa, de control, o de policía con competencia sobre el asunto.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>Desde ya expresó que no me encuentro incurso en infracción a la política de gobierno corporativo, numeral 5B Conflicto de intereses el cual se presenta cuando en ejercicio de sus funciones y responsabilidades los miembros de Junta, los Administradores, los empleados y/o los proveedores de la Organización, al tomar una decisión, tienen la posibilidad de elegir entre el interés de la Organización y su interés personal. Al respecto, como proveedor de GLOBAL TRUCKER SASdeclaro no tener relación hasta el primero civil, segundo de consanguinidad y/o primero de afinidad con cualquier empleado de GLOBAL TRUCKER SAS</p>
              <ol>
                <li>
                  <p>Autorizo a GLOBAL TRUCKER SAS para que desde ahora y mientras permanezca vigente la relación contractual y/o comercial, verifique la información que considere pertinente, en las diferentes bases de datos y centrales de riesgo y que en cumplimiento de los términos de la ley 1581 de 2012; el decreto 1377 de 2013 y normas que apliquen, adicionen o modifiquen las anteriores; administre mis datos personales y sobre ellos realice recolección, almacenamiento, uso, circulación, supresión, y tratamiento que permita definirme como su asociado de negocio.</p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Grid>
	)
}


export default TermsDriver;