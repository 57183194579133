import Home  from './UserHome';
import UserHomeServiceDetail  from './UserHomeServiceDetail';

/*set components*/


/*set icons*/
import Face3Icon from '@mui/icons-material/Face3';

export const routes_modules = [
  {
    name: "Services",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },  
  {
    name: "Services Detail",
    path: "/detail/:id",
    component: UserHomeServiceDetail,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },  
];
