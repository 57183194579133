import {useState,useEffect} from 'react';
import {Button,ButtonGroup,Grid, Paper} from '@mui/material';
import useAxios      from '../../hooks/useAxios';

export default function GroupStates({data,defaultvalue,name,id}) {
    const BACKEND           =   "api/" + process.env.REACT_APP_BACKEND_VERSION;
    const axios             =   useAxios();
    const [data2,setData2]  =   useState(false)
    const [data3,setData3]  =   useState(false)

    useEffect(()=>{
        console.log(defaultvalue)
        if (!data2) {
            setData2(defaultvalue)    
        }
    },[defaultvalue])

    const handleChange=(key)=>{
        setData2(key)
        axios.postData({status_id:key,service_id:id},BACKEND+"/dashboard/services/status").then((response)=>{
            get_messages() 
        })
    }

    const get_messages=()=>{
        axios.getData({},BACKEND+"/dashboard/comments?id=services_status_"+id).then((response)=>{
            if (response&&response.data) {
                setData3(response.data)    
            }                
        })
    }

    useEffect(()=>{
        get_messages()
    },[])

    //console.log(id)

    return (
        <>
            <ButtonGroup  aria-label="outlined primary button group">
                {
                    data.map((row,key)=>{
                        return  <Button key={key} variant={parseInt(data2)===parseInt(row.value)?"contained":"outlined"} onClick={()=>handleChange(row.value)}>
                                    {row.label}
                                </Button>
                    })
                }        
            </ButtonGroup>
            <Grid>
                {
                  data3&&(
                    data3.map((row,key)=>{
                        return  <Grid key={key} sx={{m:1}}>
                                    <Paper sx={{p:1}}>
                                        {row.mensaje}                                    
                                    </Paper>
                                </Grid>
                    })
                  )  
                }
            </Grid>
        </>
    );
}