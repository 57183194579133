import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const documents = [
  "Documento de identidad",
  "Licencia de conducción",
  "Licencia de transito del vehiculo",
  "Certificado de cuenta bancaria",
];

function fixFullpath(fullpath) {
  // Usa una expresión regular para asegurarte de que el '/' esté antes de 'images'
  return fullpath.replace(/(\/)?images/, "/images");
}

export default function CustomizedTables({ data }) {
  console.log(data);
  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Nombre</StyledTableCell>
            <StyledTableCell align="center">Documento</StyledTableCell>
            <StyledTableCell align="center">Autor</StyledTableCell>
            <StyledTableCell align="center">Url</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: 100, overflowY: "scroll" }}>
          {!data && (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={3}>
                No Hay documentos disponibles
              </StyledTableCell>
            </StyledTableRow>
          )}
          {data &&
            Array.isArray(data) &&
            data.map((row, key) => (
              <StyledTableRow key={key}>
                <StyledTableCell align="left">
                  {documents[key] || "Documento Desconocido"}
                </StyledTableCell>
                <StyledTableCell align="left">{row.name}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.description}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <a
                    href={fixFullpath(row.fullpath)}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => console.log(row.fullpath)}
                  >
                    Abrir
                  </a>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
