import  React from  'react';
import  Grid  from  '@mui/material/Grid';

import  './home.css';

const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="home-content">
              <Grid className="home-center">
                {props.children}                
              </Grid>
            </Grid>
});


export default Container
