import { Typography, Grid, Button } from "@mui/material";
import Container from "../../../components/Theme/DashboardUsuario2";
import useAsyncStorage from "../../../hooks/useAsyncStorage";
import { NavLink } from "react-router-dom";
import React from "react";
import MerchandiseValue from "../../../components/Input/MerchandiseValueDefault";
import MerchandiseWeight from "../../../components/Input/MerchandiseWeightDefault";
import Resumen from "../../../components/Resumen";
import Back from "../../../components/Button/Back";

const component = "orden-servicios";
const step = "step9";
const title = "Valor declarado";

const Home = () => {
  const componentStorage = useAsyncStorage(component);
  const master = useAsyncStorage("master");
  const [inputs, setInputs] = React.useState({});
  const [disabled, setDisabled] = React.useState(true);

  const handleEvent = async (_data) => {
    try {
      await componentStorage.setDataToStorage(component, _data);
      const result = await componentStorage.getDataFromStorage(component);
      if (
        result.merchandise_value &&
        result.merchandise_value.length > 0 &&
        result.merchandise_weight &&
        result.merchandise_weight.length > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (componentStorage.data) {
      setInputs(componentStorage.data);
    }

    const verify = async () => {
      const result = await componentStorage.getDataFromStorage(component);

      if (
        result.merchandise_value &&
        result.merchandise_value.length > 0 &&
        result.merchandise_weight &&
        result.merchandise_weight.length > 0
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

    verify();
  }, [master.data]);

  return (
    <Container>
      <Grid>
        <Typography
          variant="h3"
          className="text-secondary title-main"
          align="center"
        >
          {title}
        </Typography>
        <Grid>
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs={11} md={5}>
              <Grid sx={{ p: 2 }}>
                <Typography>
                  ¿Cuál es el valor declarado de la mercancía?
                </Typography>
                <MerchandiseValue
                  name="merchandise_value"
                  handleEvent={handleEvent}
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </Grid>
              <Grid sx={{ p: 2 }}>
                <Typography>¿Cuál es el peso de la mercancía?</Typography>
                <MerchandiseWeight
                  name="merchandise_weight"
                  handleEvent={handleEvent}
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </Grid>
              <Grid sx={{ mt: 2, p: 2 }}>
                <Back />
                <Button
                  disabled={disabled}
                  variant={"contained"}
                  component={NavLink}
                  to={"../" + step}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={11} md={5}>
              <Resumen
                inputs={inputs}
                label="Resumen del servicio"
                data={master.data}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Home;
