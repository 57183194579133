import { Box, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import StateContext from "../../helpers/ContextState";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import useAxios from "../../hooks/useAxios";
import DatosFacturacion from "../DatosFacturacion";
import DatosBasicos from "./Components/DatosBasicos";

const keys = "master";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 1, height: 450, overflow: "hidden", overflowY: "auto" }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let getInit = false;

export default function BasicTabs() {
  const context = React.useContext(StateContext);
  const { getDataFromStorage } = useAsyncStorage(keys);
  const axios = useAxios();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [storeLoading, setStoreLoading] = React.useState(false)
  const [basicLoading, setBasicLoading] = React.useState(false)
  const user = useAsyncStorage("user");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

  const getInit = () => {
    axios
      .getData({}, BACKEND + "/dashboard/user-profile")
      .then((response) => {
        if (response && response.data) {
          setData(response.data);
          setInputs(response.data);
        }
      })
      .catch((error) => {
        console.error("Error al cargar los datos del perfil:", error);
        // Maneja el error como sea necesario
      });
  };

  React.useEffect(() => {
    getInit();
    getDataFromStorage(keys).then((response) => {
      if (response) {
      }
    });
  }, []);

  const onSubmitStore = async (e, data) => {
    let data_prev = user.data;
    setStoreLoading(true)
    await axios.postData(data, "api/v1/dashboard/billing").then((response) => {
      if (response && response.data) {
        user.setDataToStorage("user", { ...data_prev, ...response.data });
        user.setDataToStorage("user2", { ...data_prev, ...response.data });
      }
      getInit();
    })
    .finally(() => {
      setStoreLoading(false)
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setBasicLoading(true)
    axios
      .postDataAttachment(data, BACKEND + "/dashboard/user-profile")
      .then((response) => {
        if (response && response.data && response.data.id) {
          getInit(true);
        }
      })
      .finally(() => {
        setBasicLoading(false)
      });
  };

  if (data === null) {
    return <Typography>Cargando información...</Typography>; // Muestra un mensaje de carga mientras se obtienen los datos
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ justifyContent: "center" }}
        >
          <Tab label="Datos" {...a11yProps(0)} />
          <Tab label="Facturación" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DatosBasicos 
          data={data} 
          setData={setData} 
          onSubmit={onSubmit} 
          loading={basicLoading} 
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid mx={10} pt={5}>
          <DatosFacturacion
            md={6}
            inputs={inputs}
            onSubmit={onSubmitStore}
            setData={setInputs}
            loading={storeLoading}
          />
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
