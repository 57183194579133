import Container from "../../../components/Theme/Dashboard";
import Table from "../../../components/Table";
import View from "./View";
import usePermissions from "../../../hooks/usePermissions";

const td = [
  {
    label: "Servicio",
    field: "id",
    className: "",
    align: "left",
  },
  {
    label: "Nombre",
    field: "cliente",
    className: "",
    align: "left",
  },
  /*
  {
    label:"Dirección",
    field:"direccion",
    className:"",
    align:"left",
  },
  */
  {
    label: "Tipo de carga",
    field: "tipo_carga",
    className: "",
    align: "left",
  },
  {
    label: "Tipo de servicio",
    field: "tipo_servicio",
    className: "",
    align: "left",
  },
  {
    label: "Modalidad",
    field: "modalidad",
    className: "",
    align: "left",
  },
  {
    label: "Tipo de vehiculo",
    field: "tipo_vehiculo",
    className: "",
    align: "left",
  },
  {
    label: "Teléfono",
    field: "cliente_celular",
    className: "",
    align: "left",
  },
  {
    label: "Acción",
    field: "event",
    className: "",
    align: "center",
    event: [
      {
        label: "Ver",
        action: "view",
        component: View,
      },
    ],
  },
];

const Home = (props) => {
  const permissions = usePermissions("clientes", "index", {
    title: 'Servicios',
    filters: { serviceStatus: true },
    component: Table,
    td: td,
    skipAdd: true,
    fullScreenModal: true,
    download: true,
  });
  return <Container>{permissions.render()}</Container>;
};
export default Home;
