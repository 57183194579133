import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const TransportConditionsModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Condiciones de Transporte
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Aquí estará la descripción de las condiciones de transporte.
        </Typography>
        <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
          Cerrar
        </Button>
      </Box>
    </Modal>
  );
};

export default TransportConditionsModal;
