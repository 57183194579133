import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Input from "../../Input";
import { NavLink } from "react-router-dom";
import useFormData from "../../../hooks/useFormData";
import useAxios from "../../../hooks/useAxios";
import React from "react";
import Select from "../../../components/Select/Select3";

const Basic = ({ data }) => {
  const { formData, onChange, setFormData } = useFormData();
  const axios = useAxios();
  const [master, setMaster] = React.useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    axios.putData(formData).then((response) => {
      if (response && response.data && response.data.id) {
        //getInit(true)
      }
    });
  };

  React.useEffect(() => {
    const getInit = async () => {
      const response = await axios.getData(
        {},
        "api/v1/dashboard/users/byRole/Usuario?all=true"
      );
      if (response && response.data && response.data.master) {
        setMaster(response.data.master);
      }
    };
    getInit();
  }, []);

  React.useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data, setFormData]);

  const customOnchange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.name,
    }));
  };

  return (
    <form onSubmit={onSubmit}>
      {console.log(data)}

      <Grid container spacing={2}>
        {data.razon_social && (
          <>
            <Grid item xs={12} md={6}>
              <Input
                defaultValue={data.razon_social}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="razon_social"
                type="text"
                label="Razón social"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                defaultValue={data.nit}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="nit"
                type="text"
                label="NIT"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={3}>
          <Input
            defaultValue={data.name}
            required
            onChange={onChange}
            size="small"
            fullWidth
            name="name"
            type="text"
            label="Nombre"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            defaultValue={data.surname}
            required
            onChange={onChange}
            size="small"
            fullWidth
            name="surname"
            type="text"
            label="Apellido"
          />
        </Grid>
        {data.documento_representante_legal && (
          <>
            <Grid item xs={12} md={3}>
              <Select
                defaultValue={data.tipo_documento_representante_legal}
                data={master.tipo_documento || []}
                name="tipo_documento_representante_legal"
                onChange={customOnchange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                defaultValue={data.documento_representante_legal}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="documento_representante_legal"
                type="text"
                label="Nro Doc"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3}>
          <Input
            defaultValue={data.email}
            required
            onChange={onChange}
            size="small"
            fullWidth
            name="email"
            type="email"
            label="Correo electrónico"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            defaultValue={data.celular}
            required
            onChange={onChange}
            size="small"
            fullWidth
            name="celular"
            type="text"
            label="Celular"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent={"center"}>
        {data && data.cedula && (
          <Grid item xs={12} sm={3} md={2}>
            <Button
              fullWidth
              variant="contained"
              component={NavLink}
              to={data.cedula}
              target="_blank"
            >
              Cédula
            </Button>
          </Grid>
        )}
        {data && data.cedula && (
          <Grid item xs={12} sm={3} md={2}>
            <Button
              fullWidth
              variant="contained"
              component={NavLink}
              to={data.rut}
              target="_blank"
            >
              RUT
            </Button>
          </Grid>
        )}
        {data && data.camara_comercio && (
          <Grid item xs={12} sm={3} md={2}>
            <Button
              fullWidth
              variant="contained"
              component={NavLink}
              to={data.camara_comercio}
              target="_blank"
            >
              Cámara de Comercio
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container justifyContent="right">
        <Grid item xs={12} md={6} align="right" sx={{ mt: 2 }}>
          <Button variant="contained" type="submit">
            Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default Basic;
