import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.css";
import image from "../../assets/png/imagen-header.png";
import Menu from "./Menu";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Header = () => {
  const navigate = useNavigate();

  const handleIAmChargeGeneratorClick = () => {
    navigate("/auth/register");
  };

  const handleGoToTruckerSection = () => {
    const element = document.getElementById('trucker');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Grid component={"header"} className="header">
      <Grid sx={{ pt: 3, position: 'absolute', width: '100%', top: 0, zIndex: 10 }}>
        <Menu position='absolute' />
      </Grid>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="header bg1">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ pt: { xs: 5, md: 10 }, px: { xs: 2, md: 4 } }}
            >
              <Grid item xs={12} md={6} sx={{ pr: { xs: 0, md: 2 } }}>
                <Typography
                  align="center"
                  component={"p"}
                  sx={{ fontSize: { xs: 14, md: 15 }, lineHeight: 1.2, fontWeight: 'bold', mt: 12, pt: 2 }}
                >
                  NUESTRA INNOVADORA TECNOLOGÍA ES LA HERRAMIENTA PERFECTA HACIA EL CAMINO UN PROCESO
                  DE CARGA TRANSPARENTE, EFICIENTE Y SIN RESISTENCIAS QUE ESTABLECE NUEVOS ESTÁNDARES EN LA
                  INDUSTRIA.
                </Typography>
                <Grid sx={{ mb: { xs: 24, sm: 45, md: 35, xl: 30 }, mt: { xs: 10, sm: 5, md: 15, xl: 10 }, ml: { xl: 2 }, display: 'flex', justifyContent: { xs: 'center'}, gap: { xs: 1, md: 5, xl: 13 } }}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%', maxWidth: { xs: 270, md: 300, xl: 320 }, borderRadius: 50, fontSize: { xs: 14, md: 20, xl: 21 }, py: { xs: 0.5, sm: 2, md: 2, xl: 3 } }}
                    onClick={handleIAmChargeGeneratorClick}
                  >
                    Soy Generador de carga
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ width: '100%', maxWidth: { xs: 270, md: 300, xl: 320 }, borderRadius: 50, fontSize: { xs: 14, md: 20, xl: 21 }, py: { xs: 0.5, sm: 2, md: 2, xl: 3 } }}
                    onClick={handleGoToTruckerSection}
                  >
                    Soy Trucker
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="header-image"
                sx={{
                  textAlign: { xs: "center", md: "right" },
                  mt: { xs: 4, md: 20 },
                }}
              >
                <img src={image} className="img-fluid" alt="Header" />
              </Grid>
            </Grid>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="header bg2">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ pt: { xs: 5, md: 10 }, px: { xs: 2, md: 4 } }}
            >
              <Grid item xs={12} md={6} sx={{ pr: { xs: 0, md: 2 } }}>
                <Typography
                  align="center"
                  component={"p"}
                  sx={{ fontSize: { xs: 14, md: 15 }, lineHeight: 1.2, fontWeight: 'bold', mt: 10, pt: 2 }}
                >
                  NO SÓLO MOVEMOS CARGA, IMPULSAMOS SUEÑOS CON RESPONSABILIDAD, CONFIABILIDAD
                  Y RESULTADOS GARANTIZADOS. ENTREGAMOS UNA EXPERIENCIA EXCEPCIONAL A NUESTROS
                  USUARIOS, CULTIVANDO UNA CULTURA ENFOCADA A LAS SOLUCIONES DEL MERCADO. 
                </Typography>
                <Grid sx={{ mb: { xs: 24, sm: 45, md: 35, xl: 30 }, mt: { xs: 10, sm: 5, md: 15, xl: 10 }, ml: { xl: 2 }, display: 'flex', justifyContent: { xs: 'center'}, gap: { xs: 1, md: 5, xl: 13 } }}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%', maxWidth: { xs: 270, md: 300, xl: 320 }, borderRadius: 50, fontSize: { xs: 14, md: 20, xl: 21 }, py: { xs: 0.5, sm: 2, md: 2, xl: 3 } }}
                    onClick={handleIAmChargeGeneratorClick}
                  >
                    Soy Generador de carga
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ width: '100%', maxWidth: { xs: 270, md: 300, xl: 320 }, borderRadius: 50, fontSize: { xs: 14, md: 20, xl: 21 }, py: { xs: 0.5, sm: 2, md: 2, xl: 3 } }}
                    onClick={handleGoToTruckerSection}
                  >
                    Soy Trucker
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="header-image"
                sx={{
                  textAlign: { xs: "center", md: "right" },
                  mt: { xs: 4, md: 20 },
                }}
              >
                <img src={image} className="img-fluid" alt="Header" />
              </Grid>
            </Grid>
          </div>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
};

export default Header;
