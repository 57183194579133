import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { NavLink } from "react-router-dom";
import facebookIcon from "../../assets/png/Facebook.png";
import linkedinIcon from "../../assets/png/Linkedin.png";
import logo from "../../assets/png/logo-transparent-home.png";
import twitterIcon from "../../assets/png/Twitter.png";

function ResponsiveAppBar() {
  return (
    <AppBar position="static" variant="home" sx={{ paddingX: { xs: 0, md: 0, xl: 20, backgroundColor: "#F4F5F9" } }} >
      <Container maxWidth="xl" >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 1, // Ajustar el padding superior e inferior
            py: 8
          }}
        >
          <img src={logo} alt="logo" style={{ height: "50px" }} />{" "}
          {/* Ajustar el tamaño del logo */}
        </Box>
        <Grid sx={{ backgroundColor: '#E4E6EB', width: '100%', height: 2, }} />
        <Toolbar disableGutters sx={{ marginTop: 2 }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              component={NavLink}
              to="./"
              sx={{
                color: "#0A142F",
                textDecoration: "none",
              }}
            >
              Terms
            </Typography>
            <Typography
              component={NavLink}
              to="./"
              sx={{
                color: "#0A142F",
                textDecoration: "none",
              }}
            >
              Privacy
            </Typography>
            <Typography
              component={NavLink}
              to="./"
              sx={{
                color: "#0A142F",
                textDecoration: "none",
              }}
            >
              Cookies
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton component={NavLink} to="./" sx={{ mx: 1 }}>
                <img
                  src={linkedinIcon}
                  alt="LinkedIn"
                  style={{ height: "30px" }}
                />
              </IconButton>
              <IconButton component={NavLink} to="./" sx={{ mx: 1 }}>
                <img
                  src={facebookIcon}
                  alt="Facebook"
                  style={{ height: "30px" }}
                />
              </IconButton>
              <IconButton component={NavLink} to="./" sx={{ mx: 1 }}>
                <img
                  src={twitterIcon}
                  alt="Twitter"
                  style={{ height: "30px" }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: { xs: "none", md: "flex" },
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography
              component={NavLink}
              to="/terminos"
              sx={{
                color: "#0A142F",
                textDecoration: "none",
                mr: 2,
              }}
            >
              Terms
            </Typography>
            <Typography
              component={NavLink}
              to="/privacidad"
              sx={{
                color: "#0A142F",
                textDecoration: "none",
                mr: 2,
              }}
            >
              Privacy
            </Typography>
            <Typography
              component={NavLink}
              to="./"
              sx={{
                color: "#0A142F",
                textDecoration: "none",
              }}
            >
              Cookies
            </Typography>
          </Box>

          <Box
            sx={{
              width: "50%",
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <IconButton component={NavLink} to="./" sx={{ mx: 1 }}>
              <img
                src={linkedinIcon}
                alt="LinkedIn"
                style={{ height: "30px" }}
              />
            </IconButton>
            <IconButton component={NavLink} to="./" sx={{ mx: 1 }}>
              <img
                src={facebookIcon}
                alt="Facebook"
                style={{ height: "30px" }}
              />
            </IconButton>
            <IconButton component={NavLink} to="./" sx={{ mx: 1 }}>
              <img src={twitterIcon} alt="Twitter" style={{ height: "30px" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
