import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Documents from "../Uploads/Documents";
import DocumentsBancarios from "../Uploads/DocumentsBancarios";
import TransportadorBasic from "./Components/TransportadorBasic";
import TransportadorVehiculo from "./Components/TransportadorVehiculo";
import TransportadorWallet from "./Components/TransportadorWallet";
import { Grid } from "@mui/material";
import Table2 from "../../components/Table/Table5";
import Table3 from "../../components/Table/Table7";
import Table4 from "../../components/Table/Table10";
import Button from "@mui/material/Button";
import SelectStatus from "../../components/Button/Select";
import useAxios from "../../hooks/useAxios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 1, height: 400, overflow: "hidden", overflowY: "scroll" }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let getInit = false;
let getCheck = false;

export default function BasicTabs({ data }) {
  const axios = useAxios();
  const [documents, setDocuments] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [backgroundCheckData, setBackgroundCheckData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  getInit = () => {
    const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

    axios
      .getData(
        {},
        BACKEND + "/dashboard/documentos/doc_adiconales_usuario_" + data.id
      )
      .then((response) => {
        if (response && response.data) {
          setDocuments(response.data);
        }
      });
  };

  React.useEffect(() => {
    getInit();
  }, []);

  const handleChangeDocuments = (data) => {
    if (
      Object.entries(data) &&
      Object.entries(data)[0] &&
      Object.entries(data)[0][1]
    ) {
      setDocuments(Object.entries(data)[0][1]);
    }
    getInit();
  };

  React.useEffect(() => {
    getCheck();
    console.log("Data entrante", backgroundCheckData);
  }, [backgroundCheckData]);

  getCheck = () => {
    if (data && data.check_id) {
      console.log("CHECKID", data.check_id);
      const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

      axios
        .getData({}, BACKEND + "/background-check-by-checkId/" + data.check_id)
        .then((response) => {
          console.log("Response status:", response.status); // Log del estado de la respuesta
          console.log("Response headers:", response.headers); // Log de las cabeceras de la respuesta
          console.log("Data background check:", response); // Log de los datos de la respuesta

          if (response) {
            setBackgroundCheckData(response);
          }
        })
        .catch((error) => {
          console.error("Error fetching background check data:", error);
          console.log(
            "Error status:",
            error.response ? error.response.status : "No response status"
          );
          console.log(
            "Error data:",
            error.response ? error.response.data : "No response data"
          );
        });
    } else {
      console.log("No check_id available");
    }
  };

  const handleConsultTruora = () => {
    console.log("Botón 'Consultar' clickeado"); // Log para saber que se ha cliqueado el botón
    setIsLoading(true); // Activar la carga

    if (data && data.documento_representante_legal) {
      console.log(
        "Documento para consultar",
        data.documento_representante_legal
      );

      const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

      axios
        .postData(
          {},
          BACKEND + "/background-check/" + data.documento_representante_legal
        )
        .then((response) => {
          console.log("Response status:", response.status);
          console.log("Data consultada background check:", response);

          if (response) {
            setBackgroundCheckData(response);
          }
        })
        .catch((error) => {
          console.error("Error fetching background check data:", error);
          console.log(
            "Error status:",
            error.response ? error.response.status : "No response status"
          );
          console.log(
            "Error data:",
            error.response ? error.response.data : "No response data"
          );
        })
        .finally(() => {
          setIsLoading(false); // Desactivar la carga, se ejecuta tanto en éxito como en error
        });
    } else {
      console.log("No documento available");
      setIsLoading(false); // Desactivar la carga si no hay documento
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ background: "#f2f2f2" }}
        >
          <Tab label="Información básica" {...a11yProps(0)} />
          <Tab label="Datos Vehículo" {...a11yProps(1)} />
          <Tab label="Datos Bancarios" {...a11yProps(2)} />
          <Tab label="Documentos" {...a11yProps(3)} />
          <Tab label="Estatus del transportador" {...a11yProps(4)} />
          <Tab label="Información adicional" {...a11yProps(5)} />
          <Tab label="Estado de cartera" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {console.log("Datos", data)}
        <TransportadorBasic data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TransportadorVehiculo data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DocumentsBancarios
              id={"doc_adiconales_usuario_" + data.id + "_datos_bancarios"}
              base={"doc_adiconales_usuario_" + data.id}
              onUpload={handleChangeDocuments}
              placeholder="Ej: Certificación bancaria, estados de cuenta."
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table3
              data={
                documents[
                  "doc_adiconales_usuario_" + data.id + "_datos_bancarios"
                ]
              }
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_adiconales_usuario_" + data.id}
              base={"doc_adiconales_usuario_" + data.id}
              onUpload={handleChangeDocuments}
              placeholder="Ej: Pase de conducción, Pasaporte, etc. "
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table2 data={documents["doc_adiconales_usuario_" + data.id]} />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <SelectStatus id={data.id} defaultValue={data.status} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="consult-field">
            <Button
              variant="contained"
              type="submit"
              onClick={handleConsultTruora}
            >
              Consultar
            </Button>
            {isLoading && <div className="loader">Consultando datos...</div>}
          </Grid>
          <Grid item xs={12} md={8}>
            <Table4 data={backgroundCheckData} />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        {console.log("Id transportador", data.id)}
        <TransportadorWallet data={data} />
      </CustomTabPanel>
    </Box>
  );
}
