import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import "./index";
import logo from "../../assets/png/logo-transparent-home.png";
import { NavLink } from "react-router-dom";

const pages = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Mi Cuenta",
    href: "/auth/login",
  },
  {
    label: "Mis Servicios",
    href: "./",
  },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" variant="home">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ width: "25%", display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, key) => (
                <MenuItem
                  component={NavLink}
                  to={page.href}
                  key={key}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
              <MenuItem
                component={NavLink}
                to="/auth/register"
                onClick={handleCloseNavMenu}
              >
                <Typography textAlign="center">Registro</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ width: "25%", display: { xs: "none", md: "flex" } }}>
            {pages.map((page, key) => (
              <Button
                component={NavLink}
                to={page.href}
                variant="outlined"
                color="primary"
                key={key}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: "block", mr: 1, borderRadius: 5 }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
            }}
          >
            <img src={logo} alt="logo" />
          </Box>

          <Box
            sx={{
              width: "25%",
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Button
              component={NavLink}
              to="/auth/register"
              variant="contained"
              color="primary"
              sx={{ my: 2, display: "block", borderRadius: 5 }}
            >
              Registro
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
