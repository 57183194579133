/*set components*/
import Dashboard  from './DashboardUsuario';
import Servicios  from './Servicios/user';
import Services  from './Servicios';
import Auth  from './Auth';
import Profile  from './Profile/ProfileMe';

/*set icons*/
import HomeIcon from '@mui/icons-material/Home';

export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Dashboard,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },

  {
    name: "Home",
    path: "/dashboard",
    component: Dashboard,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Camioneros",
    url: "camiones",
    path: "camiones/*",
    component: Dashboard,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Orden servicios",
    url: "orden-servicios",
    path: "orden-servicios/*",
    component: Services,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Generadores de Carga",
    url: "carga",
    path: "carga/*",
    component: Dashboard,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Mis Servicios",
    url: "servicios",
    path: "servicios/*",
    component: Servicios,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Salir",
    url: "auth/exit",
    path: "auth/*",
    component: Auth,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Mi Perfil",
    url: "perfil",
    path: "perfil/*",
    component: Profile,
    menu: true,
    private: true,
    className:"item-orange",
    icon: <HomeIcon />,
  },

  {
    name: "Servicios",
    url: "orden-servicios/refrigerada",
    path: "orden-servicios/*",
    component: Servicios,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },

  {
    name: "Servicios",
    url: "orden-servicios/refrigerada",
    path: "orden-servicios/*",
    component: Servicios,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },

  {
    name: "Servicios",
    url: "orden-servicios/refrigerada",
    path: "orden-servicios/*",
    component: Servicios,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },



];
