import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useAxios from "../../../hooks/useAxios";
import React, { useEffect, useState } from "react";

const Basic = ({ data }) => {
  const axios = useAxios();
  const [responseData, setResponseData] = useState(null);
  const [requestFinished, setRequestFinished] = useState(false);

  useEffect(() => {
    const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

    axios
      .getData({}, BACKEND + "/dashboard/servicios-by-transporter/" + data.id)
      .then((response) => {
        setResponseData(response);
        setRequestFinished(true);
      });
  }, []);

  // Función para formatear los valores a pesos colombianos
  const formatToColombianPesos = (value) => {
    return parseFloat(value).toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography variant="h5" gutterBottom>
                    Información de la cartera
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responseData && (
                <>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        Servicios no pagados:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {responseData.unpaid_services}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        Servicios pagados:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {responseData.paid_services}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        Valor total de los servicios aceptados:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {formatToColombianPesos(responseData.total_unpaid)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        Valor total pagado de los servicios aceptados:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {formatToColombianPesos(responseData.total_paid)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1">
                        <b>Saldo total a pagar:</b>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {formatToColombianPesos(responseData.total_payable)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              {requestFinished && !responseData && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h5" align="center">
                      No hay información de cartera
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Basic;
