import { Button, CircularProgress, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Input from '../Input';

const DatosFacturacionNatural=({inputs, tipo, loading, md = 6, onSubmit})=>{

    
    const [data, setData]           =   React.useState(inputs||{})
    const onChange=(e)=>{
        setData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    useEffect(() => {
        setData({...data, facturacion_tipo_persona: tipo })
    }, [tipo])

    return      <Grid component="form" container justifyContent={"center"} spacing={1}>
                    <Grid item xs={12} md={md}>
                        <Input size={"small"} defaultValue={data.facturacion_name} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_name" label="Nombre"/>  
                    </Grid>
                    <Grid item xs={12} md={md}>
                        <Input size={"small"} defaultValue={data.facturacion_surname} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_surname" label="Apellido"/>  
                    </Grid>
                    <Grid item xs={12} md={md}>
                        <Input size={"small"} defaultValue={data.facturacion_cedula} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_cedula" label="Cédula Legal"/>  
                    </Grid>
                    <Grid item xs={12} md={md}>
                        <Input size={"small"} defaultValue={data.facturacion_direccion} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_direccion" label="Dirección"/>  
                    </Grid>
                    <Grid item xs={12} md={md}>
                        <Input size={"small"} defaultValue={data.facturacion_telefono} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_telefono" label="Teléfono"/>  
                    </Grid>
                    <Grid item xs={12} md={md}>
                        <Input size={"small"} defaultValue={data.facturacion_email} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_email" type="email" label="Correo electrónico"/>  
                    </Grid>
                    <Grid item xs={12}  align="right">
                        <Button 
                            variant="contained" 
                            sx={{ display: 'flex', gap: 1, minWidth: 120 }}
                            disabled={loading} 
                            onClick={(e) => onSubmit(e, data)}
                        >
                            Guardar
                            {loading && <CircularProgress size={16} />}
                        </Button>
                    </Grid>                    
                </Grid>
}
export default DatosFacturacionNatural;