import React, { useEffect, useRef, useState } from 'react';

export default function AutoPlayVideo ({ video }) {
  const videoRef = useRef(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [firstInteraction, setFirstInteraction] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting ) {
          if(isUserInteracted){
            videoElement.muted = false
            videoElement.play().catch((error) => {
              console.error('Error trying to play the video:', error);
            });
            
          }else {
            if(firstInteraction === false){
              setFirstInteraction(true)
              videoElement.muted = true
              videoElement.play()
              setTimeout(() => {
                videoElement.muted = false
                videoElement.pause();
              }, 470);
            }
          }
        } else {
          videoElement.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust this threshold as needed
    });

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, [isUserInteracted, firstInteraction]);

  const handleUserInteraction = () => {
    setIsUserInteracted(true);
  };

  return (
    <div onClick={handleUserInteraction}>
      <video ref={videoRef} width="100%" controls>
        <source src={video} type="video/mp4" />
        Su navegador no soporta la etiqueta de video.
      </video>
    </div>
  );
};