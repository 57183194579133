import React from "react";
import { Grid, Typography } from "@mui/material";
import "./index.css";
import image from "../../assets/png/girl-box.png";

const Header = () => {
  return (
    <Grid component={"section"}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={11} md={4}>
          <img src={image} className="img-fluid" />
        </Grid>
        <Grid item xs={11} md={4} sx={{ pt: 20 }}>
          <Grid align="center">
            <Typography component={"p"} sx={{ fontSize: 20, lineHeight: 2 }}>
              "¿Listo para optimizar?
            </Typography>
            <Typography component={"p"} sx={{ fontSize: 15, lineHeight: 2 }}>
              Inscríbete con TRUCKER hoy mismo y descubre cómo nuestra
              plataforma avanzada, nuestro servicio especializado y nuestras
              soluciones a medida pueden transformar su experiencia. Juntos,
              avanzaremos".
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
