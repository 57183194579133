import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../hooks/useFormData';
import Container    from '../../../components/Theme/Form';
import Input        from '../../../components/Input';
import Avatar        from '../../../components/Avatar';
import Upload        from '../../../components/Theme/Upload';
import useAxios      from '../../../hooks/useAxios';
//import useAsyncStorage      from '../../../hooks/useAsyncStorage';



const Edit = ({handleClose, getInit}) => {
  //const storage           =   useAsyncStorage();
  const axios             =   useAxios();
  
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    axios.putData(formData).then((response)=>{
      if (response&&response.data&&response.data.id&&getInit) {
        getInit(true)
        handleClose()
      }
    })
  }

  /*

  React.useEffect(()=>{
    console.log(storage)
    //setFormData(data)
    
  },[setFormData])

  */

  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid sx={{mb:4,}} align="center">
                  <Upload onChange={setFormData} data={formData} name="avatar">
                    <Avatar sx={{width:100,height:100}} src={formData.avatar} />
                  </Upload>
                </Grid>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={""} required onChange={onChange} size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input defaultValue={""}  required onChange={onChange} size="small" fullWidth name="celular" type="text" label="Celular"/>
                  </Grid>
                </Grid>
                <Grid container justifyContent="right">
                  <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                    <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                      Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </form>
          );
};

export default Edit;
