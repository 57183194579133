import React from "react";
import { Typography, Grid, useMediaQuery } from "@mui/material";
import Container from "../../../components/Theme/DashboardUsuario";
import UserHomeServicios from "../../../components/Tabs/UserHomeServicios";

const Web = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Typography variant="h3" className="text-secondary" align="center">
            Historial de servicios
          </Typography>
          <Grid sx={{ mx: isMobile ? 0 : 5 }}>
            <UserHomeServicios />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Web;
