import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const RatingStars = ({name,inputs,setInputs,defaultValue}) => {
  
    const [rating, setRating]   =   useState(parseInt(defaultValue)||0);
    const [stop, setStop]       =   useState(defaultValue||false);

    const handleMouseOver = (hoveredRating) => {
        if (!stop) {
            setRating(hoveredRating);    
        }        
    };

    const handleMouseLeave = () => {
        if (rating === 0 && !stop) {
            setRating(0);
        }
    };

    const handleStarClick = (clickedRating) => {
        setRating(clickedRating);
        setStop(true);
        if (name&&setInputs) {
            setInputs({...inputs,[name]:clickedRating})
        }
    };

    return    <div>
                {
                    [1, 2, 3, 4, 5].map((star) => (
                        <span
                                key={star}
                                onMouseOver={() => handleMouseOver(star)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleStarClick(star)}>
                            {
                                star <= rating ? (
                                    <StarIcon sx={{ fontSize:50,color: '#333' }} />
                                ) : (
                                    <StarBorderIcon sx={{ fontSize:50 }} />
                                )
                            }
                        </span>
                    ))
                }
            </div>
};

export default RatingStars;
