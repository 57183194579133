import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";

const Privacy = () => {

  useEffect(()=> {
    window.scrollTo(0, 0);
  }, [])

	return (
    <Grid 
      zIndex={20} 
      sx={{ 
        mx: { xs: '1rem', sm: '50px', md: '100px', xl: '300px' } 
      }}
    >
      <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography 
          component="h1"  
          sx={{ 
            fontSize: { xs: 25, sm: 30, md: 30, xl: 30 }, 
            mt: { xs: '10vh', sm: '12vh', md: '12vh', xl: '12vh' },
            mb: { xs: '27vh', sm: '25vh', md: '25vh', xl: '25vh' },
            width: '400px',
            fontWeight: 'bold', 
            textAlign: 'center',
            color: '#ED5C01', 
          }}>
          Politica de privacidad
        </Typography>
      </Grid>
      <div>
        <ul>
          <li>
            <h2>POLÍTICA DE PRIVACIDAD</h2>
            <p>Esta Política de privacidad describe nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege dando cumplimiento de la 1581 de 2012 y el Decreto 1377 de 2013. Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad.</p>
            <p>La Política de privacidad es parte integral de los TÉRMINOS Y CONDICIONES DE LAS APLICACIONES ELECTRÓNICAS Y/O PLATAFORMA EN LÍNEA DE GLOBAL TRUCKERY DE GLOBAL TRUCKER. (en adelante GLOBAL TRUCKER SAS) y su objeto es dar cumplimiento a las disposiciones vigentes en Colombia sobre protección de datos personales. Por consiguiente, reconociendo que todas las personas tienen derecho a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bases de datos o archivos, y de conformidad con la Ley 1581 de 2012 y el Decreto 1377 de 2013, GLOBAL TRUCKER SAS  establece lo siguiente:</p>
          </li>
          <li>
            <h2>INTERPRETACIÓN Y DEFINICIONES</h2>
            <ol>
              <li>
                <p>Interpretación:</p>
                <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</p>
              </li>
              <li>
                <p>Definiciones:</p>
                <p>A los efectos de esta Política de Privacidad:</p>
                <ul>
                  <li>
                    <p>Cuenta: significa una cuenta única creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio.</p>
                  </li>
                  <li>
                    <p>Afiliado significa una entidad que controla, es controlada o está bajo control común con una parte, donde "control" significa la propiedad del 50% o más de las acciones, participación accionaria u otros valores con derecho a voto para la elección de directores u otra autoridad administrativa.</p>
                  </li>
                  <li>
                    <p>La aplicación: se refiere a GLOBAL TRUCKER, el programa de software proporcionado por la Compañía.</p>
                  </li>
                  <li>
                    <p>Empresa: (referida como "la Empresa", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a GLOBAL TRUCKER SAS., Diagonal 79B #63-13.</p>
                  </li>
                  <li>
                    <p>País: se refiere a: Colombia</p>
                  </li>
                  <li>
                    <p>Dispositivo: significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</p>
                  </li>
                  <li>
                    <p>Los datos personales: son cualquier información que se relaciona con un individuo identificado o identificable.</p>
                  </li>
                  <li>
                    <p>Servicio: se refiere a la Aplicación.</p>
                  </li>
                  <li>
                    <p>Proveedor de servicios: significa cualquier persona física o jurídica que procesa los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por la Empresa para facilitar el Servicio, proporcionar el Servicio en nombre de la Empresa, realizar servicios relacionados con el Servicio o ayudar a la Empresa a analizar cómo se utiliza el Servicio.</p>
                  </li>
                  <li>
                    <p>Los datos de uso: se refieren a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página o la reserva de un viaje en la aplicación).</p>
                  </li>
                  <li>
                    <p>Usted: se refiere a la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.</p>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h2>DETALLE DE LA POLÍTICA DE PRIVACIDAD</h2>
            <p>GLOBAL TRUCKER SAS  en su ánimo de proteger los datos y privacidad de las personas que visitan su sitio web y/o aplicaciones electrónicas y/o plataforma en línea y dando cumplimiento a lo señalado en la Ley 1581 de 2012 y el Decreto 1377 de 2013 informa que tiene adoptadas políticas de privacidad y de tratamiento de la información que contienen los derechos y procedimientos para el correcto y eficaz ejercicio por parte de los titulares. Usamos sus datos personales para proporcionar y mejorar el servicio. Al usar el servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad.</p>
            <ol>
              <li>
                <p>Datos de uso: El tratamiento que hace GLOBAL TRUCKER SAS  sobre los datos personales, consiste en recolectar, almacenar, depurar, usar, analizar, circular, suprimir, actualizar, y cruzar con información propia o de terceros autorizados, con la finalidad de: enviar correos electrónicos u otras formas de correspondencia que contengan información de interés (enfocada en la explotación comercial de todas las actividades que se desprendan del uso de la Plataforma. Venta de insumos, seguros, artículos de cualquier tipo) y/o para la creación e identificación del Usuario asignado por GLOBAL TRUCKER SAS.</p>
                <p>Los datos de uso pueden incluir información como la dirección del protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, dispositivo único identificadores y otros datos de diagnóstico.</p>
                <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores únicos de dispositivos y otros datos de diagnóstico.</p>
                <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</p>
              </li>
              <li>
                <p>Información recopilada durante el uso de la aplicación: Al utilizar nuestra Aplicación, con el fin de proporcionar características de nuestra Aplicación, podemos recopilar, con su permiso previo:</p>
                <ul>
                  <li>
                    <p>Información sobre su ubicación. Usamos esta información para 1) ofrecerte servicios de carga de acuerdo con tu ubicación, 2) servicios en la vía durante el viaje, 3) seguimiento de las actividades durante el viaje, así como enviarle ofertas de servicios de carga a nivel nacional que sean oportunas de acuerdo con su ubicación geográfica y preferencias de carga. Los servicios ofertados son determinados a partir de la ubicación del dispositivo.</p>
                  </li>
                  <li>
                    <p>Imágenes y otra información de la cámara y la biblioteca de fotos de su dispositivo. Usamos esta información para poder capturar evidencias en el registro de la aplicación y durante la operación para poder mejorar nuestro servicio puede ser para capturar y almacenar las imágenes de pantalla en la carpeta de imágenes de los dispositivos móviles de cada usuario para que puedan adjuntar y enviar la documentación necesaria. Su objetivo es la habilitación en la plataforma como un conductor que cumple con las validaciones de seguridad exigidas por la legislación colombiana.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>Usamos esta información para proporcionar características de Nuestro Servicio, para mejorar y personalizar nuestro Servicio. La información se puede cargar en los servidores de la Compañía y/o en el servidor de un Proveedor de servicios o simplemente se puede almacenar en su dispositivo.</p>
                <p>Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de su dispositivo</p>
              </li>
              <li>
                <p>Información recopilada fuera del uso de la aplicación: Al utilizar nuestra Aplicación, con el fin de proporcionar características de nuestra Aplicación, podemos recopilar, con su permiso previo:</p>
                <ul>
                  <li>
                    <p>Información sobre su ubicación: Usamos esta información para 1) ofrecerte servicios de carga de acuerdo con tu ubicación, 2) servicios en la vía durante el viaje, 3) seguimiento de las actividades durante el viaje, así como enviarle ofertas de servicios de carga a nivel nacional que sean oportunas de acuerdo con su ubicación geográfica y preferencias de carga. Los servicios ofertados son determinados a partir de la ubicación del dispositivo así no se encuentre activo dentro de la aplicación.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de su dispositivo.</p>
              </li>
              <li>
                <p>Uso de sus datos personales GLOBAL TRUCKER SAS  los Datos Personales para los siguientes propósitos:</p>
                <ul>
                  <li>
                    <p>Para proporcionar y mantener nuestro Servicio: incluso para monitorear el uso de nuestro Servicio.</p>
                  </li>
                  <li>
                    <p>Para gestionar Su Cuenta: para gestionar su registro como usuario del Servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para Usted como usuario registrado.</p>
                  </li>
                  <li>
                    <p>Para la ejecución de un contrato: el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que haya adquirido o de cualquier otro contrato con Nosotros a través del Servicio.</p>
                  </li>
                  <li>
                    <p>Para contactarlo: para contactarlo por correo electrónico, llamadas telefónicas, SMS, WhatsApp u otras formas equivalentes de comunicación electrónica, como notificaciones automáticas de una aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.</p>
                  </li>
                  <li>
                    <p>Para proporcionarle: noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o preguntó, a menos que haya optado por no recibir dicha información.</p>
                  </li>
                  <li>
                    <p>Para gestionar sus solicitudes: Para atender y gestionar sus solicitudes hacia nosotros.</p>
                  </li>
                  <li>
                    <p>Para transferencias comerciales: podemos usar su información para evaluar o realizar una fusión, venta, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como una empresa en marcha o como parte de una quiebra, liquidación, o procedimiento similar, en el que los Datos personales que tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.</p>
                  </li>
                  <li>
                    <p>Para otros fines: podemos utilizar su información para otros fines, como el análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.</p>
                  </li>
                  <li>
                    <p>Podemos compartir su información personal en las siguientes situaciones:</p>
                  </li>
                  <li>
                    <p>Con Proveedores de Servicios: Podemos compartir su información personal con Proveedores de Servicios para monitorear y analizar el uso de nuestro Servicio, para contactarlo.</p>
                  </li>
                  <li>
                    <p>Para transferencias comerciales: Podemos compartir o transferir su información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la Compañía, financiamiento o adquisición de todo o una parte de Nuestro negocio a otra compañía.</p>
                  </li>
                  <li>
                    <p>Con afiliados: podemos compartir su información con nuestros afiliados, en cuyo caso exigimos a esos afiliados que respeten esta Política de privacidad. Los afiliados incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlamos o que están bajo control común con nosotros.</p>
                  </li>
                  <li>
                    <p>Con socios comerciales: Podemos compartir su información con nuestros socios comerciales para ofrecerles ciertos productos, servicios o promociones.</p>
                  </li>
                  <li>
                    <p>Con otros usuarios: cuando comparte información personal o interactúa en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede distribuirse públicamente al exterior.</p>
                  </li>
                  <li>
                    <p>Con su consentimiento: podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>Uso de Información de Ubicación con Fines de Transporte:</p>
                <p>Al utilizar nuestros servicios de transporte, el Usuario reconoce y acepta que GLOBAL TRUCKER SAS recopilará y utilizará información de su ubicación proporcionada por el proveedor de servicios de geolocalización (GPS) con el fin de facilitar y mejorar la experiencia de transporte. Esta información de ubicación será utilizada exclusivamente para los siguientes propósitos:</p>
                <ul>
                  <li>
                    <p>Seguimiento del Viaje: Permitir que GLOBAL TRUCKER SAS identifique la ubicación del Usuario durante el viaje, con el fin de brindar servicios de transporte eficientes y mejorar la seguridad.</p>
                  </li>
                  <li>
                    <p>Asistencia en Ruta: Facilitar asistencia en tiempo real y proporcionar información sobre la ubicación del vehículo para mejorar la navegación y la eficiencia del servicio.</p>
                  </li>
                  <li>
                    <p>Seguridad del Usuario: Garantizar la seguridad del Usuario al contar con información precisa sobre la ubicación en caso de situaciones de emergencia o necesidad de asistencia.</p>
                  </li>
                  <li>
                    <p>Recomendación de Servicios Asociados: Utilizar los datos de ubicación para recomendar y ofrecer servicios asociados a nuestro Ecosistema Digital, como viajes ofrecidos de nuestros clientes, ofertas personalizadas, promociones, servicios en la vía, programas académicos, beneficios financieros, insumos para el mantenimiento de su vehículo, seguros, descuentos y otros servicios que puedan ser de interés para el Usuario y su operación, con el objetivo de mejorar su experiencia global.</p>
                  </li>
                  <li>
                    <p>Para la activación y prestación de servicios en la plataforma GLOBAL TRUCKER, el usuario hará entrega a GLOBAL TRUCKER SAS  de las credenciales de acceso a las plataformas bajo las cuales administra los dispositivos GPS de rastreo de sus vehículos, dando el derecho y la autorización a GLOBAL TRUCKER SAS, para monitorear, hacer seguimiento, gestionar la flota de vehículos, garantizar la seguridad y el cumplimiento de las obligaciones contractuales.</p>
                  </li>
                  <li>
                    <p>GLOBAL TRUCKER SAS se compromete a utilizar las credenciales de acceso proporcionadas, para los fines mencionados anteriormente, comercializar los productos y servicios de su portafolio relacionados con la operación de transporte terrestre de carga a nivel nacional, y a mantener la confidencialidad de la información obtenida a través de dichas credenciales, en cumplimiento con las leyes de protección de datos vigentes.</p>
                  </li>
                  <li>
                    <p>El Usuario otorga su consentimiento explícito para que GLOBAL TRUCKER SAS  comparta la información de ubicación recopilada con sus proveedores de servicios de geolocalización (GPS) únicamente con los fines mencionados anteriormente.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>Retención de sus datos personales, GLOBAL TRUCKER SAS  conservará sus Datos personales sólo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Conservaremos y utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</p>
                <p>GLOBAL TRUCKER SAS  también conservará los Datos de uso para fines de análisis interno. Los Datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de Nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos</p>
              </li>
              <li>
                <p>Transferencia de sus datos personales. Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de Su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de Su jurisdicción</p>
                <p>Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su acuerdo con esa transferencia.</p>
                <p>La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus datos personales a una organización o país, a menos que existan controles adecuados establecidos, incluida la seguridad de Sus datos y otra información personal.</p>
              </li>
            </ol>
          </li>
          <li>
            <h2>ESPECIFICACIONES DE LAS POLÍTICAS DE TRATAMIENTO DE LA INFORMACIÓN Y PRIVACIDAD.</h2>
            <p>La persona jurídica responsable de los datos personales recolectados a través de los distintos canales es GLOBAL TRUCKER SAS  sociedad por acciones simplificada constituida legalmente bajo las leyes de la República de Colombia y domiciliada en la ciudad de Bogotá D.C. (Colombia), tal y como consta en el respectivo certificado de existencia y representación legal expedido por la Cámara de Comercio de Bogotá D.C., con Número de Identificación Tributaria (NIT) 901.688594-4, ubicada en la dirección física que a continuación se detalla Diagonal 79B #63-13, con correo electrónico federico.beltran@outlook.com y cuyo objeto social principal es entre otros la creación de soluciones tecnológicas para transporte, comercio electrónico, manejo y explotación comercial de bases de datos e información</p>
            <ol>
              <li>
                <p>PRIMERO. DERECHOS DE LOS TITULARES. El Usuario, en su calidad de titular de la información, en los términos definidos por la Ley 1581 de 2012 y el Decreto 1377 de 2013, tendrá los siguientes derechos</p>
                <ul>
                  <li>
                    <p>Conocer, actualizar y rectificar sus datos personales frente a los responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros, frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado</p>
                  </li>
                  <li>
                    <p>Solicitar prueba de la autorización otorgada al responsable del Tratamiento salvo cuando expresamente se exceptúa como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de esta Ley.</p>
                  </li>
                  <li>
                    <p>Ser informado por el responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que les ha dado a sus datos personales.</p>
                  </li>
                  <li>
                    <p>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la presente Ley y las demás normas que la modifiquen, adicionen o complementen.</p>
                  </li>
                  <li>
                    <p>Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el responsable o Encargado han incurrido en conductas contrarias a esta Ley y a la Constitución</p>
                  </li>
                  <li>
                    <p>Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>SEGUNDO. PROCEDIMIENTO PARA EJERCER LOS DERECHOS DE LOS TITULARES. Los derechos a conocer, actualizar, rectificar y suprimir los datos personales que maneja GLOBAL TRUCKER SAS, así como revocar la autorización, o solicitar prueba de la misma, podrán ser ejercidos mediante petición escrita dirigida a Sergio Serrano Rivero, encargado por GLOBAL TRUCKER SAS  para la atención de dichas peticiones, quejas, reclamos y/o consultas, en la siguiente dirección: Diagonal 79B #63-13 o enviando un correo electrónico a <a href="mailto:federico.beltran@outlook.com">federico.beltran@outlook.com.</a> El titular deberá indicar su nombre completo y número de identificación, así como la dirección física y/o electrónica donde recibirá la respuesta. La consulta será atendida en el término de diez (10) días hábiles contados a partir del recibo de esta. En caso de no ser posible cumplir con dicho término, se informará al respectivo titular de la información, explicando las razones de la demora y señalando la fecha en que se atenderá la consulta, sin exceder nunca el término de cinco (5) días hábiles al vencimiento del primer término.</p>
                <p>En caso de reclamos, supresión de datos personales y/o solicitud de revocatoria de la autorización otorgada para el tratamiento de los mismos, se seguirá el procedimiento establecido en el artículo 15 de la Ley 1581 de 2012. En este caso, el titular y sus causahabientes podrán presentar el reclamo ante el responsable del tratamiento mediante solicitud dirigida a éste con la identificación del titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se pretendan hacer valer. Si el reclamo resulta incompleto, se requerirá al titular dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo</p>
                <p>En caso de que quien reciba el reclamo no sea competente para resolverlo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado</p>
                <p>Una vez recibido el reclamo completo, se incluirá una leyenda en la base de datos que diga “reclamo en trámite” y el motivo dentro del término de dos (2) días hábiles, la cual se mantendrá mientras se decida la solicitud.</p>
              </li>
              <li>
                <p>TERCERO. RECOPILACIÓN DE LA INFORMACIÓN.  La información que para los efectos antes señalados será solicitada es la siguiente: únicamente aquella que sea pertinente y adecuada para el fin con el cual se solicita.</p>
              </li>
              <li>
                <p>CUARTO. OBTENCIÓN DE INFORMACIÓN. GLOBAL TRUCKER SAS  puede obtener información acerca de sus Usuarios de la siguiente manera: En forma directa, cuando el Usuario ingresa información a la Plataforma; o indirectamente, cuando el responsable de los datos recolecta la información a partir de las visitas de los Usuarios en la Plataforma.</p>
              </li>
              <li>
                <p>QUINTO. ACEPTACIÓN. El Usuario acepta que los datos aportados al momento de su registro u otorgamiento de la información sean tratados por GLOBAL TRUCKER SAS, en las formas y con las finalidades antes mencionadas. En el caso de envío de información a través de alguno de los medios ofrecidos al Usuario, se entenderá que el mismo presta su consentimiento expreso para el envío de este tipo de contenido al momento del registro o de otorgar la información.</p>
              </li>
              <li>
                <p>SEXTO. AUTORIZACIONES. El tratamiento de datos personales que realiza GLOBAL TRUCKER SAS  requiere del consentimiento libre, expreso, previo o al menos concomitante e informado del titular de estos. GLOBAL TRUCKER SAS, en su condición de responsable del tratamiento de datos personales, ha dispuesto de los mecanismos necesarios para obtener la autorización de los titulares garantizando en todo caso que sea posible verificar el otorgamiento de dicha autorización. La autorización podrá constar en medio electrónico habida cuenta del medio por el cual se reciba la información, siempre garantizando su posterior consulta. Al respecto, GLOBAL TRUCKER SAS  ha adoptado las medidas necesarias para mantener registros o mecanismos técnicos o tecnológicos idóneos de cuándo y cómo obtuvo autorización por parte de los titulares de datos personales para el tratamiento de estos</p>
                <p>El titular de la información autoriza expresamente a GLOBAL TRUCKER SAS  para que éste la almacene de la forma que considere más oportuna y cumpla con la seguridad requerida para la protección de los datos.</p>
              </li>
              <li>
                <p>SÉPTIMO. CONFIDENCIALIDAD-Sin perjuicio de las anteriores autorizaciones por parte del Usuario, GLOBAL TRUCKER SAS , se compromete al cumplimiento de su obligación de secreto de los datos de carácter personal, uso privado y de su deber de tratarlos con confidencialidad, y asume, a estos efectos, las medidas de índole técnica, organizativa y de seguridad necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, de acuerdo con lo establecido en la ley, y en los tratados internacionales suscritos por Colombia que rigen la materia.</p>
              </li>
              <li>
                <p>OCTAVO. RESPONSABILIDAD DEL USUARIO. - El Usuario responderá, en cualquier caso, por la veracidad de los datos facilitados y/o proporcionados, reservándose GLOBAL TRUCKER SAS  el derecho a excluir de los servicios registrados a todo Usuario que haya facilitado datos falsos sin perjuicio de la posibilidad de tomar cualquier otra acción legal a que haya lugar a discrecionalidad de GLOBAL TRUCKER SAS.</p>
              </li>
              <li>
                <p>NOVENO. REQUERIMIENTOS DE LAS AUTORIDADES. - GLOBAL TRUCKER SAS  cooperarán con las autoridades competentes para garantizar el cumplimiento de las leyes en materia de protección de la propiedad industrial, derechos de autor, prevención del fraude y otras materias. Los titulares de la información autorizan expresamente a GLOBAL TRUCKER SAS  a suministrar cualquier información personal sobre ellos con la finalidad de dar cumplimiento a cualquier requerimiento de autoridad competente, y con el fin de cooperar con ellas en la medida en que discrecionalmente se entienda necesario y adecuado en relación con cualquier investigación de un ilícito, infracción de derechos de propiedad intelectual o industrial, u otra actividad que sea ilegal o que pueda exponer a GLOBAL TRUCKER SAS  a cualquier responsabilidad legal.</p>
              </li>
              <li>
                <p>DÉCIMO. CONSIDERACIONES ESPECIALES PARA EL TRATAMIENTO DE LA INFORMACIÓN DE NIÑOS, NIÑAS Y ADOLESCENTES. - De conformidad con lo dispuesto en el artículo 7° de la Ley 1581 de 2012, GLOBAL TRUCKER SAS  garantiza que no realiza tratamiento alguno sobre datos personales de niños, niñas y adolescentes que no sean de naturaleza pública. Está proscrito el acceso de menores de edad a la Plataforma. En caso de vulneración de la disposición antes señalada GLOBAL TRUCKER SAS  no se hará responsable por las consecuencias jurídicas derivadas de dicho incumplimiento.</p>
              </li>
              <li>
                <p>DÉCIMO PRIMERO. SEGURIDAD DE LA INFORMACIÓN GLOBAL TRUCKER SAS  se comprometen a proteger la información personal de sus clientes. De esta manera ha implementado procedimientos físicos, administrativos y técnicos apropiados para proteger dicha información personal de pérdida, mal uso o alteración y asegurar que sólo puedan acceder a ella aquellos que se encuentren debidamente autorizados. GLOBAL TRUCKER SAS  garantizan que el acceso a la información personal se permite exclusivamente a personas relacionadas con los fines estipulados en esta política de tratamiento.</p>
              </li>
              <li>
                <p>DÉCIMO SEGUNDO. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES DE LA POLÍTICA. - Al visitar la Plataforma, o prestar su consentimiento para el tratamiento de datos personales por algún otro medio, usted acepta las prácticas descritas en el presente documento. GLOBAL TRUCKER SAS  no se hacen responsable de las políticas, el contenido y las prácticas de otras compañías, a las cuales se pudiese acceder a través de la Plataforma.</p>
              </li>
              <li>
                <p>DÉCIMO TERCERO. CAMBIOS EN LA POLÍTICA DE TRATAMIENTO - GLOBAL TRUCKER SAS  se reservan el derecho a modificar las presentes políticas de tratamiento. Así mismo, comunicará oportunamente a los titulares de los datos, antes de que los mismos se implementen, cualquier cambio sustancial en las políticas de tratamiento por el medio que usualmente estos utilicen con GLOBAL TRUCKER SAS  en el curso normal de sus relaciones. En el evento, en que se trate de un cambio no sustancial, GLOBAL TRUCKER SAS  publicará a través de la Plataforma dicha situación.</p>
              </li>
              <li>
                <p>DÉCIMO CUARTO. FECHA D ENTRADA EN VIGOR DE LA PRESENTE POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN Y PERÍODO DE VIGENCIA DE LA BASE DE DATOS. - La presente política estará vigente a partir del cuatro (4) de abril de 2023. La base de datos objeto de tratamiento asociada a esta política, estará vigente durante el tiempo que sea razonable y necesario de acuerdo con las finalidades aquí contenidas, atendiendo a las disposiciones aplicables a la materia de que se trata y a los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información. Una vez cumplida la finalidad del tratamiento se procederá a la supresión de los datos personales en posesión de GLOBAL TRUCKER SAS, no obstante, los datos se conservarán para el cumplimiento de una obligación legal o contractual.</p>
                <p>Los términos utilizados en el presente anexo sobre cumplimiento de la ley 1581 de 2012 y el Decreto 1377 de 2013 de habeas data como lo son Autorización, Base de Datos, Dato personal, Encargado del Tratamiento, responsable del Tratamiento, Titular, Tratamiento deben ser entendidos en el marco de la Ley 1581 de 2012 y el Decreto 1377 de 2013 por la cual se dictan disposiciones generales para la protección de datos personales.</p>
              </li>
            </ol>
          </li>
        </ul>
      </div>
    </Grid>
	)
}


export default Privacy;