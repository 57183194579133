import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';


const TextFieldSizes = ({ autoComplete,name, label, id, defaultValue, size, onChange, placeholder, fullWidth, type, required, sx ,maxLength}) => {
  return (
    <Box sx={sx||{mb:2}}>
      <InputLabel >
          {label}
      </InputLabel>
      <TextField
        autoComplete={autoComplete||"on"}
        inputProps={{
          maxLength: {maxLength}, // Establecer maxLength en 50
        }}
        className='borderRadius'
        required={required||false}
        type={type||"text"}
        fullWidth={fullWidth}
        name={name}
        id={id}
        defaultValue={defaultValue}
        size={size}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Box>
  );
};

TextFieldSizes.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default TextFieldSizes;
