import  Grid  from  '@mui/material/Grid';
import './LoadingFullWidth.css'
import LoopIcon from '@mui/icons-material/Loop';

const LoadingFullWidth=()=>{
  return  <Grid className="LoadingFullWidthContainer">
              <Grid className="LoadingFullWidthContent">
                  <LoopIcon className="spin" sx={{fontSize:100}} color="white"/>
                  <Grid className="LoadingFullWidthText efecto">Haciendo magia...</Grid>
              </Grid>
          </Grid>
}
export default LoadingFullWidth
