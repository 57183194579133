import { Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PersonaJuridica from './PersonaJuridica';
import PersonaNatural from './PersonaNatural';

const DatosFacturacion=(props)=>{
    const [tipo,setTipo] = useState()
    
    useEffect(() => {
        setTipo(props.inputs.facturacion_tipo_persona)
    }, [props.inputs.facturacion_tipo_persona])

    return  <Grid>
                {
                    (
                        <Grid align="left" mb={3}>
                            Selecciona el tipo de Persona
                            <Grid display={"flex"} alignItems={"center"} gap={1}>
                                <Typography>
                                    Natural
                                </Typography>
                                <Switch 
                                    checked={tipo == 2 ? true : false}
                                    onChange={(e) => setTipo(e.target.checked ? 2 : 1)}
                                />
                                <Typography>
                                    Jurídica
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }              
                {
                    tipo&&tipo===1&&(
                        <PersonaNatural {...props}  tipo={tipo} />
                    )
                } 
                {
                    tipo&&tipo===2&&(
                        <PersonaJuridica {...props}  tipo={tipo} />
                    )
                }  
            </Grid>
}
export default DatosFacturacion;