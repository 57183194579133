import { Grid, Typography } from "@mui/material";

import image from "../../assets/png/imagen-header.png";

const Header = () => {
  return (
    <Grid component={"section"}>
      <Grid container justifyContent={"center"} sx={{ pt: 5 }}>
        <Grid item xs={11} md={4}>
          <Typography
            component={"p"}
            variant="subtitle"
            sx={{ fontSize: 20, lineHeight: 2 }}
          >
            Confía en nuestros TRUCKER’s, vamos a transportar tus sueños de
            manera segura y puntual
          </Typography>
          <Typography component={"p"} sx={{ fontSize: 15, lineHeight: 2 }}>
            Te conectamos con la comunidad de transportadores más confiable de
            América Latina.
          </Typography>
        </Grid>
        <Grid item xs={11} md={4}>
          <img src={image} className="img-fluid" />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Header;
