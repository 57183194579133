import React from 'react';
import Grid  from  '@mui/material/Grid';
import Button  from  '@mui/material/Button';
import useFormData  from '../../../hooks/useFormData';
import Container    from '../../../components/Theme/Form';
import Input        from '../../../components/Input';
import Select        from '../../../components/Select';
import Cropper        from '../../../components/Cropper/UploadNew';
import useAxios      from '../../../hooks/useAxios';

const Edit = ({handleClose, data , getInit}) => {
  const axios             =   useAxios();
  const { formData,
          onChange,
          setFormData }   =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    //axios.postData(formData).then((response)=>{
    let send= {...formData} 
    delete(send.component)
    axios.postData(send).then((response)=>{
      if (response&&response.data&&response.data.id&&getInit) {
        getInit(true)
        handleClose()
      }
    })
  }

  React.useEffect(()=>{
    if (data) {
      setFormData(data)
    }
  },[data,setFormData])

  console.log(data)

  return (  <form onSubmit={onSubmit}>
              <Container >
                <Grid sx={{mb:4,}} align="center">
                  <Cropper onChange={setFormData} data={formData} name="image"/>                    
                </Grid>
                {
                  formData&&formData.image&&(
                    <>
                      <Grid container spacing={2} sx={{mb:2}}>
                        <Grid item xs={12} >
                          <Select placeholder="Estado" 
                                  items={[
                                    {
                                      label:"Encendido",
                                      id:8
                                    },
                                    {
                                      label:"Apagado",
                                      id:7
                                    },
                                  ]}
                                  defaultValue={formData.ma_estatus_id} 
                                  data={formData}
                                  onChange={setFormData} 
                                  name="ma_estatus_id" 
                                  label="Estado"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input defaultValue={data.label} required onChange={onChange} size="small" fullWidth name="label" type="text" label="Nombre"/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input defaultValue={data.slug} onChange={onChange} size="small" fullWidth name="slug" type="text" label="Url"/>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="right">
                        <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                          <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                            Cancelar
                          </Button>
                          <Button variant="contained" type="submit">
                            Guardar
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )
                }                
              </Container>
            </form>
          );
};

export default Edit;
