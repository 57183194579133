import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import "./index.css";

const Resumen = ({ label, data, inputs, component, response }) => {
  const [_data, _setData] = React.useState({ ...inputs, image: false });
  const [vehiculo, setVehiculo] = React.useState({});

  React.useEffect(() => {
    let change = { ...inputs };

    if (inputs.tipo_vehiculo_id) {
      const image = data.vehiculo.find(
        (search) => search.value === inputs.tipo_vehiculo_id
      );
      if (image.images) {
        change = {
          image: image.images,
          label: image.label,
        };
      }
      setVehiculo(image);
    }

    if (inputs.tipo_servicio_id) {
      const servicio = data.tipo_servicio_id.find(
        (search) => search.value === inputs.tipo_servicio_id
      );
      if (servicio.label) {
        change = { ...change, servicio: servicio.label };
      }
    }

    if (inputs.modalidad_id) {
      const modalidad = data.modalidad_id.find(
        (search) => search.value === inputs.modalidad_id
      );
      if (modalidad.label) {
        change = { ...change, modalidad: modalidad.label };
      }
    }

    _setData(change);
  }, [inputs, data]);

  const transportationValue = parseFloat(response?.transportation_value || 0);
  const loadingValue = parseFloat(response?.loading_value || 0);
  const unloadingValue = parseFloat(response?.unloading_value || 0);
  const declaredPercentage = parseFloat(response?.declared_percentage || 0);
  const adminValue = 10;
  const totalServiceValue =
    transportationValue + loadingValue + unloadingValue + declaredPercentage;

  const isResponseValid =
    transportationValue > 0 ||
    loadingValue > 0 ||
    unloadingValue > 0 ||
    declaredPercentage > 0;

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Grid>
        <Typography variant="h6" align="center">
          {label}
        </Typography>
      </Grid>
      <Grid align="center">
        <Grid className="resumen-image-background">
          {_data.image && <img src={_data.image} alt="programandoweb" />}
        </Grid>
        <Grid>
          <b>
            {_data.label && (
              <Grid className="resumen-image-label">{_data.label}</Grid>
            )}
          </b>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          {inputs.tipo_carga_id && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              <Grid>Tipo de Vehículo:</Grid>
              <Grid>
                <b>{vehiculo.label}</b>
              </Grid>
            </Grid>
          )}
          {inputs.tipo_carga_id && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              <Grid>Tipo de carga:</Grid>
              <Grid>
                <b>{inputs.tipo_carga_id}</b>
              </Grid>
            </Grid>
          )}
          {inputs.locations && inputs.locations.length > 0 && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              {inputs.locations.map((row, key) => (
                <Grid key={key} sx={{ mb: 1 }}>
                  <Grid>
                    Ciudad {key === 0 ? "origen" : "destino"}:{" "}
                    <b> {row.address_components}</b>
                  </Grid>
                  <Grid>
                    Dirección {key === 0 ? "origen" : "destino"}:{" "}
                    <b> {row.direccion}</b>
                  </Grid>
                  <Grid>
                    Asistentes {key === 0 ? "" : ""}:{" "}
                    <b>{row.asistentes === 1 ? "Sí" : "No"}</b>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          {inputs.services_dates && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              <Grid>Fecha:</Grid>
              <Grid>
                <b>
                  {inputs.services_dates &&
                    inputs.services_dates.map(
                      (row, key) =>
                        row +
                        (key + 1 === inputs.services_dates.length ? " " : ", ")
                    )}
                </b>
              </Grid>
            </Grid>
          )}
          {inputs.merchandise_value && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              <Grid>Valor Declarado:</Grid>
              <Grid>
                <b>
                  {inputs.merchandise_value &&
                    inputs.merchandise_value.map((row, key) => {
                      const value = parseFloat(row);
                      return (
                        "$" +
                        value.toLocaleString("es-CO") +
                        (key + 1 === inputs.merchandise_value.length
                          ? " "
                          : ", ")
                      );
                    })}
                </b>
              </Grid>
            </Grid>
          )}
          {inputs.merchandise_weight && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              <Grid>Peso Mercancía:</Grid>
              <Grid>
                <b>
                  {inputs.merchandise_weight &&
                    inputs.merchandise_weight.map(
                      (row, key) =>
                        row +
                        "kg" +
                        (key + 1 === inputs.merchandise_weight.length
                          ? " "
                          : ", ")
                    )}
                </b>
              </Grid>
            </Grid>
          )}

          {inputs.merchandise_descripction && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              <Grid>Descripción:</Grid>
              <Grid>
                <b>{inputs.merchandise_descripction}</b>
              </Grid>
            </Grid>
          )}
          {inputs.merchandise_especial_descripction && (
            <Grid sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}>
              <Grid>Condiciones:</Grid>
              <Grid>
                <b>{inputs.merchandise_especial_descripction}</b>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          {response ? (
            isResponseValid ? (
              <>
                {response.transportation_value && transportationValue > 0 && (
                  <Grid
                    item
                    xs={12}
                    sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}
                  >
                    <Grid>Valor de Transporte:</Grid>
                    <Grid>
                      <b>${transportationValue.toLocaleString("es-CO")}</b>
                    </Grid>
                  </Grid>
                )}
                {response.loading_value && loadingValue > 0 && (
                  <Grid
                    item
                    xs={12}
                    sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}
                  >
                    <Grid>Valor de Carga:</Grid>
                    <Grid>
                      <b>${loadingValue.toLocaleString("es-CO")}</b>
                    </Grid>
                  </Grid>
                )}
                {response.unloading_value && unloadingValue > 0 && (
                  <Grid
                    item
                    xs={12}
                    sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}
                  >
                    <Grid>Valor de Descarga:</Grid>
                    <Grid>
                      <b>${unloadingValue.toLocaleString("es-CO")}</b>
                    </Grid>
                  </Grid>
                )}
                {response.declared_percentage && declaredPercentage > 0 && (
                  <Grid
                    item
                    xs={12}
                    sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}
                  >
                    <Grid>Valor seguro:</Grid>
                    <Grid>
                      <b>${declaredPercentage.toLocaleString("es-CO")}</b>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}
                >
                  <Grid>Valor administrativo:</Grid>
                  <Grid>
                    <b>${adminValue.toLocaleString("es-CO")}</b>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ borderBottom: "solid 1px #f2f2f2", mb: 1 }}
                >
                  <Grid>Total valor servicio:</Grid>
                  <Grid>
                    <b>${totalServiceValue.toLocaleString("es-CO")}</b>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" color="error">
                  No hay ruta disponible
                </Typography>
              </Grid>
            )
          ) : null}
        </Grid>
      </Grid>

      <Grid sx={{ mt: 1 }}>
        <Typography align="left">{component}</Typography>
      </Grid>
    </Paper>
  );
};

export default Resumen;
