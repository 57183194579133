import Container    from '../../../components/Theme/Dashboard';
import Table    from '../../../components/Table';
import usePermissions from '../../../hooks/usePermissions';


const td  = [
  {
    label:"Nombre",
    field:"producto_name",
    className:"",
    align:"left",
  },

  {
    label:"Categoría",
    field:"label",
    className:"",
    align:"left",
  },

  {
    label:"Etiquetas",
    field:"tags",
    className:"",
    align:"left",
  },

  {
    label:"Precio",
    field:"price",
    className:"",
    align:"left",
  },
  
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        byIndex:{
                  prefixed:"./productos",
                  key:"id"
                },
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                href:"0"                                                                
                                                              });
  return  <Container>
            {permissions.render()}
          </Container>
}
export default Home
