import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {formatCurrency} from '../../helpers/functions';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables({data,novedades}) {

  const [extra,setExtra]  =   React.useState(0)
  React.useEffect(()=>{

    if (novedades && novedades.length>0) {
      let new_extra=0;
        novedades.map((row,key)=>{
          if (parseFloat(row.valor)>0) {
            new_extra+=parseFloat(row.valor);
          }
          return;
        })
      setExtra(new_extra)  
    }
    
  },[novedades])

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Concepto</StyledTableCell>
            <StyledTableCell align="right">Monto</StyledTableCell>            
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: 100, overflowY: 'scroll' }}>          
          <StyledTableRow>
            <StyledTableCell align="center">
              Valor del transporte
            </StyledTableCell>
            <StyledTableCell align="right">
              {formatCurrency(data.transportation_value)}
            </StyledTableCell>            
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">
              Valor de carga
            </StyledTableCell>
            <StyledTableCell align="right">
              {formatCurrency(data.loading_value)}
            </StyledTableCell>            
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">
              Valor de descarga
            </StyledTableCell>
            <StyledTableCell align="right">
              {formatCurrency(data.unloading_value)}
            </StyledTableCell>            
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">
              Seguro
            </StyledTableCell>
            <StyledTableCell align="right">
              {formatCurrency((
                                    parseFloat(data.transportation_value)+
                                    parseFloat(data.loading_value)+
                                    parseFloat(data.unloading_value)
                                ) * (
                                      parseFloat(data.insurance_percentage)/100
                                    ))}
            </StyledTableCell>            
          </StyledTableRow>
          {
            extra>0&&(
              <StyledTableRow>
                <StyledTableCell align="center">
                  Valor extra novedades
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatCurrency(extra)}
                </StyledTableCell>            
              </StyledTableRow>
            )
          }
          
          <StyledTableRow>
            <StyledTableCell align="right">
              Total
            </StyledTableCell>
            <StyledTableCell align="right">
              {formatCurrency(  parseFloat(data.transportation_value)+
                                parseFloat(data.loading_value)+
                                parseFloat(extra)+
                                parseFloat(data.unloading_value)+
                                ((
                                    parseFloat(data.transportation_value)+
                                    parseFloat(data.loading_value)+
                                    parseFloat(extra)+
                                    parseFloat(data.unloading_value)
                                ) * (
                                      parseFloat(data.insurance_percentage)/100
                                    ) ))}
            </StyledTableCell>            
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}