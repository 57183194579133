import { useState } from "react";
import { Button, Grid } from "@mui/material";
import Container    from '../../Theme/Loading';
import Stars from "../../Stars";
import Textarea from '../../Input/Textarea';
import useAxios  from '../../../hooks/useAxios';
const UserHomeServiciosDetalleServicio=({data,getInit})=>{
    const axios                 =   useAxios();
    const [inputs,setInputs]    =   useState({  
                                                attachment:5,
                                                mensaje:"",
                                                mensaje:"",
                                            })

    const onChange=(e)=>{
        setInputs({...inputs,[e.target.name]:e.target.value})
    }
    const onSubmit=(e)=>{
        e.preventDefault()
        axios.postData(inputs,'api/v1/dashboard/servicios/detail/'+data.id+'/calificacion').then((response)=>{
            if (response&&response.data&&response.data.id) {
                getInit(true)                
            }
        })           
    }
    
    return  <Container>
                <Grid component={"form"} onSubmit={onSubmit}>
                    <Grid container justifyContent={"center"} spacing={1} sx={{pt:4}}>
                        <Grid item xs={10} md={7} align="center">
                            <Grid component={"div"}><b>Calificación</b></Grid>
                        </Grid> 
                        <Grid item xs={10} md={7} align="center">
                            <Stars name="attachment" defaultValue={data&&data.calificacion&&data.calificacion.attachment?data.calificacion.attachment:false} inputs={inputs} setInputs={setInputs}/>
                        </Grid>                                        
                        <Grid item xs={10} md={7} align="center">
                            {
                                data&&data.calificacion&&(
                                    <Grid component={"b"}>
                                        "{data.calificacion.mensaje}"
                                    </Grid>
                                )
                            }
                            {
                                !data||!data.calificacion&&(
                                    <Grid>
                                        <Textarea onChange={onChange} name="mensaje" placeholder="Observaciones"/>                        
                                    </Grid>
                                )
                            }
                            
                        </Grid>      
                        {
                            data&&(!data.calificacion||!data.calificacion.attachment)&&(
                                <Grid item xs={10} md={7} align="center">
                                    <Button variant="contained" type="submit">
                                        Calificar
                                    </Button>
                                </Grid>                                        
                            )
                        }                                  
                        
                    </Grid>
                </Grid>
            </Container>
}

export default UserHomeServiciosDetalleServicio;