import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../components/NotFound';

const useMyRoutes = (routes_modules) => {
  return (
    <Routes>
      {routes_modules.map((module, index) => {
        // Check if a layout is provided; if so, wrap the component with the layout
        const Component = module.component;
        const Layout = module.layout;

        const element = Layout ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Component />
        );

        return (
          <Route
            key={index}
            path={module.path}
            element={element}
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default useMyRoutes;
