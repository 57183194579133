import { useState, useEffect, useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  TextField,
  Box,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SelectLocationMap from "./SelectLocationMap"; // Asegúrate de ajustar la ruta de importación

const Component = ({ label, size, onChange, type, name, icon }) => {
  const apitemp = process.env.REACT_APP_GOOGLEMAP;
  const [mapOpen, setMapOpen] = useState(false);
  const [address, setAddress] = useState("");
  const inputRef = useRef(null);

  const types = type ? [type] : ["(cities)"]; // Default to cities if no type is provided

  const { ref: materialRef } = usePlacesWidget({
    apiKey: apitemp,
    strictBounds: false,
    onPlaceSelected: (place) => {
      onChange(place, name);
      setAddress(place.formatted_address);
    },
    options: {
      types: types,
      componentRestrictions: { country: "CO" }, // Restrict to Colombia
    },
  });

  const handleIconClick = () => {
    setMapOpen(true);
  };

  const handleMapSelect = (position) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK" && results[0]) {
        const place = results[0];
        onChange(place, name);
        setAddress(place.formatted_address);
        if (inputRef.current) {
          inputRef.current.value = place.formatted_address;
        }
      }
    });
    setMapOpen(false); // Close the map dialog after selecting the location
  };

  useEffect(() => {
    if (materialRef && materialRef.current) {
      inputRef.current = materialRef.current;
    }
  }, [materialRef]);

  return (
    <Box sx={{ mb: 2 }}>
      {label && <InputLabel>{label}</InputLabel>}
      <TextField
        size={size}
        fullWidth
        color="secondary"
        variant="outlined"
        inputRef={materialRef}
        InputProps={{
          startAdornment: icon ? (
            <InputAdornment position="start">
              <IconButton onClick={handleIconClick}>
                <LocationOnIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <SelectLocationMap
        open={mapOpen}
        onClose={() => setMapOpen(false)}
        onSelect={handleMapSelect}
        defaultPosition={{ lat: 4.60971, lng: -74.08175 }} // Puedes ajustar esto a una posición predeterminada apropiada
      />
    </Box>
  );
};

export default Component;
