import Container from "../../../components/Theme/Dashboard";
import Table from "../../../components/Table";
import View from "./View";

import usePermissions from "../../../hooks/usePermissions";

const td = [
  {
    label: "Nombre",
    field: "name",
    className: "",
    align: "left",
  },
  {
    label: "Teléfono",
    field: "celular",
    className: "",
    align: "left",
  },
  {
    label: "Correo electrónico",
    field: "email",
    className: "",
    align: "left",
  },
  {
    label: "Acción",
    field: "event",
    className: "",
    align: "center",
    event: [
      {
        label: "Ver",
        action: "view",
        component: View,
      },
    ],
  },
];

const Home = (props) => {
  const permissions = usePermissions("clientes", "index", {
    title: 'Clientes',
    filters: { userStatus: true },
    component: Table,
    td: td,
    skipAdd: true,
    fullScreenModal: true,
    download: true,
  });
  return <Container>{permissions.render()}</Container>;
};
export default Home;
