import React from "react";
import Container from "../../components/Theme/AuthForm";
import { Button, Grid, Typography } from "@mui/material";

import { NavLink } from "react-router-dom";

const Login = () => {
  return (
    <Container>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} md={7} align="center">
          <Typography>Bienvenido a TRUCKER</Typography>
          <Typography sx={{ mb: 2 }}>
            Gracias por usar nuestros servicios. Accede a nuestra plataforma
            dando clic en el botón Volver.
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} align="center">
          <Grid>
            <Button
              component={NavLink}
              variant="contained"
              to="/auth/login"
              sx={{ mb: 1 }}
            >
              Volver
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Login;
