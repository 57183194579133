/*set components*/
import Home  from './Home';
import View  from './View';
/*set icons*/
import SellIcon from '@mui/icons-material/Sell';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <SellIcon />,
    title: "Cotizaciones",
    subtitle: "Gestiona cotizaciones rápidas y precisas"
  },
  {
    name: "Cotización",
    path: "/*",
    component: View,
    menu: true,
    private: false,
    icon: <SellIcon />,
    title: "Cotización",
    subtitle: "Desarrollos inmobiliarios"
  },
];
