import HomeIcon from '@mui/icons-material/Home';
import DetalleOrdenServicio from './DetalleOrdenServicio';
import Home from './Home';
import Layout from './Layout';
import Privacy from './Privacy';
import Terms from './Terms';
import TermsDriver from './TermsTransportador';

export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Terminos y Condiciones",
    path: "/terminos",
    layout: Layout,
    component: Terms,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Politica de Privacidad",
    path: "/privacidad",
    layout: Layout,
    component: Privacy,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Terminos Transportador",
    path: "/terminos-transportador",
    layout: Layout,
    component: TermsDriver,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Detalle",
    path: "/detalle-order-servicio/:token",
    component: DetalleOrdenServicio,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
];
