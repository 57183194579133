import React, { useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Input from "../../../components/Input";
import Select from "../../../components/Select/Select5";
import useFormData from "../../../hooks/useFormData";
import useAxios from "../../../hooks/useAxios";
import GoogleMap from "../../../components/GoogleMap";

const Edit = ({ data, setOpen, getInit }) => {
  const [reset, setReset] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [master, setMaster] = React.useState([]);
  const axios = useAxios();
  const { formData, onChange, setFormData } = useFormData();

  const onSubmit = (e) => {
    e.preventDefault();
    if (data.id) {
      axios.putData({ ...data, ...formData }).then((response) => {
        if (response && response.data && response.data.id) {
          getInit(true);
          setOpen(false);
        }
      });
    } else {
      axios.postData({ ...data, ...formData }).then((response) => {
        if (response && response.data && response.data.id) {
          getInit(true);
          setOpen(false);
        }
      });
    }
  };

  const onChangeMap = (e, inputName) => {
    if (e.geometry && e.geometry && e.geometry.location) {
      const geometry = e.geometry.location;
      const value = {
        address_components: e.address_components,
        lat: geometry.lat(),
        lng: geometry.lng(),
      };
      const end_value =
        e.address_components &&
        e.address_components[0] &&
        e.address_components[0].short_name
          ? e.address_components[0].short_name
          : false;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [inputName]: end_value,
      }));
    }
  };

  const onChangeMapOrigen = (e) => {
    if (e.geometry && e.geometry && e.geometry.location) {
      onChangeMap(e, "origin");
    }
  };

  const onChangeMapDestino = (e) => {
    if (e.geometry && e.geometry && e.geometry.location) {
      onChangeMap(e, "destination");
    }
  };

  useEffect(() => {
    const getInit = async () => {
      const response = await axios.getData(
        {},
        "api/v1/dashboard/users/byRole/Usuario"
      );
      if (response && response.data && response.data.clients) {
        setClients(response.data.clients);
      }
      if (response && response.data && response.data.master) {
        setMaster(response.data.master);
      }
    };
    getInit();
  }, []);

  const customOnchange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.name,
    }));
  };

  function cleanNumber(value = "") {
    console.log("🚀 ~ cleanNumber ~ value:", value)
    if (typeof value !== "string") {
      return "";
    }
    // return value.replace(/[^0-9]/g, "");
    return value
  }

  console.log("Datos de la configuración", data);

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="h6" sx={{ background: "#f2f2f2", py: 0.5, pl: 2 }}>
        Configuración Ruta
      </Typography>
      <Grid container justifyContent={"center"}>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              {data && data.enterprise && (
                <Typography component="b">
                  Empresa
                  <br />
                  {data.enterprise}
                </Typography>
              )}
              {data && !data.enterprise && (
                <Select
                  label="Empresa"
                  data={clients}
                  name="enterprise_id"
                  onChange={setFormData}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              {data && data.type_vehicle && (
                <Typography component="b">
                  Tipo de vehículo
                  <br />
                  {data.type_vehicle}
                </Typography>
              )}
              {data && !data.type_vehicle && (
                <Select
                  label="Tipo de Vehículo"
                  data={master}
                  name="type_vehicle"
                  onChange={setFormData}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              {data && data.origin && (
                <b>
                  <Typography component="b">
                    Origen
                    <br />
                    {data.origin}
                  </Typography>
                </b>
              )}
              {data && !data.origin && (
                <GoogleMap
                  reset={reset}
                  setReset={setReset}
                  onChange={onChangeMapOrigen}
                  size="small"
                  label={"Origen"}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              {data && data.destination && (
                <b>
                  <Typography component="b">
                    Destino
                    <br />
                    {data.destination}
                  </Typography>
                </b>
              )}
              {data && !data.destination && (
                <GoogleMap
                  reset={reset}
                  setReset={setReset}
                  onChange={onChangeMapDestino}
                  size="small"
                  label={"Destino"}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                defaultValue={cleanNumber(data.transportation_value)}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="transportation_value"
                type="text"
                label="Valor de transporte"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Input
                defaultValue={cleanNumber(data.loading_value)}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="loading_value"
                type="text"
                label="Valor de cargue"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                defaultValue={cleanNumber(data.unloading_value)}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="unloading_value"
                type="text"
                label="Valor de descargue"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                defaultValue={cleanNumber(data.insurance_percentage)}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="insurance_percentage"
                type="text"
                label="Porcentaje de aseguramiento"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                defaultValue={cleanNumber(
                  data.min_global_commission_percentage
                )}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="min_global_commission_percentage"
                type="text"
                label="Porcentaje mínimo de comisión global"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                defaultValue={cleanNumber(
                  data.max_global_commission_percentage
                )}
                required
                onChange={onChange}
                size="small"
                fullWidth
                name="max_global_commission_percentage"
                type="text"
                label="Porcentaje máximo de comisión global"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Edit;
