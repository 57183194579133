import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const anchor = "bottom";

export default function TemporaryDrawer({ open, setOpen, getInit }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, _open) => (event) => {
    getInit(true);
    setOpen(_open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: _open });
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={anchor}
        open={open ? true : false}
        onClose={toggleDrawer(anchor, false)}
      >
        <Box
          sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
          onKeyDown={toggleDrawer(anchor, false)}
          align="right"
        ></Box>
        <Box sx={{ height: "80vh", position: "relative" }}>
          <Grid
            sx={{ position: "fixed", right: 2, zIndex: 9999 }}
            onClick={toggleDrawer(anchor, false)}
          >
            <IconButton sx={{ background: "#fb8c00" }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          {open.component && (
            <open.component data={open} setOpen={setOpen} getInit={getInit} />
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
