import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Table from "../../components/Table/Table4";
import Table2 from "../../components/Table/Table5";
import Table9 from "../../components/Table/Table9";
import Table3 from "../../components/Table/Table6";
import Table8 from "../../components/Table/Table8";
import Documents from "../Uploads/Documents";
import Novedades from "../Comentarios/Novedades";
import useAxios from "../../hooks/useAxios";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import ButtonGroup from "../../components/Button/GroupStates";
import ServicesTransportadores from "./Components/ServicesTransportadores";
import Stars from "../../components/Stars";

const keys = "master";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 1, height: 400, overflow: "hidden", overflowY: "scroll" }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let getInit = false;

export default function BasicTabs({ data }) {
  const master = useAsyncStorage("master");
  const { getDataFromStorage } = useAsyncStorage(keys);
  const axios = useAxios();
  const [value, setValue] = React.useState(0);
  const [documents, setDocuments] = React.useState(data.docs);
  const [transportadores, setTransportadores] = React.useState({});
  const [status, setStatus] = React.useState([]);

  const [novedades, setNovedades] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  getInit = async () => {
    const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

    const result = await master.getDataFromStorage("master");
    if (result && result.transportadores) {
      setTransportadores(result.transportadores);
    }

    axios
      .getData(
        {},
        BACKEND + "/dashboard/documentos/doc_adiconales_usuario_" + data.id
      )
      .then((response) => {
        if (response && response.data) {
          setDocuments(response.data);
        }
      });

    axios
      .getData(
        {},
        BACKEND + "/dashboard/comments?id=novedades_servicio_" + data.id
      )
      .then((response) => {
        if (response && response.data) {
          setNovedades(response.data);
        }
      });
  };

  React.useEffect(() => {
    getInit();
    getDataFromStorage(keys).then((response) => {
      if (
        response &&
        response.status_services &&
        response.status_services.length > 0
      ) {
        setStatus(response.status_services);
      }
    });
  }, []);

  const onResetNovedad = () => {
    getInit();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ background: "#f2f2f2" }}
        >
          <Tab label="Detalle Servicio" {...a11yProps(0)} />
          <Tab label="Usuario" {...a11yProps(1)} />
          <Tab label="Transportador" {...a11yProps(2)} />
          <Tab label="Calificación" {...a11yProps(3)} />
          <Tab label="Novedades" {...a11yProps(4)} />
          <Tab label="Documentos" {...a11yProps(5)} />
          <Tab label="Pagos Transportador" {...a11yProps(6)} />
          <Tab label="Pagos Cliente" {...a11yProps(7)} />
          <Tab label="Estados" {...a11yProps(8)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            Modalidad: <b>{data.modalidad}</b>
          </Grid>
          <Grid item xs={12} md={3}>
            Tipo Carga: <b>{data.tipo_carga}</b>
          </Grid>
          <Grid item xs={12} md={3}>
            Tipo Servicio: <b>{data.tipo_servicio}</b>
          </Grid>
          <Grid item xs={12} md={3}>
            Tipo Vehículo: <b>{data.tipo_vehiculo}</b>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 2 }}>
          <Table data={data.direcciones} />
          {data && <Table8 data={data} novedades={novedades} />}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <label>Cliente ID</label>
            <Typography align="center">
              <b>{data.cliente_id}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <label>Cliente</label>
            <Typography>
              <b>{data.cliente}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <label>Teléfono</label>
            <Typography>
              <b>{data.cliente_celular}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <label>Email</label>
            <Typography>
              <b>{data.cliente_email}</b>
            </Typography>
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ServicesTransportadores
          getInit={getInit}
          data={data}
          trasportadores={transportadores}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        Calificación
        {data && (!data.calificacion || !data.calificacion.attachment) && (
          <Grid>Aún no ha sido calificado...</Grid>
        )}
        {data && data.calificacion && data.calificacion.attachment && (
          <Stars
            name="attachment"
            defaultValue={
              data && data.calificacion && data.calificacion.attachment
                ? data.calificacion.attachment
                : false
            }
          />
        )}
        {data && data.calificacion && (
          <Grid component={"b"}>"{data.calificacion.mensaje}"</Grid>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Novedades
              id={"novedades_servicio_" + data.id}
              base={"novedades_servicio_" + data.id}
              onResetNovedad={onResetNovedad}
              skip={true}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table9 data={novedades} />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_servicio_" + data.id}
              base={"doc_servicio_" + data.id}
              onUpload={setDocuments}
              showSelect={true} // Aquí indicamos que debe mostrar el select
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table2 data={documents["doc_servicio_" + data.id]} />
          </Grid>
        </Grid>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_servicio_" + data.id + "_pago_transportador"}
              base={"doc_servicio_" + data.id}
              onUpload={setDocuments}
              valor={true}
              fecha={true}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table3
              data={
                documents["doc_servicio_" + data.id + "_pago_transportador"]
              }
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_servicio_" + data.id + "_pago_clientes"}
              base={"doc_servicio_" + data.id}
              onUpload={setDocuments}
              valor={true}
              fecha={true}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table3
              data={documents["doc_servicio_" + data.id + "_pago_clientes"]}
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8}>
        {status && status.length > 0 && (
          <ButtonGroup
            id={data.id}
            data={status}
            defaultvalue={data.status_id}
            name="status_id"
          />
        )}
      </CustomTabPanel>
    </Box>
  );
}
