import { Grid, Typography } from "@mui/material";
import logo from "../../assets/png/logo-transparent-home.png";
import BlockBox2 from "../../components/BlockBox/Block2";
import BlockBox3 from "../../components/BlockBox/Block3";
import BlockBox6 from "../../components/BlockBox/Block6";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import BillIcon from "../../components/Icons/BillIcon";
import ClientsIcon from "../../components/Icons/ClientsIcon";
import CommunityIcon from "../../components/Icons/CommunityIcon";
import MedalIcon from "../../components/Icons/MedalIcon";
import MoneyIcon from "../../components/Icons/MoneyIcon";
import NetworkIcon from "../../components/Icons/NetworkIcon";
import PackagesIcon from "../../components/Icons/PackagesIcon";
import PuzzleIcon from "../../components/Icons/PuzzleIcon";
import TechnologyIcon from "../../components/Icons/TechnologyIcon";
import Container from "../../components/Theme/Home";

const Home = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ my: 4 }}
      >
        <img src={logo} alt="logo" />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={11} md={4} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <NetworkIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Red de alta confianza
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Benefíciese de una red de camioneros profesionales, todos los
              cuales han pasado rigurosos controles de antecedentes de
              seguridad. Este nivel de confianza y fiabilidad proporciona
              tranquilidad, sabiendo que sus mercancías se manejan de forma
              segura y protegida.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} md={4} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <PuzzleIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Soluciones a medida
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Reconocemos la diversidad de las necesidades de envío, ofreciendo
              soluciones flexibles y personalizables para LTL, FTL y carga
              especializada. Nuestra plataforma está diseñada para adaptarse a
              sus necesidades específicas, garantizando un ajuste óptimo para
              cada envío.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} md={4} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <ClientsIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Servicio al cliente inigualable:
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Conocidos por nuestro excepcional servicio al cliente, en TRUCKER
              nos comprometemos a garantizar una experiencia de envío sin
              resistencias. Nuestro equipo está listo para ayudar con cualquier
              necesidad o pregunta, de inicio a fin.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} md={4} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <MoneyIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Garantizaos los mejores precios del mercado:
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Nuestra sólida estructura financiera brinda soluciones
              contundentes a la operación, lo cual nos permite ofrecer los
              mejores precios del mercado.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} md={4} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <TechnologyIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Plataforma tecnológica avanzada
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Utilice nuestra plataforma fácil de usar, con seguimiento GPS en
              tiempo real para una supervisión precisa de sus envíos. Esta
              avanzada tecnología garantiza la transparencia y le da el control
              para gestionar su logística con confianza.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ my: 2 }}
        >
          <section id="trucker" style={{ height: 80, alignContent: 'center' }}>
            <Typography component="h2" variant="h4" sx={{ color: "#FB8C00", fontWeight: 'bold', textTransform: "uppercase" }}>
              Conductores
            </Typography>
          </section>
        </Grid>
        <Grid>
          <BlockBox2 />
        </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        align="center"
        sx={{ my: 4 }}
      >
        <Typography component="h2" variant="h4" sx={{ color: "#FB8C00", fontWeight: 'bold', textTransform: 'uppercase' }}>
          ¿Por qué ser un trucker?
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={11} md={3} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <BillIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Flexibilidad en los pagos
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Respetamos el duro trabajo que pones en cada transporte. Para
              honrar esto, TRUCKER ofrece flexibilidad en los pagos, incluyendo
              pagos y anticipos en máximo 48 horas, asegurando que usted tenga
              estabilidad financiera y tranquilidad.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} md={3} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <MedalIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Reconocimiento y comunidad
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Únete a una comunidad en la que se te valora por encima de todo.
              En TRUCKER, los transportistas son nuestros socios, esenciales
              para satisfacer las necesidades de nuestros clientes. Reconocemos
              su duro trabajo y su contribución a nuestro éxito colectivo,
              fomentando un sentido de pertenencia y aprecio.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} md={3} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <CommunityIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Ecosistema de ventajas
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Hemos adaptado un ecosistema diseñado para que su trabajo sea más
              fácil y gratificante. Desde descuentos exclusivos en combustibles,
              servitecas y apoyo en el camino, estamos aquí para ayudarle en
              todo momento.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} md={3} align="center">
          <Grid sx={{ mt: 5, mb: 2 }}>
            <PackagesIcon width={100} />
          </Grid>
          <Grid>
            <Typography component="h1" variant="h4">
              Oportunidades de carga continua
            </Typography>
            <Typography maxWidth="80%" sx={{ fontSize: 15, lineHeight: 2 }}>
              Con TRUCKER, diga adiós a los tiempos muertos y viajes en vacío.
              Nuestra optimización con la amplia red de carga fundamentada en
              tecnología facilita garantizar un trabajo continuo,
              proporcionándole un flujo constante de oportunidades para que su
              labor siga avanzando.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ my: 4 }}>
        <BlockBox6 />
      </Grid>
      <Grid sx={{ mt: 4 }}>
        <BlockBox3 />
      </Grid>
      <Footer />
    </Container>
  );
};
export default Home;
