import { Grid, Typography } from "@mui/material";

// import image from "../../assets/png/imagen-header.png";
import video from '../../assets/video/trucker-video.mp4';
import AutoPlayVideo from "../AutoPlayVideo";

const Header = () => {
  return (
    <Grid component={"section"}>
      <Grid container justifyContent={"center"} sx={{ pt: 5 }}>
        <Grid item xs={11} md={8}>
          <Typography
            component={"p"}
            variant="subtitle"
            sx={{ fontSize: 20, lineHeight: 1.5, fontWeight: 'bold', mb: 3 }}
          >
            Don Luis Pineda es uno de nuestros Truckers más antiguos y con más de 50 años de
						experiencia, junto a él hemos construido soluciones contundentes para todas las partes
						involucradas en el transporte de carga terrestre. ¡Has parte de la revolución junto con Don Luis!
          </Typography>
        </Grid>
        <Grid item xs={11} md={8}>
        	<AutoPlayVideo video={video} />
				</Grid>
      </Grid>
    </Grid>
  );
};
export default Header;
