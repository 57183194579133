import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import image from "../../assets/png/conductor.png";
import WhatsappIcon from "../Icons/WhatsappIcon";
import "./index.css";

const Header = () => {
  const navigate = useNavigate()

  const handleWhatsappClick = () => {
    navigate("/");
    console.log('Redirect to whatsapp url');
  };

  return (
    <Grid component={"section"} className="section-1">
      <Grid container justifyContent={"center"} sx={{ pt: 5 }} position={'relative'}>
        <Grid item xs={11} md={4}>
          <Typography
            component={"p"}
            variant="subtitle"
            sx={{ fontSize: 20, lineHeight: 2 }}
          >
            Conviértete en TRUCKER: Donde tu compromiso se encuentra con nuestro
            apoyo
          </Typography>
          <Typography component={"p"} sx={{ fontSize: 15, lineHeight: 2 }}>
            En TRUCKER, entendemos que la columna vertebral de nuestro éxito
            radica en nuestra red de transportistas dedicados. Por eso nos
            comprometemos a proporcionarle no solo cargas, sino una comunidad
            que valora su contribución reconozca su trabajo duro y apoye su
            crecimiento en cada kilómetro del camino.
          </Typography>
        </Grid>
        <Grid item xs={11} md={4} mt={{ xs: 5, sm: 5, md: 0, xl: 0 }} position={'relative'}>
          <IconButton aria-label="delete" size="large" sx={{ position: 'absolute', top: { xs: -30, sm: 50,  md: 30, lg: 50, xl: 50 }, right: { xs: -25, md: -130, lg: -120, xl: -90 }, ":hover": { transform: 'scale(1.05)' } }} onClick={handleWhatsappClick}>
            <WhatsappIcon width={90} /> 
          </IconButton>
          <img src={image} className="img-fluid" alt="trucker-1" />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Header;
