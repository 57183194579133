import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordStrengthMeter = ({
  name,
  label,
  id,
  defaultValue,
  onChange,
  placeholder,
  fullWidth,
  required,
  sx,
}) => {
  const [password, setPassword] = useState(defaultValue || "");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const calculatePasswordStrength = () => {
    // Lógica de fuerza de la contraseña (simple por longitud)
    const lengthStrength = password.length / 6; // Puedes ajustar este valor según tus criterios

    // Asegurarse de que la fuerza esté entre 0 y 100
    const strength = Math.min(lengthStrength * 100, 100);

    return strength;
  };

  const passwordStrength = calculatePasswordStrength();

  return (
    <FormControl sx={sx || { mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        required={required || false}
        fullWidth={fullWidth}
        inputlabelprops={{
          shrink: true,
        }}
        name={name}
        label={label}
        id={id}
        value={password}
        variant="outlined"
        onChange={(e) => {
          setPassword(e.target.value);
          onChange(e);
        }}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <LinearProgress
        variant="determinate"
        value={passwordStrength}
        sx={{ mt: 1, height: 5 }}
        color={passwordStrength === 100 ? "success" : "primary"}
      />
    </FormControl>
  );
};

PasswordStrengthMeter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.object,
};

export default PasswordStrengthMeter;
