import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {/* <StyledTableCell align="center">Check ID</StyledTableCell> */}
            <StyledTableCell align="center">Tipo</StyledTableCell>
            <StyledTableCell align="center">Resultado</StyledTableCell>
            <StyledTableCell align="center">Severidad</StyledTableCell>
            <StyledTableCell align="center">Puntuación</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: 100, overflowY: "scroll" }}>
          {(!data || !Array.isArray(data)) && (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={4}>
                No Hay registros disponibles
              </StyledTableCell>
            </StyledTableRow>
          )}
          {data &&
            Array.isArray(data) &&
            data.map((row, key) => (
              <StyledTableRow key={key}>
                {/* <StyledTableCell align="center">{row.check_id}</StyledTableCell> */}
                <StyledTableCell align="center">{row.type}</StyledTableCell>
                <StyledTableCell align="center">{row.result}</StyledTableCell>
                <StyledTableCell align="center">{row.severity}</StyledTableCell>
                <StyledTableCell align="center">{row.score}</StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
