import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const BillingDataModal = ({ open, handleClose, data }) => {
  const getDataOrPlaceholder = (field) => {
    return field ? field : "Info no registrada";
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Datos de Facturación
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Razón Social: {getDataOrPlaceholder(data.razon_social)}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          NIT: {getDataOrPlaceholder(data.facturacion_nit)}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Nombre: {getDataOrPlaceholder(data.facturacion_name)}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Apellido: {getDataOrPlaceholder(data.facturacion_surname)}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Cédula: {getDataOrPlaceholder(data.facturacion_cedula)}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Dirección: {getDataOrPlaceholder(data.facturacion_direccion)}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Teléfono: {getDataOrPlaceholder(data.facturacion_telefono)}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Email: {getDataOrPlaceholder(data.facturacion_email)}
        </Typography>
        <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
          Cerrar
        </Button>
      </Box>
    </Modal>
  );
};

export default BillingDataModal;
