import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import { Tab, Box, Grid, Typography } from "@mui/material";
import useAxios from "../../hooks/useAxios";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import Novedades from "../Comentarios";
import Documents from "../Uploads/Documents";
import Table2 from "../../components/Table/Table5";
import Table3 from "../../components/Table/Table6";
import Table9 from "../../components/Table/Table9";
import UserHomeServiciosDetalleServicio from "./Components/UserHomeServiciosDetalleServicio";
import UserHomeServiciosDetalleTransportador from "./Components/UserHomeServiciosDetalleTransportador";
import UserHomeServiciosDetalleCalificacion from "./Components/UserHomeServiciosDetalleCalificacion";

const keys = "master";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 1, height: 400, overflow: "hidden", overflowY: "scroll" }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let getInit = false;

export default function BasicTabs() {
  const { getDataFromStorage } = useAsyncStorage(keys);
  const axios = useAxios();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState({});
  const [documents, setDocuments] = React.useState(data.docs);
  const [novedades, setNovedades] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

  const getInit = () => {
    axios.getData({}).then((response) => {
      if (response && response.data) {
        const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

        axios
          .getData(
            {},
            BACKEND +
              "/dashboard/comments?id=novedades_servicio_" +
              response.data.id
          )
          .then((response) => {
            if (response && response.data) {
              setNovedades(response.data);
            }
          });
        setData(response.data);

        axios
          .getData(
            {},
            BACKEND +
              "/dashboard/documentos/doc_adiconales_usuario_" +
              response.data.id +
              "/all"
          )
          .then((response) => {
            if (response && response.data) {
              setDocuments(response.data);
            }
          });
      }
    });
  };

  React.useEffect(() => {
    getInit();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ justifyContent: "center" }}
        >
          <Tab label="Servicio" {...a11yProps(0)} />
          <Tab label="Transportador" {...a11yProps(1)} />
          <Tab label="Calificación" {...a11yProps(2)} />
          <Tab label="Novedades" {...a11yProps(3)} />
          <Tab label="Documentos" {...a11yProps(4)} />
          <Tab label="Pago" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserHomeServiciosDetalleServicio data={data} novedades={novedades} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserHomeServiciosDetalleTransportador getInit={getInit} data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <UserHomeServiciosDetalleCalificacion getInit={getInit} data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {console.log(novedades)}
        <Table9 data={novedades} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_servicio_" + data.id}
              base={"doc_servicio_" + data.id}
              onUpload={setDocuments}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {documents && documents["doc_servicio_" + data.id] && (
              <Table2 data={documents["doc_servicio_" + data.id]} />
            )}
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_servicio_" + data.id + "_pago_clientes"}
              base={"doc_servicio_" + data.id}
              onUpload={setDocuments}
              valor={true}
              fecha={true}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {documents &&
              documents["doc_servicio_" + data.id + "_pago_clientes"] && (
                <Table3
                  data={documents["doc_servicio_" + data.id + "_pago_clientes"]}
                />
              )}
          </Grid>
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
