import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import image from "../../assets/png/trasporter-truck.png";
import Whatsapp from "../Icons/WhatsappIcon";
import "./index.css";

const Header = () => {
  const navigate = useNavigate();

  const handleWhatsappClick = () => {
    navigate("/");
    console.log('Redirect to whatsapp url');
  };

  return (
    <Grid component={"section"} className="section-1">
      <Grid container justifyContent={"center"} sx={{ pt: 5 }}>
        <Grid item xs={11} md={4}>
          <img src={image} className="img-fluid" alt="trucker-2" />
        </Grid>
        <Grid item xs={11} md={4}>
          <Grid align="center">
            <Typography component={"p"} sx={{ fontSize: 20, lineHeight: 2 }}>
              Haga clic aquí para unirse a nuestra red de TRUCKER’s. Descubre el
              significado de conducir con apoyo, reconocimiento y continuas
              oportunidades. Conviértete en TRUCKER hoy mismo y juntos el camino
              hacia el éxito."
            </Typography>
          </Grid>
          <Grid container justifyContent={"center"} sx={{ mt: 3, my: 5 }}>
            <IconButton aria-label="delete" size="large" sx={{ ":hover": { transform: 'scale(1.05)' } }} onClick={handleWhatsappClick}>
              <Whatsapp width={90} /> 
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
