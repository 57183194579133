import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import  Avatar  from  '../Avatar';
import  logo  from  '../../assets/png/logo-transparent-2.png';
import Item from './Items'

export default function ButtonAppBar(props) {
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color='blue'>
        <Toolbar variant="dense">
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ 
                  mr: 2                  
                }}
          >
            <Item {...props}/> 
          </IconButton>
          <Box align="right" sx={{width:"100%"}}>
            <Avatar sx={{width:{md:"90%",xs:"20%"},height:"auto"}} 
                                title={process.env.REACT_APP_NAME} 
                                alt={process.env.REACT_APP_NAME} 
                                src={logo}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}