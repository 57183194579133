/*set components*/
import Web  from './app/Web';
import Auth from './app/Auth';
import Dashboard from './app/Dashboard';

/*set icons*/
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export const routes_modules = [
  {
    name: "Home",
    path: "/*",
    component: Web,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Auth",
    path: "/auth/*",
    component: Auth,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "Dashboard",
    path: "/dashboard/*",
    component: Dashboard,
    menu: true,
    private: true,
    icon: <LibraryBooksIcon />,
  },
];
