import Grid     from    '@mui/material/Grid';
import Button   from    '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Input    from    '../../Input';
import useFormData  from '../../../hooks/useFormData';
import useAxios  from '../../../hooks/useAxios';

import React from 'react';

const Basic=({data})=>{
    const { formData, onChange, setFormData }            =   useFormData();
    const axios                             =   useAxios();
    const [error, setError] = React.useState("");
    const storedData = localStorage.getItem('master');


  const getVehicleLabel =  (id) => {
      try {
          const master = JSON.parse(storedData);

          function getVehicles(master) {
              return master.vehiculo.map(vehiculo => ({
                  label: vehiculo.label,
                  value: vehiculo.value,
                  images: vehiculo.images
              }));
          }

          const vehicles = getVehicles(master);

          const vehicle = vehicles.find((item) => item.value === Number(id));
         

          return vehicle ? vehicle.label : '';
      } catch (err) {
          console.error('Error al obtener datos de almacenamiento', err);
          setError(err);
          console.log('Error al obtener datos de almacenamiento', error);
          return '';
      }
  };
    const onSubmit=(e)=>{
        e.preventDefault()

        if (data&&data.id===0) {
            axios.postData(formData).then((response)=>{
                if (response&&response.data&&response.data.id) {
                    //getInit(true)                
                }
            })   
        }else{
            axios.putData(formData).then((response)=>{
                if (response&&response.data&&response.data.id) {
                    //getInit(true)                
                }
            })
        }        
    }

    React.useEffect(()=>{
        if (data) {
            setFormData(data)
        }
    },[data,setFormData])

    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Input
              defaultValue={data.vehiculo_placa}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_placa"
              type="text"
              label="Placas"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Input
              defaultValue={data.vehiculo_marca}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_marca"
              type="text"
              label="Marca"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Input
              defaultValue={data.vehiculo_modelo}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_modelo"
              type="text"
              label="Modelo"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              defaultValue={data.vehiculo_cilindraje}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_cilindraje"
              type="text"
              label="Cilindraje"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              defaultValue={getVehicleLabel(data.vehiculo_tipo_vehiculo)}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_tipo_vehiculo"
              type="text"
              label="Tipo vehículo"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              defaultValue={data.vehiculo_seguro}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_seguro"
              type="text"
              label="Seguro"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              defaultValue={data.vehiculo_seguro_vencimiento}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_seguro_vencimiento"
              type="date"
              label="Seguro vencimiento"
              InputLabelProps={{
                shrink: true, // Esto mantiene la etiqueta sobre el campo
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Input
              defaultValue={data.vehiculo_tecnomecanica}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_tecnomecanica"
              type="text"
              label="Tecnomecánica"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              defaultValue={data.vehiculo_tecnomecanica_vencimiento}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_tecnomecanica_vencimiento"
              type="date"
              label="Tecnomecánica vencimiento"
              InputLabelProps={{
                shrink: true, // Esto mantiene la etiqueta sobre el campo
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Input
              defaultValue={data.vehiculo_estudio_seguridad}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_estudio_seguridad"
              type="text"
              label="Estudio seguridad"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              defaultValue={data.vehiculo_estudio_seguridad_aprobacion}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_estudio_seguridad_aprobacion"
              type="date"
              label="Estudios vencimiento"
              InputLabelProps={{
                shrink: true, // Esto mantiene la etiqueta sobre el campo
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              defaultValue={data.vehiculo_estudio_seguridad_aprobacion_estatus}
              sx={{ mb: 0 }}
              onChange={onChange}
              size="small"
              fullWidth
              name="vehiculo_estudio_seguridad_aprobacion_estatus"
              type="text"
              label="Aprobación estatus"
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="right">
          <Grid item xs={12} md={6} align="right" sx={{ mt: 2 }}>
            <Button variant="contained" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    );
}
export default Basic