import { Typography, Grid, Button } from "@mui/material";
import Container from "../../../components/Theme/DashboardUsuario2";
import useAsyncStorage from "../../../hooks/useAsyncStorage";
import { NavLink } from "react-router-dom";
import React from "react";
import Carousel from "../../../components/Carousel";
import Resumen from "../../../components/Resumen";
import Back from "../../../components/Button/Back";

const component = "orden-servicios";
const step = "step3";
const title = "Tipo de Vehículo";

const Home = () => {
  const componentStorage = useAsyncStorage(component);
  const master = useAsyncStorage("master");
  const [inputs, setInputs] = React.useState({});
  const [data1, setData1] = React.useState([]);

  const handleEvent = async (name, val) => {
    try {
      setInputs({ ...inputs, [name]: val.value });

      let _data = {};
      if (componentStorage.data === null) {
        _data[name] = val.value;
      } else {
        _data = { ...componentStorage.data };
        _data[name] = val.value;
      }

      await componentStorage.setDataToStorage(component, _data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (componentStorage.data) {
      setInputs(componentStorage.data);
    }

    if (master.data && master.data.vehiculo) {
      setData1(master.data.vehiculo);
    }
  }, [master.data]);

  //console.log(inputs)

  return (
    <Container>
      <Grid>
        <Typography
          variant="h3"
          className="text-secondary title-main"
          align="center"
        >
          {title}
        </Typography>
        <Grid>
          <Grid container justifyContent={"center"} spacing={2}>
            {data1 && data1.length > 0 && (
              <Grid item xs={11} md={5}>
                <Carousel
                  name="tipo_vehiculo_id"
                  inputs={inputs}
                  data={data1}
                  handleEvent={handleEvent}
                />

                <Grid sx={{ mt: 2 }}>
                  <Back />
                  <Button
                    variant={"contained"}
                    component={NavLink}
                    to={"../" + step}
                  >
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item xs={11} md={5}>
              <Resumen
                inputs={inputs}
                label="Resumen del servicio"
                data={master.data}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Home;
