import { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Paper,
  Box,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import useAxios from "../../hooks/useAxios";
import image1 from "../../assets/png/avatar.png";
import image2 from "../../assets/png/avatar2.png";
import { NavLink } from "react-router-dom";
import "./UserHome.css";
import {formatCurrency} from '../../helpers/functions';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 1, height: 400, overflow: "hidden", overflowY: "scroll" }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export default function ColorTabs() {
  const { data } = useAsyncStorage("master");
  const [value, setValue] = useState(0);
  const axios = useAxios();
  const [data2, setData2] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios.getData().then((response) => {
      if (response && response.data) {
        const __data = {
          Solicitado: response.data.Solicitado,
          Enviado: response.data.Enviado,
          Pagado: response.data.Pagado,
          Anulado: response.data.Anulado,
          "Sin Ruta disponible": response.data["Sin Ruta Disponible"],
        };
        setData2(__data);
      }
    });
  }, []);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ px: 0 }}>
      <Box
        sx={{
          overflowX: "auto",
          position: "sticky",
          bottom: "0",
          zIndex: "1000",
          px: 0,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Centrar las pestañas
            overflowX: "scroll", // Permitir el desplazamiento horizontal
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="primary"
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              minHeight: "10",
              borderBottom: "2px solid #052B4F",
              flexShrink: 0, // Evita que las pestañas se reduzcan en tamaño
              // '& .MuiTab-root': {
              //   minWidth: 120, // Establece un ancho mínimo para las pestañas
              // },
              borderLeft: "10px solid transparent",
            }}
          >
            {Object.entries(data2) &&
              Object.entries(data2).length > 0 &&
              Object.entries(data2).map((row, key) => {
                return <Tab key={key} label={row[0]} {...a11yProps(key)} />;
              })}
          </Tabs>
        </Box>
      </Box>
      {Object.entries(data2) &&
        Object.entries(data2).length > 0 &&
        Object.entries(data2).map((row, key) => {
          return (
            <CustomTabPanel key={key} value={value} index={key}>
              <Grid container spacing={2} justifyContent={"center"}>
                {row[1] &&
                  row[1].length > 0 &&
                  row[1].map((row2, key2) => {
                    console.log('row2', row2)
                    return (
                      <Grid key={key2} item xs={11} md={6}>
                        <NavLink to={"/dashboard/servicios/detail/" + row2.id}>
                          <Paper sx={{ p: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={3} align="center">
                                <img
                                  className="img-fluid"
                                  src={key2 % 2 === 0 ? image1 : image2}
                                  alt="Programandoweb"
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <Grid>
                                  <Typography component="h3" variant="h6">
                                    {row2.razon_social || row2.cliente}
                                  </Typography>
                                </Grid>
                                
                                
                                <Grid>
                                <Typography component={"div"} sx={{ fontSize: 11 }}>
                                    {row2.id}
                                  </Typography>
                                <Typography component={"div"} sx={{ fontSize: 11 }}>
                                    {row2.created_at.split("T")[0]}
                                  </Typography>
                                  <Typography component={"div"} sx={{ fontSize: 11 }}>
                                    origen: {row2.origin}
                                  </Typography>
                                  <Typography component={"div"} sx={{ fontSize: 11 }}>
                                    destino: {row2.destination}
                                  </Typography>
                                  <Typography component={"div"} sx={{ fontSize: 11 }}>
                                    {formatCurrency(row2.transportation_value)}
                                  </Typography>
                                </Grid>
                              </Grid>
                              {/* <Grid>
                                  {row2 &&
                                    row2.direcciones2 &&
                                    row2.direcciones2.length > 0 &&
                                    row2.direcciones2.map((row3, key3) => {
                                      return (
                                        <Grid key={key3}>
                                          <Typography
                                            component={"div"}
                                            sx={{ fontSize: 11 }}
                                          >
                                            {row3.descripcion}
                                          </Typography>
                                        </Grid>
                                      );
                                    })}
                                </Grid> */}
                            </Grid>
                          </Paper>
                        </NavLink>
                      </Grid>
                    );
                  })}
              </Grid>
            </CustomTabPanel>
          );
        })}
    </Box>
  );
}
