import React from "react";
import { Typography, Grid } from "@mui/material";
import Container from "../../components/Theme/DashboardUsuario";
import vehiculo1 from "../../assets/png/vehiculo1.png";
import vehiculo2 from "../../assets/png/vehiculo2.png";
import vehiculo3 from "../../assets/png/vehiculo3.png";
import BlockBox4 from "../../components/BlockBox/Block4";
import BlockBox5 from "../../components/BlockBox/Block5";
import ImageActionButton from "../../components/Button/ImageActionButton";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

const component = "orden-servicios";
const step = "step1";
let getInit;

const Web = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const componentStorage = useAsyncStorage(component);
  const master = useAsyncStorage("master");

  getInit = async () => {
    axios.getData().then((response) => {
      if (response && response.data) {
        master.setData(response.data);
      }
    });
  };

  const handleEvent = async (name, type) => {
    try {
      let _data = {};
      if (componentStorage.data === null) {
        _data[name] = type;
      } else {
        _data = { ...componentStorage.data };
        _data[name] = type;
      }

      await componentStorage.setDataToStorage(component, _data);

      return navigate(component + "/" + step);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getInit();
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Grid container sx={{ width: "100%", margin: 0 }}>
        <Grid item xs={12}>
          <Typography variant="h3" className="text-secondary" align="center">
            Tipo de carga
          </Typography>
        </Grid>

        <Grid container justifyContent="center" sx={{ width: "100%" }}>
          <Grid item xs={12} md={4}>
            <Grid sx={{ padding: { md: 4, xs: 1 } }}>
              <ImageActionButton
                event={handleEvent}
                name="tipo_carga_id"
                type="Refrigerada"
                src={vehiculo1}
                className="image-button"
                imgClassName="img-fluid"
                alt="Programandoweb"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid sx={{ padding: { md: 4, xs: 1 } }}>
              <ImageActionButton
                event={handleEvent}
                name="tipo_carga_id"
                type="Seca"
                src={vehiculo2}
                className="image-button"
                imgClassName="img-fluid"
                alt="Programandoweb"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid sx={{ padding: { md: 4, xs: 1 } }}>
              <ImageActionButton
                event={handleEvent}
                name="tipo_carga_id"
                type="Granel"
                src={vehiculo3}
                className="image-button"
                imgClassName="img-fluid"
                alt="Programandoweb"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ my: 4 }}>
            <BlockBox4 />
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ my: 4, width: "100%" }}
          >
            <Typography component="h1" variant="h3" sx={{ color: "#ED5C01" }}>
              Nuestra promesa
            </Typography>
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ my: 4, width: "100%" }}
          >
            <Typography sx={{ maxWidth: "80%", textAlign: "center" }}>
              Asociarse con TRUCKER significa elegir un líder en logística de
              carga que prioriza sus necesidades. Con nuestro enfoque innovador,
              que combina eficiencia, seguridad y tecnología, ofrecemos una
              solución integral de transporte que supera las expectativas.
              Permítanos gestionar la logística, para que usted pueda
              concentrarse en hacer crecer su negocio.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ my: 4 }}>
            <BlockBox5 />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Web;
