import { Grid, Typography, Box } from '@mui/material';
import * as React from 'react';

export default function ControlledOpenSelect({ items, label, name, value, onChange }) {

  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Grid sx={{ position: "relative" }} className='select2023'>
      <Box>
        <Typography className='label'>
          {label}
        </Typography>
      </Box>
      <select
        className='select2023'
        value={value || ''}
        name={name}
        onChange={handleOnChange}
        required
      >
        <option value="">
          Seleccione
        </option>
        {items &&
          items.map((row, key) => (
            <option value={row.id || row.value || key} key={key}>
              {row.label}
            </option>
          ))
        }
      </select>
    </Grid>
  );
}
