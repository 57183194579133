import * as React from 'react';
import StateContext from '../../helpers/ContextState';
import IndexAdmin from './IndexAdmin';
import IndexUsuario from './IndexUsuario'
import Footer from '../../components/Footer';

const Auth = () => {
  const context                       =   React.useContext(StateContext);

  if (context.user.roles&&context.user.roles.find(search=>search==='Super Admin')) {
    return <IndexAdmin/> 
  }else if (context.user.roles&&context.user.roles.find(search=>search==='Usuario')) {
    return (
      <>
        <IndexUsuario/>
        <Footer/>
      </>
    )
  }else{
    return <></>
  }  
  
};

export default Auth;
