import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Documents from "../Uploads/Documents";
import ClientesBasic from "./Components/ClientesBasic";
import Input from "../../components/Input";
import useFormData from "../../hooks/useFormData";
import DatosFacturacion from "./Components/ClientesBasic";
import { Grid } from "@mui/material";
import Table2 from "../../components/Table/Table5";
import SelectStatus from "../../components/Button/Select";
import useAxios from "../../hooks/useAxios";
import DatosFacturacionPersonas from "../../components/DatosFacturacion";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 1, height: 400, overflow: "hidden", overflowY: "scroll" }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let getInit = false;

export default function BasicTabs({ data }) {
  const { formData, onChange, setFormData } = useFormData();
  const axios = useAxios();
  const [documents, setDocuments] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [inputs, setInputs] = React.useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  getInit = () => {
    const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

    axios
      .getData(
        {},
        BACKEND + "/dashboard/documentos/doc_adiconales_usuario_" + data.id
      )
      .then((response) => {
        if (response && response.data) {
          setDocuments(response.data);
        }
      });
  };

  React.useEffect(() => {
    getInit();
  }, []);

  React.useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data, setFormData]);

  const handleChangeDocuments = (data) => {
    getInit();
    if (
      Object.entries(data) &&
      Object.entries(data)[0] &&
      Object.entries(data)[0][1]
    ) {
      setDocuments(Object.entries(data)[0][1]);
    }
  };

  const onSubmitStore = async (e, data) => {
    e.preventDefault();

    await axios
      .postData(data, "api/v1/dashboard/billing")
      .then((response) => {});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    axios.putData(formData).then((response) => {
      if (response && response.data && response.data.id) {
        //getInit(true)
      }
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ background: "#f2f2f2" }}
        >
          <Tab label="Información básica" {...a11yProps(0)} />
          <Tab label="Datos de facturación" {...a11yProps(1)} />
          <Tab label="Documentos adicionales" {...a11yProps(2)} />
          <Tab label="Estatus del usuario" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ClientesBasic data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <form onSubmit={onSubmit}>
          {console.log("Datos de facturación", data)}
          <Grid container spacing={2}>
            {data && data.facturacion_razon_social && (
              <Grid item xs={12} md={6}>
                <Input
                  defaultValue={data.facturacion_razon_social}
                  required
                  onChange={onChange}
                  size="small"
                  fullWidth
                  name="facturacion_razon_social"
                  type="text"
                  label="Razón social"
                />
              </Grid>
            )}
            {data && data.facturacion_nit && (
              <Grid item xs={12} md={6}>
                <Input
                  defaultValue={data.facturacion_nit}
                  required
                  onChange={onChange}
                  size="small"
                  fullWidth
                  name="facturacion_nit"
                  type="text"
                  label="NIT"
                />
              </Grid>
            )}
            {data && data.facturacion_name && (
              <Grid item xs={12} md={3}>
                <Input
                  defaultValue={data.facturacion_name}
                  required
                  onChange={onChange}
                  size="small"
                  fullWidth
                  name="facturacion_name"
                  type="text"
                  label="Nombre"
                />
              </Grid>
            )}
            {data && data.facturacion_surname && (
              <Grid item xs={12} md={3}>
                <Input
                  defaultValue={data.facturacion_surname}
                  required
                  onChange={onChange}
                  size="small"
                  fullWidth
                  name="facturacion_surname"
                  type="text"
                  label="Apellido"
                />
              </Grid>
            )}
            {data && data.facturacion_cedula && (
              <Grid item xs={12} md={3}>
                <Input
                  defaultValue={data.facturacion_cedula}
                  required
                  onChange={onChange}
                  size="small"
                  fullWidth
                  name="facturacion_cedula"
                  type="text"
                  label="Cédula"
                />
              </Grid>
            )}
            {data && data.facturacion_telefono && (
              <Grid item xs={12} md={3}>
                <Input
                  defaultValue={data.facturacion_telefono}
                  required
                  onChange={onChange}
                  size="small"
                  fullWidth
                  name="facturacion_telefono"
                  type="text"
                  label="Teléfono"
                />
              </Grid>
            )}
            {data && data.facturacion_direccion && (
              <Grid item xs={12} md={12}>
                <Input
                  defaultValue={data.facturacion_direccion}
                  required
                  onChange={onChange}
                  size="small"
                  fullWidth
                  name="facturacion_direccion"
                  type="text"
                  label="Dirección"
                />
              </Grid>
            )}
            <Grid container justifyContent="right">
              <Grid item xs={12} md={6} align="right" sx={{ mt: 2 }}>
                <Button variant="contained" type="submit">
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents
              id={"doc_adiconales_usuario_" + data.id}
              base={"doc_adiconales_usuario_" + data.id}
              onUpload={handleChangeDocuments}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table2 data={documents["doc_adiconales_usuario_" + data.id]} />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <SelectStatus id={data.id} defaultValue={data.status} />
      </CustomTabPanel>
    </Box>
  );
}
