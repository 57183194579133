import Container    from '../../../components/Theme/Dashboard';
import Table    from '../../../components/Table';
import Create from './Create';
import usePermissions from '../../../hooks/usePermissions';


const td  = [
  {
    label:"Consecutivo",
    field:"cotizacion_id",
    className:"",
    align:"left",
  },
  {
    label:"Cliente",
    field:"cliente",
    className:"",
    align:"left",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        byIndex:{
                  prefixed:"./cotizacion",
                  key:"id"
                },
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                create:Create
                                                              });
  return  <Container>
            {permissions.render()}
          </Container>
}
export default Home
