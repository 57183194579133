import { createTheme } from "@mui/material/styles";
import { grey, orange } from "@mui/material/colors";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: orange[600],
    },
    secondary: {
      main: grey[500],
    },
    error: {
      main: orange[500],
    },

    black: {
      main: "black",
    },
    blue: {
      main: "#fff",
    },
    tabulator: {
      main: orange[500],
    },
    indicatorColor: {
      main: orange[500], // Asigna el color deseado para el indicador
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiAppBar: {
      variants: [
        {
          props: {
            variant: "home",
          },
          style: {
            background: "transparent",
          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          cursor: "pointer",
          props: { variant: "link" },
          style: {
            color: orange[300],
          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: "simulaInput" },
          style: {
            color: orange[300],
            border: "solid 1px #ccc",
            marginBottom: 20,
            borderRadius: 5,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 10,
            paddingRight: 10,
            cursor: "pointer",
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "h3" },
          style: {
            fontSize: 40,
          },
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            fontFamily: "Bruno Ace",
            fontSize: 14,
            border: "solid 1px #333",
            borderRadius: "40px",
            padding: 12,
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "cardHome" },
          style: {
            border: "2px solid rgba(0,0,0,0.1)", // Borde tenue
            borderRadius: "4px", // Bordes redondeados (opcional)
          },
        },
      ],
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: "main" },
          style: {
            minHeight: 40,
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "white",
            fontWeight: "bold",
          },
        },

        {
          props: { variant: "outline" },
          style: {
            fontWeight: "bold",
            border: "solid 3px #333",
            width: "200px",
            fontSize: "20px",
            "@media (min-width:600px)": {
              fontSize: "80px",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.2)", // Cambia el color de fondo al hacer hover
            },
          },
        },
      ],
    },
  },
});
