import Container    from '../../../components/Theme/Dashboard';
import Table    from '../../../components/Table';
import View from './ViewCarousel';
import usePermissions from '../../../hooks/usePermissions';


const td  = [
  {
    label:"Nombre",
    field:"label",
    className:"",
    align:"left",
  },
  {
    label:"Imagen",
    field:"image",
    className:"",
    align:"left",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        component:View,
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                create:View
                                                              });
  return  <Container>
            {permissions.render()}
          </Container>
}
export default Home
