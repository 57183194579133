import React from "react";
import { Typography, Grid } from "@mui/material";
import Container from "../../../components/Theme/DashboardUsuario";
import UserHomeServiciosDetalle from "../../../components/Tabs/UserHomeServiciosDetalle";

const Web = () => {
  return (
    <Container>
      <Grid>
        <Typography variant="h3" className="text-secondary" align="center">
          Detalle del Servicio
        </Typography>
        <Grid sx={{ width: { xs: 400, md: 800 }, mt: { xs: 2, md: 6 } }}>
          <UserHomeServiciosDetalle />
        </Grid>
      </Grid>
    </Container>
  );
};
export default Web;
