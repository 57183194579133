import React from "react";
import Container from "../../components/Theme/AuthForm";
import { Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import useAxios from "../../hooks/useAxios";
import PersonIcon from "../../assets/svgs/person_icon_register";
import EnterpriseIcon from "../../assets/svgs/enterprise_icon_register";

const storage = "master";
// const storage2 = "register";
let getData = false;
let master = false;
const Login = () => {
  const axios = useAxios();
  // const register = useAsyncStorage(storage2);
  const [data, setData] = React.useState({});
  getData = axios.getData;
  master = useAsyncStorage(storage);

  // const handleClick = (register_type) => {
  //   register.setData({ usertype: register_type });
  // };

  React.useEffect(() => {
    const getInit = async () => {
      localStorage.clear();
      await getData().then((response) => {
        try {
          master.setDataToStorage(storage, response.data);
          setData(response.data);
        } catch (error) {
          console.error(error);
        }
      });
    };
    getInit();
  }, []);

  return (
    <Container>
      <Typography variant="h3" align="center" sx={{ mb: { md: 5, xs: 2 } }}>
        Tipo de registro
      </Typography>
      <Grid container spacing={2} justifyContent={"center"}>
        {data &&
          data.register_type &&
          data.register_type.map((row, key) => (
            <Grid item xs={12} md={4} align="center" key={key}>
              <NavLink to={"/auth/" + row.value2}>
                {row.label === "Persona" ? (
                  <>
                    <PersonIcon />
                    <Typography variant="h6" color="textPrimary">
                      Persona natural
                    </Typography>
                  </>
                ) : (
                  <>
                    <EnterpriseIcon />
                    <Typography variant="h6" color="textPrimary">
                      Persona jurídica
                    </Typography>
                  </>
                )}
              </NavLink>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};
export default Login;
