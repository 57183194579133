import React from 'react';
import { Button, Grid } from '@mui/material';
import {  NavLink  } from "react-router-dom";
const ImageButton = ({ src, className, imgClassName, alt, to, event, type, name }) => {
   return (
    <Grid className={ className }>
        {
          !event&&to&&(
            <Button component={NavLink} to={to} >        
              <img src={src} alt={alt} className={imgClassName} />
            </Button>
          )
        }

        {
          event&&type&&!to&&(
            <Button onClick={()=>event(name,type)} >        
              <img src={src} alt={alt} className={imgClassName} />
            </Button>
          )
        }
        
    </Grid>
  );
};

export default ImageButton;
