import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Grid } from "@mui/material";
import "./carousel-custom.css"; // Importa el archivo CSS personalizado

const DemoCarousel = ({ name, data, inputs, handleEvent }) => {
  const [selectDefault, setSelectDefault] = React.useState(0);

  const onClickThumb = (row) => {
    if (name && handleEvent && data[row]) {
      handleEvent(name, data[row]);
    }
  };

  React.useEffect(() => {
    if (!inputs[name]) {
      handleEvent(name, data[0]); // definir por defecto el primero
    }
    if (selectDefault === 0) {
      const result = data.findIndex((search) => search.value === inputs[name]);
      setSelectDefault(result);
    }
  }, [handleEvent, inputs[name]]);

  return (
    <Carousel
      selectedItem={selectDefault}
      onClickThumb={onClickThumb}
      thumbWidth={50}
      showArrows={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        const indicatorColor = isSelected ? "#fb8c00" : "#ccc"; // Cambiar el color aquí
        const indicatorStyle = {
          background: indicatorColor,
          width: 10,
          height: 10,
          borderRadius: "50%",
          display: "inline-block",
          margin: "0 8px",
          cursor: "pointer",
        };

        return (
          <li
            style={indicatorStyle}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`Go to slide ${index + 1}`}
            title={`${label}: ${index + 1}`}
          />
        );
      }}
    >
      {data.map((row, key) => (
        <div key={key}>
          <img src={row.images} alt="Programandoweb" />
          <Grid
            className="legend"
            sx={{ background: "rgba(0, 0, 0, 0.4) !important" }}
          >
            {row.label}
          </Grid>
        </div>
      ))}
    </Carousel>
  );
};

export default DemoCarousel;
