import React, { useRef, useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const SelectLocationMap = ({ open, onClose, onSelect, defaultPosition }) => {
  const mapRef = useRef(null);
  const [markerPosition, setMarkerPosition] = useState(defaultPosition);

  useEffect(() => {
    setMarkerPosition(defaultPosition);
  }, [defaultPosition]);

  const handleMapClick = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleConfirm = () => {
    onSelect(markerPosition);
    onClose();
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Selecciona la dirección</DialogTitle>
      <DialogContent>
        <GoogleMap
          mapContainerStyle={{ height: "400px", width: "100%" }}
          center={markerPosition}
          zoom={15}
          onClick={handleMapClick}
          onLoad={(map) => (mapRef.current = map)}
        >
          <Marker position={markerPosition} />
        </GoogleMap>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGetCurrentLocation}>Mi Ubicación</Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectLocationMap;
