import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Back from "../../../components/Button/Back";
import DatosFacturacion from "../../../components/DatosFacturacion";
import Resumen from "../../../components/Resumen";
import Container from "../../../components/Theme/DashboardUsuario2";
import StateContext from "../../../helpers/ContextState";
import useAsyncStorage from "../../../hooks/useAsyncStorage";
import useAxios from "../../../hooks/useAxios";

const component = "orden-servicios";
const title = "Facturación";
let componentStorage = false;

const Home = () => {
  const context = React.useContext(StateContext);
  const navigate = useNavigate();
  const { postData } = useAxios();
  const master = useAsyncStorage("master");
  const user = useAsyncStorage("user");
  const [inputs, setInputs] = React.useState({});
  const [storeLoading, setStoreLoading] = React.useState(false)
  componentStorage = useAsyncStorage(component);

  React.useEffect(() => {
    if (componentStorage.data) {
      setInputs(componentStorage.data);
    }
  }, [master.data]);

  React.useEffect(() => {
    if (user.data) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        ...componentStorage.data,
        ...user.data,
      }));
    }
  }, [user.data]);

  const [response, setResponse] = React.useState({});
  React.useEffect(() => {
    const responseData = JSON.parse(localStorage.getItem("response"));
    if (responseData) {
      setResponse(responseData);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    //return console.log(inputs)
    context.setOpenBackdrop("modal");

    await postData(inputs).then((response) => {
      context.setOpenBackdrop(false);
      try {
        if (
          response &&
          response.data &&
          response.data &&
          !response.data.success
        ) {
          context.setOpenDialog({
            maxWidth: "xs",
            component: (
              <Grid align="center" sx={{ p: 2 }}>
                <Grid>
                  <ErrorOutlineIcon sx={{ fontSize: 50 }} />
                </Grid>
                <Typography component={"h3"}>
                  Tu solicitud se procesó pero no hay servicios disponibles para
                  esa ubicación.
                </Typography>
              </Grid>
            ),
          });
        }

        //console.log(response.data.success);return ;

        if (response.data && response.data.id) {
          context.setOpenMessage("Orden creada con éxito");
          componentStorage.removeData();
          return navigate("/dashboard/servicios");
        } else {
          context.setOpenMessage(
            "Problemas al crear su orden de servicio, contacte al administrador"
          );
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const onSubmitStore = async (e, data) => {
    let data_prev = user.data;
    setStoreLoading(true)
    await postData(data, "api/v1/dashboard/billing")
    .then((response) => {
      if (response && response.data) {
        user.setDataToStorage("user", { ...data_prev, ...response.data });
        user.setDataToStorage("user2", { ...data_prev, ...response.data });
      }
    })
    .finally(() => {
      setStoreLoading(false)
    });
  };

  return (
    <Container>
      <Grid component={"form"} onSubmit={onSubmit} className="primer-form">
        <Typography
          variant="h4"
          className="text-secondary title-main"
          align="center"
        >
          {title}
        </Typography>
        <Grid>
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs={11} md={5}>
              <Grid sx={{ p: 2 }} mb={3}>
                <Grid container mb={3}>
                  <Grid item xs={12} md={10}>
                    <Typography component={"div"}>
                      <b>Datos de facturación</b>
                    </Typography>
                  </Grid>
                </Grid>
                <DatosFacturacion
                  md={12}
                  inputs={inputs}
                  onSubmit={onSubmitStore}
                  setData={setInputs}
                  loading={storeLoading}
                />
              </Grid>
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                justifyContent="center"
                spacing={{ xs: 2, sm: 0 }}
              >
                <Grid item xs={12} sm={3} md={4}>
                  <Back fullWidth />
                </Grid>
                <Grid item xs={12} sm={3} md={4}>
                  <Button
                    fullWidth
                    disabled={
                      inputs.facturacion_nit === "" &&
                      inputs.facturacion_cedula === ""
                    }
                    variant="contained"
                    type="submit"
                    sx={{
                      px: 4,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Generar Servicio
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11} md={5}>
              <Resumen
                inputs={inputs}
                label="Resumen"
                data={master.data}
                response={response}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Home;
