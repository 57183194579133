import React from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt from "jwt-decode";


const GoogleLoginPage = ({setFormData,events}) => {
  const responseGoogle = (response) => {
    var decoded = jwt(response.credential);

    setFormData(decoded)
    setTimeout(()=>{
        events(decoded)
    },1000)

  }

  return (
    <div className="">
          <div className="">
            <GoogleOAuthProvider  clientId="61047916049-n2me4jpo73q869gtumkks0ep3no72dk7.apps.googleusercontent.com">
             <GoogleLogin
              render={(renderProps) => (
                <button
                  type="button"
                  className=""
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                   Ingresar con Google
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
            />
            </GoogleOAuthProvider>
          </div>
    </div>
  )
}

export default GoogleLoginPage