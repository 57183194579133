import { Typography, Grid, Button } from "@mui/material";
import Container from "../../../components/Theme/DashboardUsuario2";
import useAsyncStorage from "../../../hooks/useAsyncStorage";
import { useNavigate } from "react-router-dom";
import React from "react";
import Resumen from "../../../components/Resumen";
import Textarea from "../../../components/Input/Textarea";
import useFormData from "../../../hooks/useFormData";
import Back from "../../../components/Button/Back";
import useAxios from "../../../hooks/useAxios";

const component = "orden-servicios";
const step = "step10";
const title = "Sugerencias";
let componentStorage = false;
const Home = () => {
  const { formData, onChange } = useFormData();
  const navigate = useNavigate();
  componentStorage = useAsyncStorage(component);
  const master = useAsyncStorage("master");
  const user = useAsyncStorage("user");
  const [inputs, setInputs] = React.useState({});
  const { getData } = useAxios();

  React.useEffect(() => {
    if (componentStorage.data) {
      setInputs(componentStorage.data);
    }
  }, [master.data]);

  React.useEffect(() => {
    if (user.data) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        ...user.data,
        ...componentStorage.data,
      }));
    }
  }, [user.data]);

  const handleEvent = async (e) => {
    e.preventDefault();
    try {
      const storedData = {
        ...inputs,
        ...formData,
      };

      console.log("Line 49 Datos a almacenar en el storage:", storedData);

      // Guardar los datos en el almacenamiento
      await componentStorage.setDataToStorage(component, storedData);

      console.log("Line 54 Datos para la solicitud get-routes:", storedData);

      // Realizar la solicitud GET utilizando la función getData de useAxios
      const response = await getData(storedData, "api/v1/dashboard/get-routes");

      console.log("Line 59 Respuesta de la solicitud:", response);

      if (response) {
        // Verificar si la respuesta tiene el estado 200

        console.log("line 64 Datos de la respuesta:", response);

        // Guardar los datos de la respuesta en el almacenamiento
        await componentStorage.setDataToStorage("response", response);

        // Redirigir a la siguiente página
        navigate("../" + step);
      } else {
        // Si no hay respuesta, mostrar un mensaje de error desconocido
        console.error("Respuesta indefinida");
        alert("Error desconocido al procesar la solicitud");
      }
    } catch (error) {
      // Capturar errores generales y mostrar un mensaje
      console.error("Error al hacer la solicitud:", error);
      alert(`Error: ${error.message || "Unknown error"}`);
    }
  };

  return (
    <Container>
      <Grid>
        <Typography
          variant="h4"
          className="text-secondary title-main"
          align="center" // Añade esta línea para centrar el texto
        >
          {title}
        </Typography>

        <Grid>
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs={11} md={5}>
              <Grid sx={{ p: 2 }}>
                <Typography>
                  <b>Describe la mercancía</b>
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  Recuerda que <b>NO</b> se pueden transportar: armas,
                  explosivos, estupefacientes.
                </Typography>
                <Textarea
                  name={"merchandise_descripction"}
                  label="Descripción de la mercancía"
                  defaultValue={
                    componentStorage.data &&
                    componentStorage.data.merchandise_descripction
                      ? componentStorage.data.merchandise_descripction
                      : ""
                  }
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
              <Grid sx={{ p: 2 }}>
                <Typography>
                  <b>Condiciones del transporte</b>
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  Describe algún requisito especial para el transporte de la
                  mercancías
                </Typography>
                <Textarea
                  name={"merchandise_especial_descripction"}
                  label="Descripción especial de transporte de la mercancía"
                  defaultValue={
                    componentStorage.data &&
                    componentStorage.data.merchandise_especial_descripction
                      ? componentStorage.data.merchandise_especial_descripction
                      : ""
                  }
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
              <Grid sx={{ mt: 2, p: 2 }}>
                <Back />
                <Button onClick={handleEvent} variant={"contained"}>
                  Continuar
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={11} md={5}>
              <Resumen
                inputs={inputs}
                label="Resumen del servicio"
                data={master.data}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Home;
