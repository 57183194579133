import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import StateContext from '../../helpers/ContextState';

const ButtonLoading = ({variant,label,fullWidth}) => {
  const context                   =   React.useContext(StateContext);
  return (
    <Box sx={{width:"100%",display:"block", marginBottom: '20px'}}>
      <Button fullWidth={fullWidth}
              type="submit"
              variant={variant||"contained"}
              disabled={context.loading}>
        {context.loading ? 'Procesando...' : label}
      </Button>
    </Box>
  );
};

export default ButtonLoading;
