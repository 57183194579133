import  Grid  from  '@mui/material/Grid';
import './LoadingFullWidth.css'
import WifiOffIcon from '@mui/icons-material/WifiOff';

const LoadingFullWidth=()=>{
  return  <Grid className="LoadingFullWidthContainer">
              <Grid className="LoadingFullWidthContent">
                  <WifiOffIcon  sx={{fontSize:100}} color="white"/>
                  <Grid className="LoadingFullWidthText white">
                    Esperamos restablecer la conexión a Internet
                  </Grid>
              </Grid>
          </Grid>
}
export default LoadingFullWidth
