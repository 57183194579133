import React from 'react';
import Container from '../../components/Theme/AuthForm';
import Input from '../../components/Input/InputLabel';
import useFormData  from '../../hooks/useFormData';
import { Button, Grid } from '@mui/material';
import useAxios     from '../../hooks/useAxios';
import { useNavigate } from "react-router-dom";
import StateContext from '../../helpers/ContextState';


const Login=()=>{
  const context                 =   React.useContext(StateContext)
  const navigate                =   useNavigate();
  const axios                   =   useAxios();
  const { 
          formData,
          onChange
        } =   useFormData();

  const onSubmit = async (e)=>{
    e.preventDefault()
    context.setOpenDialog({
      loading:true
    })
    await axios.postData(formData).then((response)=>{
      if (response&&response.status==='success') {
        return navigate("/auth/register_response");
      }
    })
    context.setOpenDialog(false)
  }

  
  return  <Container label="Datos de registro"  onSubmit={onSubmit}>
              <Grid container spacing={2}>                
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="email" 
                    type="text" 
                    label="Correo electrónico"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="register_code" 
                    type="text" 
                    label="Código de confirmación"
                    maxLength={6}
                  />
                </Grid>
                <Grid item xs={12} align="right">
                  <Button variant='contained' type="submit">
                    Confimar Cuenta
                  </Button>
                </Grid>
              </Grid>              
          </Container>
}
export default Login
