function Enterprise_icon_register() {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_104_382)">
        <path
          d="M123.95 61.98C123.95 67.07 123.34 72.02 122.18 76.75C115.55 103.85 91.11 123.96 61.97 123.96C32.72 123.96 8.2 103.7 1.69 76.44C0.59 71.8 0 66.96 0 61.98C0 50.33 3.21 39.43 8.8 30.12C19.64 12.08 39.4 0 61.98 0C84.71 0 104.59 12.24 115.37 30.49C120.82 39.72 123.95 50.48 123.95 61.98Z"
          fill="#F7903E"
        />
        <path
          d="M88.9299 86.1299L81.8199 87.3399V57.4199L88.9299 53.6399V86.1299Z"
          fill="#001D37"
        />
        <path
          d="M84.3 62.2299L83.29 62.5899V60.3499L84.3 59.9399V62.2299Z"
          fill="white"
        />
        <path
          d="M83.9499 89.2599L78.8199 87.9399V62.9799L83.9499 61.4199V89.2599Z"
          fill="#001D37"
        />
        <path
          d="M102.88 83.05V62.52L95.02 65.5V81L102.88 83.05Z"
          fill="#001D37"
        />
        <path
          d="M96.33 86.1301L91.22 84.8101V59.4801L96.33 56.8601V86.1301Z"
          fill="#001D37"
        />
        <path
          d="M88.9301 87.9699L94.6401 86.4899V56.5799L88.9301 53.6399V87.9699Z"
          fill="#316097"
        />
        <path
          d="M83.95 89.2599L89.07 87.9399V62.9799L83.95 61.4199V89.2599Z"
          fill="#316097"
        />
        <path
          d="M93.38 58.9101L94.22 59.2801V57.2301L93.38 56.8201V58.9101Z"
          fill="white"
        />
        <path
          d="M93.38 62.13L94.22 62.43V60.38L93.38 60.03V62.13Z"
          fill="white"
        />
        <path
          d="M93.38 65.34L94.22 65.58V63.53L93.38 63.25V65.34Z"
          fill="white"
        />
        <path
          d="M91.92 58.2801L92.82 58.6701V56.5501L91.92 56.1101V58.2801Z"
          fill="white"
        />
        <path
          d="M91.92 61.6099L92.82 61.9399V59.8099L91.92 59.4399V61.6099Z"
          fill="white"
        />
        <path
          d="M91.92 64.95L92.82 65.2V63.08L91.92 62.78V64.95Z"
          fill="white"
        />
        <path
          d="M89.63 57.27L90.63 57.71V55.48L89.63 54.99V57.27Z"
          fill="white"
        />
        <path
          d="M89.63 60.79L90.63 61.15V58.92L89.63 58.5V60.79Z"
          fill="white"
        />
        <path
          d="M87.6 57.4799L86.6 57.7999V55.6999L87.6 55.1899V57.4799Z"
          fill="white"
        />
        <path
          d="M87.73 61.3701L86.72 61.7301V59.4901L87.73 59.0801V61.3701Z"
          fill="white"
        />
        <path
          d="M84.3 59.0099L83.29 59.3099V57.4099L84.3 56.9199V59.0099Z"
          fill="white"
        />
        <path
          d="M89.63 64.31L90.63 64.59V62.35L89.63 62.02V64.31Z"
          fill="white"
        />
        <path
          d="M93.38 70.2299L94.22 70.3599V68.3099L93.38 68.1399V70.2299Z"
          fill="white"
        />
        <path
          d="M93.38 73.4401L94.22 73.5101V71.4601L93.38 71.3501V73.4401Z"
          fill="white"
        />
        <path
          d="M93.38 76.6601H94.22V74.6101L93.38 74.5701V76.6601Z"
          fill="white"
        />
        <path
          d="M91.92 70.0101L92.82 70.1501V68.0301L91.92 67.8401V70.0101Z"
          fill="white"
        />
        <path
          d="M91.92 73.3499L92.82 73.4199V71.2899L91.92 71.1799V73.3499Z"
          fill="white"
        />
        <path d="M91.92 76.68H92.82V74.56L91.92 74.52V76.68Z" fill="white" />
        <path
          d="M89.63 69.6601L90.63 69.8101V67.5801L89.63 67.3701V69.6601Z"
          fill="white"
        />
        <path
          d="M89.63 73.1799L90.63 73.2499V71.0199L89.63 70.8899V73.1799Z"
          fill="white"
        />
        <path
          d="M89.63 76.6999L90.63 76.6899V74.4499L89.63 74.4099V76.6999Z"
          fill="white"
        />
        <path
          d="M89.63 82.07L90.63 81.94V79.7L89.63 79.78V82.07Z"
          fill="white"
        />
        <path
          d="M89.63 85.5899L90.63 85.3799V83.1399L89.63 83.2999V85.5899Z"
          fill="white"
        />
        <path
          d="M93.38 81.5499L94.22 81.4399V79.3899L93.38 79.4599V81.5499Z"
          fill="white"
        />
        <path
          d="M93.38 84.76L94.22 84.59V82.54L93.38 82.67V84.76Z"
          fill="white"
        />
        <path
          d="M91.92 81.75L92.82 81.63V79.51L91.92 79.58V81.75Z"
          fill="white"
        />
        <path
          d="M91.92 85.08L92.82 84.9V82.77L91.92 82.91V85.08Z"
          fill="white"
        />
        <path d="M87.1 68.9L88.22 69.09V66.98L87.1 66.74V68.9Z" fill="white" />
        <path
          d="M84.97 68.5701L86.19 68.7701V66.5601L84.97 66.3101V68.5701Z"
          fill="white"
        />
        <path d="M87.1 65.94L88.22 66.2V64.09L87.1 63.78V65.94Z" fill="white" />
        <path
          d="M84.97 65.47L86.19 65.75V63.54L84.97 63.21V65.47Z"
          fill="white"
        />
        <path
          d="M87.1 71.7301L88.22 71.8401V69.7301L87.1 69.5601V71.7301Z"
          fill="white"
        />
        <path
          d="M84.97 71.52L86.19 71.65V69.44L84.97 69.26V71.52Z"
          fill="white"
        />
        <path d="M87.1 76.21L88.22 76.22V74.1L87.1 74.04V76.21Z" fill="white" />
        <path
          d="M84.97 76.21L86.19 76.22V74.01L84.97 73.95V76.21Z"
          fill="white"
        />
        <path
          d="M87.1 81.99L88.22 81.86V79.75L87.1 79.83V81.99Z"
          fill="white"
        />
        <path
          d="M85.6801 88.5499L87.5801 88.0699V83.1399L85.6801 83.4199V88.5499Z"
          fill="white"
        />
        <path
          d="M84.97 82.2599L86.19 82.1199V79.9099L84.97 79.9999V82.2599Z"
          fill="white"
        />
        <path
          d="M87.1 79.0301L88.22 78.9701V76.8601L87.1 76.8701V79.0301Z"
          fill="white"
        />
        <path
          d="M84.97 79.1699L86.19 79.0999V76.8899L84.97 76.8999V79.1699Z"
          fill="white"
        />
        <path
          d="M96.33 86.1301L101.43 84.8101V59.4801L96.33 56.8601V86.1301Z"
          fill="#316097"
        />
        <path
          d="M100.53 61.53L101.13 61.79V60.06L100.53 59.77V61.53Z"
          fill="white"
        />
        <path
          d="M100.53 64.24L101.13 64.45V62.72L100.53 62.48V64.24Z"
          fill="white"
        />
        <path
          d="M100.53 66.9499L101.13 67.1199V65.3799L100.53 65.1899V66.9499Z"
          fill="white"
        />
        <path
          d="M99.5 61.09L100.13 61.37V59.58L99.5 59.27V61.09Z"
          fill="white"
        />
        <path
          d="M99.5 63.8801L100.13 64.1101V62.3201L99.5 62.0601V63.8801Z"
          fill="white"
        />
        <path
          d="M99.5 66.6701L100.13 66.8501V65.0701L99.5 64.8601V66.6701Z"
          fill="white"
        />
        <path d="M97.9 60.39L98.6 60.69V58.83L97.9 58.49V60.39Z" fill="white" />
        <path
          d="M97.9 63.3099L98.6 63.5599V61.6899L97.9 61.4099V63.3099Z"
          fill="white"
        />
        <path
          d="M97.9 66.2301L98.6 66.4201V64.5601L97.9 64.3301V66.2301Z"
          fill="white"
        />
        <path
          d="M96.72 59.8699L97.46 60.1899V58.2699L96.72 57.8999V59.8699Z"
          fill="white"
        />
        <path
          d="M96.72 62.8799L97.46 63.1499V61.2299L96.72 60.9199V62.8799Z"
          fill="white"
        />
        <path
          d="M96.72 65.8999L97.46 66.1099V64.1799L96.72 63.9399V65.8999Z"
          fill="white"
        />
        <path
          d="M100.53 71.07L101.13 71.16V69.43L100.53 69.3V71.07Z"
          fill="white"
        />
        <path
          d="M100.53 73.78L101.13 73.82V72.09L100.53 72.01V73.78Z"
          fill="white"
        />
        <path
          d="M100.53 76.49L101.13 76.48V74.75L100.53 74.72V76.49Z"
          fill="white"
        />
        <path
          d="M99.5 70.9201L100.13 71.0201V69.2301L99.5 69.1001V70.9201Z"
          fill="white"
        />
        <path
          d="M99.5 73.7099L100.13 73.7599V71.9799L99.5 71.8999V73.7099Z"
          fill="white"
        />
        <path
          d="M99.5 76.5099L100.13 76.4999V74.7199L99.5 74.6899V76.5099Z"
          fill="white"
        />
        <path d="M97.9 70.67L98.6 70.78V68.91L97.9 68.77V70.67Z" fill="white" />
        <path
          d="M97.9 73.5899L98.6 73.6399V71.7799L97.9 71.6899V73.5899Z"
          fill="white"
        />
        <path
          d="M97.9 76.5101H98.6V74.6501L97.9 74.6101V76.5101Z"
          fill="white"
        />
        <path d="M97.9 80.97L98.6 80.88V79.02L97.9 79.07V80.97Z" fill="white" />
        <path
          d="M97.9 83.8999L98.6 83.7499V81.8899L97.9 81.9999V83.8999Z"
          fill="white"
        />
        <path
          d="M96.72 70.48L97.46 70.6V68.68L96.72 68.52V70.48Z"
          fill="white"
        />
        <path
          d="M96.72 73.5L97.46 73.56V71.63L96.72 71.54V73.5Z"
          fill="white"
        />
        <path
          d="M96.72 76.5201H97.46V74.5901L96.72 74.5601V76.5201Z"
          fill="white"
        />
        <path
          d="M96.72 81.1201L97.46 81.0301V79.1101L96.72 79.1601V81.1201Z"
          fill="white"
        />
        <path
          d="M96.72 84.1401L97.46 83.9901V82.0701L96.72 82.1801V84.1401Z"
          fill="white"
        />
        <path
          d="M100.53 80.6L101.13 80.53V78.8L100.53 78.84V80.6Z"
          fill="white"
        />
        <path
          d="M100.53 83.31L101.13 83.19V81.46L100.53 81.55V83.31Z"
          fill="white"
        />
        <path
          d="M99.5 80.7499L100.13 80.6699V78.8799L99.5 78.9299V80.7499Z"
          fill="white"
        />
        <path
          d="M99.5 83.5399L100.13 83.4099V81.6299L99.5 81.7299V83.5399Z"
          fill="white"
        />
        <path
          d="M82.4 91.0601L63.76 96.0501V23.5601L82.4 38.3201V91.0601Z"
          fill="#316097"
        />
        <path
          d="M82.4 39.7801L63.76 25.5601V23.5601L82.4 38.3201V39.7801Z"
          fill="#001D37"
        />
        <path
          d="M70.39 49.61L65.95 47.58V44.7L70.39 46.9199V49.61Z"
          fill="white"
        />
        <path
          d="M76.26 52.3L72.64 50.64V48.04L76.26 49.85V52.3Z"
          fill="white"
        />
        <path
          d="M81.1701 54.5401L78.1801 53.1701V50.8201L81.1701 52.3101V54.5401Z"
          fill="white"
        />
        <path
          d="M70.39 45.0801L65.95 42.7301V39.8401L70.39 42.3801V45.0801Z"
          fill="white"
        />
        <path
          d="M76.26 48.1899L72.64 46.2699V43.6799L76.26 45.7499V48.1899Z"
          fill="white"
        />
        <path
          d="M81.1701 50.8001L78.1801 49.2101V46.8501L81.1701 48.5701V50.8001Z"
          fill="white"
        />
        <path
          d="M70.39 40.55L65.95 37.87V34.98L70.39 37.85V40.55Z"
          fill="white"
        />
        <path
          d="M76.26 44.0901L72.64 41.9101V39.3101L76.26 41.6401V44.0901Z"
          fill="white"
        />
        <path
          d="M81.1701 47.0499L78.1801 45.2399V42.8799L81.1701 44.8199V47.0499Z"
          fill="white"
        />
        <path
          d="M70.39 36.0099L65.95 33.0099V30.1299L70.39 33.3199V36.0099Z"
          fill="white"
        />
        <path
          d="M76.26 39.9799L72.64 37.5399V34.9399L76.26 37.5399V39.9799Z"
          fill="white"
        />
        <path
          d="M81.1701 43.2999L78.1801 41.2799V38.9199L81.1701 41.0699V43.2999Z"
          fill="white"
        />
        <path
          d="M70.39 54.1501L65.95 52.44V49.55L70.39 51.4501V54.1501Z"
          fill="white"
        />
        <path
          d="M76.26 56.3999L72.64 55.0099V52.4099L76.26 53.9599V56.3999Z"
          fill="white"
        />
        <path
          d="M81.1701 58.29L78.1801 57.14V54.78L81.1701 56.06V58.29Z"
          fill="white"
        />
        <path
          d="M70.39 58.6799L65.95 57.2999V54.4099L70.39 55.9799V58.6799Z"
          fill="white"
        />
        <path
          d="M76.26 60.51L72.64 59.38V56.78L76.26 58.07V60.51Z"
          fill="white"
        />
        <path
          d="M81.1701 62.04L78.1801 61.11V58.75L81.1701 59.81V62.04Z"
          fill="white"
        />
        <path
          d="M70.39 63.22L65.95 62.16V59.27L70.39 60.52V63.22Z"
          fill="white"
        />
        <path
          d="M76.26 64.6099L72.64 63.7499V61.1499L76.26 62.1699V64.6099Z"
          fill="white"
        />
        <path
          d="M81.1701 65.79L78.1801 65.07V62.71L81.1701 63.56V65.79Z"
          fill="white"
        />
        <path
          d="M70.39 67.7499L65.95 67.0099V64.1299L70.39 65.0499V67.7499Z"
          fill="white"
        />
        <path
          d="M76.26 68.72L72.64 68.12V65.52L76.26 66.28V68.72Z"
          fill="white"
        />
        <path
          d="M81.1701 69.5299L78.1801 69.0399V66.6799L81.1701 67.2999V69.5299Z"
          fill="white"
        />
        <path
          d="M70.39 72.28L65.95 71.87V68.98L70.39 69.59V72.28Z"
          fill="white"
        />
        <path
          d="M76.26 72.8299L72.64 72.4899V69.8899L76.26 70.3799V72.8299Z"
          fill="white"
        />
        <path
          d="M81.1701 73.2799L78.1801 72.9999V70.6499L81.1701 71.0499V73.2799Z"
          fill="white"
        />
        <path
          d="M70.39 76.8201L65.95 76.7301V73.8401L70.39 74.1201V76.8201Z"
          fill="white"
        />
        <path
          d="M76.26 76.93L72.64 76.86V74.26L76.26 74.49V76.93Z"
          fill="white"
        />
        <path
          d="M81.1701 77.46L78.1801 77.4V75.04L81.1701 75.23V77.46Z"
          fill="white"
        />
        <path
          d="M70.39 81.3499L65.95 81.5899V78.6999L70.39 78.6499V81.3499Z"
          fill="white"
        />
        <path
          d="M76.26 81.0401L72.64 81.2301V78.6301L76.26 78.6001V81.0401Z"
          fill="white"
        />
        <path
          d="M81.1701 81.6399L78.1801 81.7999V79.4399L81.1701 79.4099V81.6399Z"
          fill="white"
        />
        <path
          d="M70.39 85.49L65.95 86.02V83.13L70.39 82.79V85.49Z"
          fill="white"
        />
        <path
          d="M76.26 84.7901L72.64 85.2201V82.6201L76.26 82.3401V84.7901Z"
          fill="white"
        />
        <path
          d="M81.1701 91.37L78.1801 92.17V84.27L81.1701 84.04V91.37Z"
          fill="white"
        />
        <path
          d="M70.39 89.6299L65.95 90.4499V87.5599L70.39 86.9299V89.6299Z"
          fill="white"
        />
        <path
          d="M76.26 88.5301L72.64 89.2101V86.6101L76.26 86.0901V88.5301Z"
          fill="white"
        />
        <path
          d="M102.88 83.05V62.52L110.74 65.31V81L102.88 83.05Z"
          fill="#316097"
        />
        <path
          d="M107.9 70.0499L106.57 69.8299V67.6599L107.9 67.9799V70.0499Z"
          fill="white"
        />
        <path
          d="M107.9 72.5L106.57 72.4V70.23L107.9 70.43V72.5Z"
          fill="white"
        />
        <path
          d="M107.9 76.2599L106.57 76.3399V74.1699L107.9 74.1799V76.2599Z"
          fill="white"
        />
        <path
          d="M107.9 81.74L105.78 82.29V77.99L107.9 77.74V81.74Z"
          fill="white"
        />
        <path
          d="M105.32 69.6299L104.6 69.5099V67.1899L105.32 67.3599V69.6299Z"
          fill="white"
        />
        <path
          d="M105.32 72.3099L104.6 72.2599V69.9299L105.32 70.0399V72.3099Z"
          fill="white"
        />
        <path
          d="M105.32 76.4199L104.6 76.4599V74.1399L105.32 74.1499V76.4199Z"
          fill="white"
        />
        <path
          d="M109.18 70.3001L108.28 70.1101V68.0701L109.18 68.3301V70.3001Z"
          fill="white"
        />
        <path
          d="M107.9 67.6201L106.57 67.2901V65.1201L107.9 65.5501V67.6201Z"
          fill="white"
        />
        <path
          d="M105.32 66.97L104.6 66.79V64.47L105.32 64.71V66.97Z"
          fill="white"
        />
        <path
          d="M109.18 67.9999L108.28 67.7199V65.6799L109.18 66.0299V67.9999Z"
          fill="white"
        />
        <path
          d="M109.18 72.59L108.28 72.53V70.49L109.18 70.62V72.59Z"
          fill="white"
        />
        <path
          d="M109.18 76.1899L108.28 76.2299V74.1899L109.18 74.2199V76.1899Z"
          fill="white"
        />
        <path
          d="M105.32 79.22L104.6 79.33V77.01L105.32 76.95V79.22Z"
          fill="white"
        />
        <path
          d="M109.18 78.6199L108.28 78.7599V76.7199L109.18 76.6599V78.6199Z"
          fill="white"
        />
        <path
          d="M110.4 70.4601L109.45 70.3001V68.3501L110.4 68.5701V70.4601Z"
          fill="white"
        />
        <path
          d="M110.4 68.2501L109.45 68.0101V66.0601L110.4 66.3701V68.2501Z"
          fill="white"
        />
        <path
          d="M110.4 72.6799L109.45 72.6099V70.6599L110.4 70.7999V72.6799Z"
          fill="white"
        />
        <path
          d="M110.4 76.1L109.45 76.16V74.21L110.4 74.22V76.1Z"
          fill="white"
        />
        <path
          d="M110.4 78.43L109.45 78.58V76.62L110.4 76.55V78.43Z"
          fill="white"
        />
        <path
          d="M104.14 69.53L103.38 69.41V67.01L104.14 67.2V69.53Z"
          fill="white"
        />
        <path
          d="M104.14 66.79L103.38 66.6V64.21L104.14 64.46V66.79Z"
          fill="white"
        />
        <path
          d="M104.14 72.3001L103.38 72.2401V69.8501L104.14 69.9601V72.3001Z"
          fill="white"
        />
        <path
          d="M104.14 76.5399L103.38 76.5899V74.1899L104.14 74.1999V76.5399Z"
          fill="white"
        />
        <path
          d="M104.14 79.4301L103.38 79.5501V77.1501L104.14 77.0901V79.4301Z"
          fill="white"
        />
        <path
          d="M37.97 83.9099L30.64 85.8299V68.6699L37.97 70.1099V83.9099Z"
          fill="#001D37"
        />
        <path
          d="M31.79 86.37L43.33 89.39V60.22L31.79 64.01V86.37Z"
          fill="#0E2D4E"
        />
        <path
          d="M54.88 86.37L43.33 89.39V60.22L54.88 64.01V86.37Z"
          fill="#001D37"
        />
        <path
          d="M29.8899 81.3199L22.3199 83.2299V60.1499L29.8899 63.9399V81.3199Z"
          fill="#001D37"
        />
        <path
          d="M41.41 65.3099L42.66 65.01V62.45L41.41 62.81V65.3099Z"
          fill="white"
        />
        <path
          d="M39.48 65.78L40.63 65.5101V63.05L39.48 63.3801V65.78Z"
          fill="white"
        />
        <path
          d="M34.99 66.8301L38.01 66.1201V63.8L34.99 64.67V66.8301Z"
          fill="white"
        />
        <path
          d="M41.41 70.1001L42.66 69.9401V67.3701L41.41 67.6001V70.1001Z"
          fill="white"
        />
        <path
          d="M41.41 73.9501L42.66 73.8801V71.3201L41.41 71.4501V73.9501Z"
          fill="white"
        />
        <path
          d="M41.41 77.79L42.66 77.83V75.26L41.41 75.29V77.79Z"
          fill="white"
        />
        <path
          d="M39.48 70.38L40.63 70.23V67.77L39.48 67.98V70.38Z"
          fill="white"
        />
        <path
          d="M39.48 74.07L40.63 74.01V71.55L39.48 71.67V74.07Z"
          fill="white"
        />
        <path
          d="M39.48 77.7601L40.63 77.7901V75.3301L39.48 75.3601V77.7601Z"
          fill="white"
        />
        <path
          d="M34.99 70.98L38.01 70.58V68.26L34.99 68.82V70.98Z"
          fill="white"
        />
        <path
          d="M34.99 74.3001L38.01 74.1401V71.8201L34.99 72.1401V74.3001Z"
          fill="white"
        />
        <path
          d="M34.99 77.6299L38.01 77.7099V75.3899L34.99 75.4699V77.6299Z"
          fill="white"
        />
        <path
          d="M34.99 81.7999L38.01 82.1899V79.8699L34.99 79.6399V81.7999Z"
          fill="white"
        />
        <path
          d="M34.99 85.12L38.01 85.76V83.44L34.99 82.96V85.12Z"
          fill="white"
        />
        <path
          d="M41.41 82.5901L42.66 82.7501V80.1801L41.41 80.0901V82.5901Z"
          fill="white"
        />
        <path
          d="M41.41 86.4299L42.66 86.6899V84.1299L41.41 83.9299V86.4299Z"
          fill="white"
        />
        <path
          d="M39.48 82.36L40.63 82.51V80.05L39.48 79.96V82.36Z"
          fill="white"
        />
        <path
          d="M39.48 86.0399L40.63 86.2899V83.8299L39.48 83.6399V86.0399Z"
          fill="white"
        />
        <path
          d="M33.6801 67.14L34.5501 66.94V64.8L33.6801 65.05V67.14Z"
          fill="white"
        />
        <path
          d="M33.6801 71.1599L34.5501 71.0399V68.8999L33.6801 69.0599V71.1599Z"
          fill="white"
        />
        <path
          d="M33.6801 74.3699L34.5501 74.3299V72.1899L33.6801 72.2799V74.3699Z"
          fill="white"
        />
        <path
          d="M33.6801 77.59L34.5501 77.62V75.48L33.6801 75.5V77.59Z"
          fill="white"
        />
        <path
          d="M33.6801 81.61L34.5501 81.72V79.58L33.6801 79.51V81.61Z"
          fill="white"
        />
        <path
          d="M33.6801 84.83L34.5501 85.01V82.87L33.6801 82.73V84.83Z"
          fill="white"
        />
        <path
          d="M32.21 67.48L33.02 67.29V65.24L32.21 65.47V67.48Z"
          fill="white"
        />
        <path
          d="M32.21 71.3499L33.02 71.2399V69.1799L32.21 69.3299V71.3499Z"
          fill="white"
        />
        <path
          d="M32.21 74.4501L33.02 74.4101V72.3501L32.21 72.4301V74.4501Z"
          fill="white"
        />
        <path
          d="M32.21 77.55L33.02 77.57V75.51L32.21 75.53V77.55Z"
          fill="white"
        />
        <path
          d="M32.21 81.4199L33.02 81.5199V79.4599L32.21 79.3999V81.4199Z"
          fill="white"
        />
        <path
          d="M32.21 84.52L33.02 84.69V82.63L32.21 82.5V84.52Z"
          fill="white"
        />
        <path
          d="M23.3 83.9099L30.64 85.8299V68.6699L23.3 70.1099V83.9099Z"
          fill="#0E2D4E"
        />
        <path
          d="M24.6899 82.6701L27.8699 83.3501V81.9001L24.6899 81.3601V82.6701Z"
          fill="white"
        />
        <path
          d="M23.59 82.4399L24.34 82.5999V81.3099L23.59 81.1799V82.4399Z"
          fill="white"
        />
        <path
          d="M28.38 83.43L30.26 83.83V82.28L28.38 81.97V83.43Z"
          fill="white"
        />
        <path
          d="M24.6899 80.1301L27.8699 80.5401V79.1001L24.6899 78.8201V80.1301Z"
          fill="white"
        />
        <path
          d="M23.59 79.98L24.34 80.08V78.78L23.59 78.72V79.98Z"
          fill="white"
        />
        <path
          d="M28.38 80.5901L30.26 80.8301V79.2801L28.38 79.1201V80.5901Z"
          fill="white"
        />
        <path
          d="M24.6899 77.58L27.8699 77.73V76.29L24.6899 76.27V77.58Z"
          fill="white"
        />
        <path d="M23.59 77.52L24.34 77.56V76.26H23.59V77.52Z" fill="white" />
        <path
          d="M28.38 77.74L30.26 77.83V76.28L28.38 76.27V77.74Z"
          fill="white"
        />
        <path
          d="M24.6899 75.03L27.8699 74.93V73.48L24.6899 73.72V75.03Z"
          fill="white"
        />
        <path
          d="M23.59 75.06L24.34 75.04V73.74L23.59 73.8V75.06Z"
          fill="white"
        />
        <path
          d="M28.38 74.89L30.26 74.83V73.28L28.38 73.42V74.89Z"
          fill="white"
        />
        <path
          d="M24.6899 72.4799L27.8699 72.1199V70.6799L24.6899 71.1699V72.4799Z"
          fill="white"
        />
        <path
          d="M23.59 72.6L24.34 72.52V71.22L23.59 71.34V72.6Z"
          fill="white"
        />
        <path
          d="M28.38 72.04L30.26 71.83V70.28L28.38 70.58V72.04Z"
          fill="white"
        />
        <path
          d="M19.0699 82.3799L22.3199 83.2299V60.1499L19.0699 61.8199V82.3799Z"
          fill="#0E2D4E"
        />
        <path
          d="M21.62 62.6799L22.08 62.4799V60.9299L21.62 61.1599V62.6799Z"
          fill="white"
        />
        <path
          d="M21.62 65.0301L22.08 64.8601V63.3101L21.62 63.5001V65.0301Z"
          fill="white"
        />
        <path
          d="M21.62 67.38L22.08 67.25V65.7L21.62 65.85V67.38Z"
          fill="white"
        />
        <path
          d="M20.89 63.0201L21.32 62.8201V61.3101L20.89 61.5301V63.0201Z"
          fill="white"
        />
        <path
          d="M20.89 65.2999L21.32 65.1499V63.6299L20.89 63.8199V65.2999Z"
          fill="white"
        />
        <path
          d="M20.89 67.59L21.32 67.47V65.96L20.89 66.1V67.59Z"
          fill="white"
        />
        <path
          d="M19.91 63.4501L20.31 63.2701V61.8101L19.91 62.0101V63.4501Z"
          fill="white"
        />
        <path
          d="M19.91 65.66L20.31 65.51V64.05L19.91 64.22V65.66Z"
          fill="white"
        />
        <path
          d="M19.91 67.86L20.31 67.75V66.29L19.91 66.43V67.86Z"
          fill="white"
        />
        <path
          d="M19.27 63.7299L19.66 63.5599V62.1299L19.27 62.3299V63.7299Z"
          fill="white"
        />
        <path
          d="M19.27 65.8901L19.66 65.7501V64.3201L19.27 64.4801V65.8901Z"
          fill="white"
        />
        <path
          d="M19.27 68.04L19.66 67.93V66.51L19.27 66.64V68.04Z"
          fill="white"
        />
        <path
          d="M21.62 70.9401L22.08 70.8701V69.3201L21.62 69.4101V70.9401Z"
          fill="white"
        />
        <path
          d="M21.62 73.29L22.08 73.25V71.7L21.62 71.76V73.29Z"
          fill="white"
        />
        <path
          d="M21.62 75.6401H22.08V74.0901L21.62 74.1101V75.6401Z"
          fill="white"
        />
        <path d="M20.89 71.07L21.32 71V69.49L20.89 69.58V71.07Z" fill="white" />
        <path
          d="M20.89 73.3501L21.32 73.3201V71.8101L20.89 71.8701V73.3501Z"
          fill="white"
        />
        <path
          d="M20.89 75.6399H21.32V74.1299L20.89 74.1499V75.6399Z"
          fill="white"
        />
        <path
          d="M19.91 71.22L20.31 71.16V69.7L19.91 69.78V71.22Z"
          fill="white"
        />
        <path
          d="M19.91 73.4299L20.31 73.3999V71.9399L19.91 71.9899V73.4299Z"
          fill="white"
        />
        <path
          d="M19.91 75.6399H20.31V74.1799L19.91 74.1999V75.6399Z"
          fill="white"
        />
        <path
          d="M19.91 79.0101L20.31 79.0601V77.6001L19.91 77.5701V79.0101Z"
          fill="white"
        />
        <path
          d="M19.91 81.21L20.31 81.3V79.84L19.91 79.78V81.21Z"
          fill="white"
        />
        <path
          d="M19.27 71.3201L19.66 71.2601V69.8301L19.27 69.9201V71.3201Z"
          fill="white"
        />
        <path
          d="M19.27 73.47L19.66 73.45V72.02L19.27 72.07V73.47Z"
          fill="white"
        />
        <path d="M19.27 75.63H19.66V74.21L19.27 74.23V75.63Z" fill="white" />
        <path
          d="M19.27 78.92L19.66 78.97V77.55L19.27 77.52V78.92Z"
          fill="white"
        />
        <path
          d="M19.27 81.0799L19.66 81.1599V79.7399L19.27 79.6799V81.0799Z"
          fill="white"
        />
        <path
          d="M21.62 79.1999L22.08 79.2599V77.7099L21.62 77.6699V79.1999Z"
          fill="white"
        />
        <path
          d="M21.62 81.55L22.08 81.64V80.09L21.62 80.02V81.55Z"
          fill="white"
        />
        <path
          d="M20.89 79.1199L21.32 79.1699V77.6599L20.89 77.6299V79.1199Z"
          fill="white"
        />
        <path
          d="M20.89 81.3999L21.32 81.4999V79.9899L20.89 79.9199V81.3999Z"
          fill="white"
        />
        <path
          d="M63.76 96.0501L45.05 91.1501V38.2201L63.76 23.3601V96.0501Z"
          fill="#0E2D4E"
        />
        <path
          d="M63.76 25.3701L45.05 39.6901V38.2201L63.76 23.3601V25.3701Z"
          fill="#001D37"
        />
        <path
          d="M49.29 53.13L46.29 54.51V52.27L49.29 50.77V53.13Z"
          fill="white"
        />
        <path
          d="M54.77 50.62L51.15 52.28V49.83L54.77 48.02V50.62Z"
          fill="white"
        />
        <path
          d="M61.57 47.5101L57.11 49.5501V46.8401L61.57 44.6101V47.5101Z"
          fill="white"
        />
        <path
          d="M49.29 49.15L46.29 50.75V48.51L49.29 46.78V49.15Z"
          fill="white"
        />
        <path
          d="M54.77 46.2399L51.15 48.1599V45.7199L54.77 43.6299V46.2399Z"
          fill="white"
        />
        <path d="M61.57 42.63L57.11 45V42.29L61.57 39.72V42.63Z" fill="white" />
        <path
          d="M49.29 45.17L46.29 46.9901V44.75L49.29 42.8V45.17Z"
          fill="white"
        />
        <path
          d="M54.77 41.86L51.15 44.05V41.6L54.77 39.25V41.86Z"
          fill="white"
        />
        <path
          d="M61.57 37.7501L57.11 40.4401V37.7301L61.57 34.8401V37.7501Z"
          fill="white"
        />
        <path
          d="M49.29 41.1901L46.29 43.2201V40.9901L49.29 38.8201V41.1901Z"
          fill="white"
        />
        <path
          d="M54.77 37.4801L51.15 39.9301V37.4801L54.77 34.8701V37.4801Z"
          fill="white"
        />
        <path
          d="M61.57 32.86L57.11 35.89V33.18L61.57 29.96V32.86Z"
          fill="white"
        />
        <path
          d="M49.29 57.11L46.29 58.27V56.03L49.29 54.75V57.11Z"
          fill="white"
        />
        <path
          d="M54.77 55.0099L51.15 56.3999V53.9499L54.77 52.3999V55.0099Z"
          fill="white"
        />
        <path
          d="M61.57 52.39L57.11 54.1V51.4L61.57 49.49V52.39Z"
          fill="white"
        />
        <path d="M49.29 61.1L46.29 62.03V59.8L49.29 58.73V61.1Z" fill="white" />
        <path
          d="M54.77 59.39L51.15 60.52V58.07L54.77 56.78V59.39Z"
          fill="white"
        />
        <path
          d="M61.57 57.2701L57.11 58.6601V55.9501L61.57 54.3701V57.2701Z"
          fill="white"
        />
        <path
          d="M49.29 65.08L46.29 65.79V63.56L49.29 62.71V65.08Z"
          fill="white"
        />
        <path
          d="M54.77 63.7699L51.15 64.6399V62.1899L54.77 61.1599V63.7699Z"
          fill="white"
        />
        <path
          d="M61.57 62.15L57.11 63.21V60.5L61.57 59.25V62.15Z"
          fill="white"
        />
        <path
          d="M49.29 69.0599L46.29 69.5599V67.3199L49.29 66.6899V69.0599Z"
          fill="white"
        />
        <path
          d="M54.77 68.15L51.15 68.75V66.3L54.77 65.55V68.15Z"
          fill="white"
        />
        <path
          d="M61.57 67.0299L57.11 67.7699V65.0599L61.57 64.1299V67.0299Z"
          fill="white"
        />
        <path
          d="M49.29 73.0399L46.29 73.3199V71.0799L49.29 70.6699V73.0399Z"
          fill="white"
        />
        <path
          d="M54.77 72.5399L51.15 72.8699V70.4199L54.77 69.9299V72.5399Z"
          fill="white"
        />
        <path
          d="M61.57 71.91L57.11 72.32V69.61L61.57 69.01V71.91Z"
          fill="white"
        />
        <path
          d="M49.29 77.0199L46.29 77.0799V74.8399L49.29 74.6499V77.0199Z"
          fill="white"
        />
        <path
          d="M54.77 76.9201L51.15 76.9901V74.5401L54.77 74.3101V76.9201Z"
          fill="white"
        />
        <path
          d="M61.57 76.7899L57.11 76.8699V74.1699L61.57 73.8899V76.7899Z"
          fill="white"
        />
        <path
          d="M49.29 81.0001L46.29 80.8401V78.6001L49.29 78.6401V81.0001Z"
          fill="white"
        />
        <path
          d="M54.77 81.2999L51.15 81.1099V78.6599L54.77 78.6899V81.2999Z"
          fill="white"
        />
        <path
          d="M61.57 81.67L57.11 81.43V78.72L61.57 78.77V81.67Z"
          fill="white"
        />
        <path
          d="M61.57 86.3601L57.11 85.8301V83.1201L61.57 83.4601V86.3601Z"
          fill="white"
        />
        <path
          d="M61.57 90.7601L57.11 89.8001V87.0901L61.57 87.8601V90.7601Z"
          fill="white"
        />
        <path
          d="M49.29 84.64L46.29 84.27V82.04L49.29 82.27V84.64Z"
          fill="white"
        />
        <path
          d="M54.77 85.2999L51.15 84.8599V82.4199L54.77 82.6999V85.2999Z"
          fill="white"
        />
        <path
          d="M49.29 88.27L46.29 87.71V85.47L49.29 85.91V88.27Z"
          fill="white"
        />
        <path
          d="M54.77 89.2999L51.15 88.6199V86.1699L54.77 86.6999V89.2999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_104_382">
          <rect width="123.95" height="123.95" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Enterprise_icon_register;
