import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Paper, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import {formatCurrency} from '../../helpers/functions'

export default function InputAdornments({ inputs, setInputs, name, handleEvent, skipInput }) {
  
  const [list2, setList2] = React.useState([1]);
  const [list, setList] = React.useState([]);
  const [error, setError] = React.useState('');

  const onChange = (e,key) => {
    setError(''); // Limpia el error al cambiar el valor
    
    const value = e.target.value

    if (value==='') {
      setError('Por favor, ingresa una monto válido.');
      return;
    }

    let _input  =   [...list];
    _input[key] =   value
    
    setList(_input)
    
    if (setInputs && name) {
      let _inputs         =   { ...inputs };
          _inputs[name]   =   _input;
      setInputs(_inputs);
      handleEvent(_inputs)
      setError(''); // Limpia el error después de guardar
    }    

  };

  
  React.useEffect(()=>{
    if (list.length===0&&inputs&&inputs[name]) {
        setList(inputs[name])
    }
  },[inputs])

  return (
    <Grid container spacing={1}>
        {
            !skipInput&&list2&&(
                <>
                  {
                    list2.map((row,key)=>{
                      return  <TextField  key={key}
                                          placeholder={'Valor servicio de fecha ' +row}
                                          error={!!error}
                                          helperText={error}
                                          type="number"
                                          fullWidth
                                          onChange={(e)=>onChange(e,key)}
                                          sx={{ m: 1, width: '100%' }}
                                          InputProps={{
                                            startAdornment: (
                                                <Grid position="start" className="bg-primary" sx={{ p: 1 }}>
                                                  <MonetizationOnIcon />
                                                </Grid>
                                            ),
                                          }}
                                          variant="standard"
                              />
                    })
                  }                                
                </>
            )
        }
      
    {
        inputs.services_dates&&(
          inputs.services_dates.map((row, key) => {
            return (
            <Grid item xs={'auto'} key={key}>
              <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      badgeContent={key+1} 
                      color="primary"   
                      
              >
                  <Paper sx={{ p: 2, mb: 1 }}>
                    <Typography align='center' component={"div"}>
                      <Grid>
                        {row}
                      </Grid>
                      <Grid>
                        {
                          formatCurrency(parseFloat(list[key])||0)
                        }
                      </Grid>
                    </Typography>
                  </Paper>
              </Badge>                
            </Grid>
            );
          })
        )        
    }
    </Grid>
  );
}
