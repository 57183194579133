/*set components*/
import ForgotPassword  from './ForgotPassword';
import ForgotPassword2  from './ForgotPassword2';

import Login  from './Login';
import Register  from './Register';
import RegisterNatural  from './RegisterNatural';
import RegisterEmpresa  from './RegisterEmpresa';
import RegisterCode  from './RegisterCode';
import RegisterContract  from './RegisterContract';
import RegisterResponse  from './RegisterResponse';

/*set icons*/
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';

export const routes_modules = [
  {
    name: "Auth",
    path: "/",
    component: Login,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Auth",
    path: "/login",
    component: Login,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "Signin",
    path: "/register",
    component: Register,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "Signin",
    path: "/register_natural",
    component: RegisterNatural,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "Signin",
    path: "/register_empresa",
    component: RegisterEmpresa,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "RegisterCode",
    path: "/register_code",
    component: RegisterCode,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "RegisterCode",
    path: "/register_contract",
    component: RegisterContract,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "RegisterResponse",
    path: "/register_response",
    component: RegisterResponse,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword",
    component: ForgotPassword,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword2",
    component: ForgotPassword2,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },

];
