import * as React from "react";
import {
  Grid,
  Paper,
  Alert,
  Button,
  IconButton,
  Typography,
  CardMedia,
} from "@mui/material";
import Cropper from "./Cropper";
import getCroppedImg from "./cropImage";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import { NavLink } from "react-router-dom";
import Loading from "../Loading";
import useAxios from "../../hooks/useAxios";
import imageCompression from "browser-image-compression";
import "./index.css";

const limit = 2 * 1000000;

export default function RecipeReviewCard({
  onChange,
  data,
  name,
  aspect,
  label,
  context,
}) {
  const axios = useAxios();
  const [reset, setReset] = React.useState(false);
  const [img, setImg] = React.useState(false);
  const [doc, setDoc] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [inputs, setInputs] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const handleCapture = async (e) => {
    const fileDoc = e.files;
    if (e.files && e.files.length !== 0) {
      const file = e.files[0];

      // Comprimir imagen si es necesario
      let compressedFile = file;
      if (file.size > limit) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          compressedFile = await imageCompression(file, options);
        } catch (error) {
          console.error("Error compressing file", error);
          setFail(true);
          return;
        }
      }

      if (compressedFile.size > limit) {
        return context.setOpenDialog({
          component: (
            <Paper sx={{ p: 2 }}>
              <Alert severity="warning">
                Es importante completar todos los documentos requeridos
              </Alert>
              <Grid>
                El archivo que intentas subir pesa{" "}
                <b> {compressedFile.size / 1000000}MB </b>, sobrepasa el máximo
                permitido <b> 2MB </b>
              </Grid>
              <Grid align="right">
                <Button
                  variant="contained"
                  onClick={() => context.setOpenDialog(false)}
                >
                  Cerrar
                </Button>
              </Grid>
            </Paper>
          ),
        });
      }

      setLoading(true);

      axios
        .postDataAttachment(
          { doc: compressedFile },
          "api/v1/dashboard/multimedia/uploads_docs"
        )
        .then((response) => {
          if (response && response.data && response.code === "SUCCESS") {
            console.log("File uploaded successfully", response.data); // Log success
            setDoc(response.data);
            onChange((prevState) => ({
              ...prevState,
              [name]: response.data,
            }));
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error uploading file", error); // Log error
          setLoading(false);
        });

      if (compressedFile.size <= limit) {
        return context.setOpenDialog({
          component: (
            <Paper sx={{ p: 2 }}>
              <Alert severity="success">
                Es importante completar todos los documentos requeridos
              </Alert>
              <Grid>
                El archivo que intentas subir se ha seleccionado correctamente y
                pesa <b> {compressedFile.size / 1000000}MB </b>, cumple con el
                tamaño permitido. <b> 2MB </b>
              </Grid>
              <Grid align="right">
                <Button
                  variant="contained"
                  onClick={() => context.setOpenDialog(false)}
                >
                  Cerrar
                </Button>
              </Grid>
            </Paper>
          ),
        });
      }
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels, image_) => {
    let inputs_ = { ...inputs };
    inputs_.croppedArea = croppedArea;
    inputs_.croppedAreaPixels = croppedAreaPixels;
    inputs_.image = image_;
    if (setInputs) {
      setInputs(inputs_);
    }
  };

  const subCrop = () => {
    setLoading(true);
    async function cropping() {
      let croppedImage = await getCroppedImg(
        inputs.image,
        inputs.croppedAreaPixels
      );
      if (inputs && setInputs) {
        let inputs_ = { ...inputs };
        inputs_.image = croppedImage;
        setInputs(inputs_);

        axios
          .postData(
            { images: croppedImage },
            "api/v1/dashboard/multimedia/uploads"
          )
          .then((response) => {
            if (
              response &&
              response.data &&
              onChange &&
              data &&
              response.data.url
            ) {
              let _data = { ...data };
              if (!_data[name]) {
                _data[name] = response.data.path;
                _data[name + "_preview"] = response.data.url;
              }
              setDoc(response.data);
            }
          });
      }

      if (onChange) {
        setReset(true);
      }

      setImg(false);
      setLoading(false);
    }
    cropping(this);
  };

  return (
    <div>
      <Typography variant="h6">{label}</Typography>
      <Grid className="container-cropper">
        {fail || ""}
        {!reset && !img ? (
          <>
            <Grid>
              <Grid className="cam-content cursor-pointer">
                <input
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => handleCapture(e.target)}
                />
                {data &&
                  data[name + "_preview"] &&
                  data[name + "_preview"] !== "" && (
                    <CardMedia
                      component="img"
                      sx={{ height: "100%" }}
                      image={data[name + "_preview"]}
                      alt="Programandoweb"
                    />
                  )}
                <Grid className="photo-content">
                  {!doc && <AddIcon sx={{ fontSize: 50 }} />}
                  {doc && <DescriptionIcon sx={{ fontSize: 50 }} />}
                </Grid>
              </Grid>
              <Grid align="center">
                {doc && (
                  <IconButton component={NavLink} to={doc} target="_blank">
                    <FileDownloadIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          false
        )}
        {img && !loading && (
          <Cropper
            aspect={aspect || 2.45 / 1}
            subCrop={subCrop}
            onCropComplete={onCropComplete}
            image={img}
          />
        )}
        {loading && (
          <Grid className="flex">
            <Grid>
              <Loading />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
