import * as React from 'react';
import Box                from '@mui/material/Box';
import {  List,
          Divider,
          ListItem,
          ListItemButton,
          ListItemIcon,
          ListItemText,
          CssBaseline,
          Toolbar,
          IconButton,
          Grid,
          Button
        } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon           from '@mui/icons-material/Menu';
import Typography         from '@mui/material/Typography';
import MuiDrawer          from '@mui/material/Drawer';
import ClickAwayListener  from '@mui/material/ClickAwayListener';

import MuiAppBar          from '@mui/material/AppBar';
import HttpsIcon          from '@mui/icons-material/Https';
import ChevronLeftIcon    from '@mui/icons-material/ChevronLeft';
//import ChevronRightIcon   from '@mui/icons-material/ChevronRight';

import StateContext       from '../../helpers/ContextState';

import { NavLink } from "react-router-dom";
import { routes_modules } from './routesAdmin';

import useMyRoutes        from '../../hooks/useMyRoutes';
import useAxios           from  '../../hooks/useAxios';
import useAsyncStorage    from  '../../hooks/useAsyncStorage';
import { useNavigate }    from  "react-router-dom";
import logo1 from '../../assets/png/logo-transparent.png'
import logo2 from '../../assets/png/logo-transparent-2.png'


const drawerWidth                     =   240;


const ListNew=(open)=>{
      return <List>
                    {
                      routes_modules.map((text, index) => {
                        if (!text.menu) {
                          return false
                        }
                        return  <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                  <ListItemButton
                                    component={NavLink} to={text.url||text.path}
                                    sx={{
                                      minHeight: 48,
                                      justifyContent: open ? 'initial' : 'center',
                                      px: 2.5,
                                    }}
                                  >
                                    <ListItemIcon
                                      onClick={text.event}
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {text.icon}
                                    </ListItemIcon>
                                    <ListItemText onClick={text.event} primary={text.name} sx={{ opacity: open ? 1 : 0 }} />
                                  </ListItemButton>
                                </ListItem>
                      })
                    }
                  </List>
}

const Auth = () => {
  const navigate                      =   useNavigate();
  const context                       =   React.useContext(StateContext);
  const axios                         =   useAxios();
  const userStorage                   =   useAsyncStorage("user");
  const theme                         =   useTheme();
  const [open, setOpen]               =   React.useState(false);
  const profileName                   =   context.user.name+ " " +context.user.surname;

  const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSessionClose=()=>{
    axios.postData({},'api/v1/dashboard/exit')
    userStorage.removeData()
    navigate("/auth/login",{ state: { reset: true } })
  }

  const fixed_menu      =   [
                              // {
                              //   label:'Switch Perfil',
                              //   icon:<ToggleOnIcon />,
                              //   event:openModal,
                              // },
                              {
                                name:'Cerrar sesión',
                                icon:<HttpsIcon />,
                                event:handleSessionClose,
                              },
                            ]

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/*meto aquí un menú*/}
        <AppBar position="fixed" open={open}>
          <Toolbar variant="main">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container>
              <Grid item xs={8} sx={{pt:0}}>
                
              </Grid>
              <Grid item xs={4} align="right">
                <Typography noWrap component="div" className="text-12 text-uppercase">
                  {profileName || 'Administración'}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            {
              open? <Grid align="center" sx={{pl:2}}>
                <Grid component="img" src={logo1}>
                  
                </Grid>
              </Grid>:<Grid alig="center" sx={{width:"100%"}}>
                <Grid component="img" src={logo2} sx={{
                                                        width:40,
                                                        height:40,
                                                        mt:6,
                                                        ml:4,
                                                      }}>
                  
                </Grid>
              </Grid>
            }
            <IconButton onClick={handleDrawerClose} sx={{ ml: 2 }}>
              {theme.direction !== 'rtl' && open ? <>
                <ChevronLeftIcon /> 
              </>:<>
                
              </>}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <ListNew open={open}/>
          <Divider />
          <List>
            {fixed_menu.map((text, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    onClick={text.event}
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText onClick={text.event} primary={text.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box sx={{ flexGrow: 1, p: 3 }} className="bgMain">
          <DrawerHeader />
          <Box sx={{display:context.loadingApiRest||context.hide?"none":"block"}}>
            {useMyRoutes(routes_modules)}
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default Auth;
