import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop({open,setOpen}) {
  const handleClose = () => {
    if (open!=='modal') {
      setOpen(false);  
    }    
  };
  
  return (  <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={open?true:false}
                      onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>    
  );
}