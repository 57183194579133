import React from 'react';
import  Grid  from  '@mui/material/Grid';
import Cropper from './Cropper'
import getCroppedImg from './cropImage'
import  './upload.css';

const   Container   =     React.forwardRef((props, ref) => {
  const [preview,setPreview]  =   React.useState(null)
  const [inputs, setInputs]   =   React.useState({});
  const cropper=(event)=>{
    let file          =   event.target.files[0];
    let reader        =   new FileReader();

    reader.onload     =   function() {
      setPreview(reader.result)
    }
    reader.readAsDataURL(file);
  }

  const onCropComplete=async(croppedArea, croppedAreaPixels,image_)=>{
    let inputs_ =   inputs;
        inputs_.croppedArea       = croppedArea
        inputs_.croppedAreaPixels = croppedAreaPixels
        inputs_.image             = image_
        //console.log(inputs_.croppedAreaPixels);
        let croppedImage = await getCroppedImg(
                                                inputs_.image,
                                                inputs_.croppedAreaPixels
                                              )
        inputs_.image = croppedImage
        setInputs(inputs_)
  }

  const handleCloseSave=()=>{
    if (props.onChange) {
      //console.log(inputs.image,props.data);
      props.onChange({...props.data,[props.name]:inputs.image});
    }
    setPreview(null)
  }

  return  <Grid ref={ref} className="Upload-content2">
            <Grid className="Upload-content">
              {
                !preview&&(
                  <>
                    <Grid className="Upload-image">{props.children}</Grid>
                    <input type="file" name={props.name || "file"} accept="image/*"  onChange={cropper} />
                  </>
                )
              }
              {
                preview&&(
                  <Grid className="crop-container">
                    <Cropper aspect={props.aspect} onCropComplete={onCropComplete} image={preview} handleCloseSave={handleCloseSave}/>
                  </Grid>
                )
              }

            </Grid>
          </Grid>
});


export default Container
