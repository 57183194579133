import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import Avatar from "../../Avatar/AvatarWithUpload";
import Input from "../../Input";

const Basic = ({ data, setData, loading, onSubmit }) => {
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ mx: 10 }}>
        {" "}
        {/* Agrega margen horizontal */}
        <Grid container justifyContent={"center"} sx={{ py: 5, mb: 0 }}>
          {" "}
          {/* Quitar margen inferior */}
          <Grid item xs={12} md={2} align="center">
            <Avatar inputs={data} name="avatar" />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 0 }}>
          {" "}
          {/* Quitar margen inferior */}
          <Grid item xs={12} md={6}>
            <Input
              defaultValue={data.name}
              required
              onChange={onChange}
              size="small"
              fullWidth
              name="name"
              type="text"
              label="Nombre"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              defaultValue={data.surname}
              required
              onChange={onChange}
              size="small"
              fullWidth
              name="surname"
              type="text"
              label="Apellido"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 0 }}>
          {" "}
          {/* Quitar margen inferior */}
          <Grid item xs={12} md={6}>
            <Input
              defaultValue={data.email}
              disabled
              required
              onChange={onChange}
              size="small"
              fullWidth
              name="email"
              type="email"
              label="Correo electrónico"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              defaultValue={data.celular}
              required
              onChange={onChange}
              size="small"
              fullWidth
              name="celular"
              type="text"
              label="Celular"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 0 }}>
          {" "}
          {/* Quitar margen inferior */}
          <Grid item xs={12} md={6}>
            <Input
              defaultValue={data.direccion}
              required
              onChange={onChange}
              size="small"
              fullWidth
              name="direccion"
              type="text"
              label="Dirección"
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        <Grid container justifyContent="right" sx={{ mb: 0 }}>
          {" "}
          {/* Quitar margen inferior */}
          <Grid item xs={12} md={6} align="right" sx={{ mt: 2 }}>
            <Button 
              variant="contained" 
              type="submit"
              disabled={loading} 
              sx={{ display: 'flex', gap: 1, minWidth: 120 }}
            >
                Guardar
                {loading && <CircularProgress size={16} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default Basic;
