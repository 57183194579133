import Home  from './Home';

/*set components*/
import Step1  from './Step1';
import Step2  from './Step2';
import Step3  from './Step3';
import Step4  from './Step4';
import Step5  from './Step5';
import Step6  from './Step6';
import Step7  from './Step7';
import Step8  from './Step8';
import Step9  from './Step9';
import Step10  from './Step10';
import Step11  from './Step11';
/*set icons*/
import Face3Icon from '@mui/icons-material/Face3';




export const routes_modules = [
  {
    name: "Services",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step1",
    component: Step2,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step2",
    component: Step2,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step3",
    component: Step3,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step4",
    component: Step4,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step5",
    component: Step5,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step6",
    component: Step6,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step7",
    component: Step7,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step8",
    component: Step8,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step9",
    component: Step9,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step10",
    component: Step10,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
  {
    name: "Clientes",
    path: "/step11",
    component: Step11,
    menu: true,
    private: false,
    icon: <Face3Icon />,
    title: "Clientes",
    subtitle: "Impulsan tu negocio"
  },
];
