import { Container } from "@mui/material";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header2";

const Layout = ({ children }) => {
	return (
		<>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: 'relative', 
        }}
      >
        <img
          src="/static/media/mountain1.52816ce6bf6078081d07.png"
          alt="cover"
          style={{ 
            objectFit: 'cover',
            position: "absolute",
            width: '100%',
            height: '50vh',
            top: 0, 
            left: 0, 
          }} 
        />
        <Header style={{ zIndex: 20 }} />
        {children}
      </Container>
      <Footer />
    </>
	)
}


export default Layout;