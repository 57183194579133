import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  InputLabel,
  TextField,
  LinearProgress,
  InputAdornment,
} from "@mui/material";

const PasswordStrengthMeter = ({
  name,
  label,
  id,
  defaultValue,
  onChange,
  placeholder,
  fullWidth,
  required,
  sx,
  maxLength,
  endAdornment,
  showPassword,
}) => {
  const [password, setPassword] = useState(defaultValue || "");

  useEffect(() => {
    setPassword(defaultValue || "");
  }, [defaultValue]);

  const calculatePasswordStrength = () => {
    // Expresiones regulares para verificar la presencia de mayúscula, número y carácter especial
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Verificar la presencia de mayúscula, número y carácter especial
    const hasUppercase = uppercaseRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const hasSpecialChar = specialCharRegex.test(password);

    // Calcular la fuerza de la contraseña
    let strength = 0;
    if (hasUppercase) strength += 25;
    if (hasNumber) strength += 25;
    if (hasSpecialChar) strength += 25;
    if (password.length >= 6) strength += 25;

    return strength;
  };

  const passwordStrength = calculatePasswordStrength();

  return (
    <Box sx={sx || { mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <TextField
        inputlabelprops={{
          shrink: true,
        }}
        required={required || false}
        fullWidth={fullWidth}
        name={name}
        label={label}
        id={id}
        value={showPassword ? password : defaultValue} // Mostrar la contraseña si showPassword es true
        variant="outlined"
        onChange={(e) => {
          setPassword(e.target.value);
          onChange(e);
        }}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"} // Utilizar el estado showPassword para determinar el tipo de entrada
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
      />
      <LinearProgress
        variant="determinate"
        value={passwordStrength}
        sx={{ mt: 1, height: 5 }}
        color={passwordStrength === 100 ? "success" : "primary"}
      />
    </Box>
  );
};

PasswordStrengthMeter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.object,
  maxLength: PropTypes.number,
  endAdornment: PropTypes.node,
  showPassword: PropTypes.bool,
};

export default PasswordStrengthMeter;
